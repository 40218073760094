import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: undefined,
  show: false,
  message: "",
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      const { status, message } = action.payload;
      state.show = true;

      if (status === "Success") {
        state.status = "success";
      } else if (status === "error" || status === "warning") {
        state.status = "error";
      } else {
        state.status = "info";
      }
      if (message) {
        state.message = message;
      }
    },
    clearMessage: (state) => {
      state.show = false;
      state.status = undefined;
      state.message = "";
    },
  },
  extraReducers: {},
});


export const { setMessage, clearMessage } = notificationSlice.actions;

export const notificationSelector = (state) => state.notification;

export default notificationSlice.reducer;
