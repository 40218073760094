import { getCurrentWeekNumber, getCurrentYear } from "../utils/index";

import { HttpClient } from "../utils/httpClient";
import { normalizeTimesheetData } from "../utils/timesheet";

export class TimesheetService {
  constructor() {
    this.httpClient = new HttpClient();
    this.currentWeekNumber = getCurrentWeekNumber();
    this.currentYear = getCurrentYear();
  }

  async createTimesheet(data) {
    try {
      const {
        hours,
        dateCreated,
        associateId,
        taskId,
        dateTimesheet,
        projectId,
      } = data;
      const response = await this.httpClient.post(`/ui/timesheet`, {
        hours: parseInt(hours),
        dateCreated: dateCreated,
        associateId: associateId,
        taskId: taskId,
        dateTimesheet: dateTimesheet,
        projectId: projectId,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getTimesheetDetails(timesheetId) {
    try {
      const response = await this.httpClient.get(
        `/ui/timesheet/${timesheetId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getTimesheetList(associateId, currentWeekNumber, currentYear) {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const response = await this.httpClient.get(
        `/ui/query/timesheet?associateId=${associateId}&weekNum=${currentWeekNumber}&year=${currentYear}`
      );
      if (
        response.status === 200 &&
        response.data &&
        response.data.status === "Success"
      ) {
        const { result } = response.data;
        const formattedWeekTimesheet = normalizeTimesheetData(result);
        return formattedWeekTimesheet;
      }
    } catch (error) {
      throw error;
    }
  }
  async updateTimesheet(data) {
    try {
      const {
        hours,
        dateCreated,
        associateId,
        taskId,
        dateTimesheet,
        projectId,
      } = data;
      const response = await this.httpClient.put(`/ui/timesheet`, {
        hours: parseInt(hours),
        dateCreated: dateCreated,
        associateId: associateId,
        taskId: taskId,
        dateTimesheet: dateTimesheet,
        projectId: projectId,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  async deleteTimesheet(timesheetId) {
    try {
      const response = await this.httpClient.delete(
        `/ui/timesheet/${timesheetId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async submitForReview(timesheet) {
    try {
      const response = await this.httpClient.post(
        `/ui/timesheet/submitForReview`,
        timesheet
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getWeekTimesheetOfAssociateByTodoId(todoId) {
    try {
      const response = await this.httpClient.get(
        `/ui/associateTimesheetByTodo/${todoId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getCurrentWeekDays() {
    try {
      const response = await this.httpClient.get(
        `/ui/query/getWeekdays?weekNumber=${this.currentWeekNumber}&year=${this.currentYear}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}
