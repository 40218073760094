import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { HolidaysService } from "../../services/holidays.service";

const initialState = {
  isLoading: false,
  isError: false,
  error: null,
  isSuccess: false,
  success: null,
  isGetHolidaysDetailsSuccess: false,
  isGetHolidaysDetailsFailure: false,
  isGetHolidaysListSuccess: false,
  isGetHolidaysListFailure: false,
  isUpdateSuccess: false,
  isUpdateFailure: false,
  isDeleteSuccess: false,
  isDeleteFailure: false,
  isCreateSuccess: false,
  isCreateFailure: false,
  holidays: null,
  holidaysRejectResponse: null,
  holidaysResponse: null,
  createSuccessResponse: null,
  createFailureResponse: null,
  updateResponse: null,
  deleteResponse: null,
};

export const createHolidays = createAsyncThunk(
  "holidays/createHolidays",
  async (data, thunkAPI) => {
    const holidaysService = new HolidaysService();
    try {
      const response = await holidaysService.createHolidays(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getHolidaysDetails = createAsyncThunk(
  "holidays/getHolidaysDetails",
  async (data, thunkAPI) => {
    const holidaysService = new HolidaysService();
    const holidayId = data.holidayId;
    try {
      const response = await holidaysService.getHolidaysDetails(holidayId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getHolidaysList = createAsyncThunk(
  "holidays/getHolidaysList",
  async (data, thunkAPI) => {
    try {
      const holidaysService = new HolidaysService();
      const response = await holidaysService.getHolidaysList();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getList = createAsyncThunk(
  "holidays/getList",
  async (data, thunkAPI) => {
    const holidayService = new HolidaysService();
    try {
    } catch (error) {}
  }
);
export const updateHolidays = createAsyncThunk(
  "holidays/updateHolidays",
  async (data, thunkAPI) => {
    const holidaysService = new HolidaysService();
    try {
      const response = await holidaysService.updateHolidays(data);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteHolidays = createAsyncThunk(
  "holidays/deleteHolidays",
  async (data, thunkAPI) => {
    const holidaysService = new HolidaysService();
    const holidayId = data;
    try {
      const response = await holidaysService.deleteHolidays(holidayId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const holidaysSlice = createSlice({
  name: "holidays",
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.error = null;
      state.isSuccess = false;
      state.success = null;
      state.isGetHolidaysDetailsSuccess = false;
      state.isGetHolidaysDetailsFailure = false;
      state.isGetHolidaysListSuccess = false;
      state.isGetHolidaysListFailure = false;
      state.isUpdateSuccess = false;
      state.isUpdateFailure = false;
      state.isCreateSuccess = false;
      state.isCreateFailure = false;
      state.isDeleteSuccess = false;
      state.isDeleteFailure = false;
      state.holidays = null;
      state.holidaysRejectResponse = null;
      state.createSuccessResponse = null;
      state.createFailureResponse = null;
      state.deleteResponse = null;
      state.updateResponse = null;
      state.holidaysResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(createHolidays.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(createHolidays.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload) {
          const message = action.payload.data;
          if (message.error) {
            state.isCreateSuccess = false;
            state.isCreateFailure = true;
            state.createFailureResponse = action.payload.data;
          } else {
            state.isCreateSuccess = true;
            state.createSuccessResponse = action.payload.data.result[0];
          }
        } else {
          // handle undefined payload
        }
      })

      .addCase(createHolidays.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateFailure = true;
        if (action.payload) {
          state.createFailureResponse = action.payload;
        }
      })

      .addCase(getHolidaysDetails.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getHolidaysDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isGetHolidaysDetailsSuccess = true;

        if (action.payload) {
          state.holidaysResponse = action.payload;
        }
      })

      .addCase(getHolidaysDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isGetHolidaysDetailsFailure = true;

        if (action.payload) {
          state.holidaysResponse = action.payload;
        }
      })

      .addCase(getHolidaysList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getHolidaysList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isGetHolidaysListSuccess = true;
        state.success = true;
        if (action.payload) {
          state.holidaysResponse = action.payload.data.result;
        }
      })

      .addCase(getHolidaysList.rejected, (state, action) => {
        state.isLoading = false;
        state.isGetHolidaysListFailure = true;
        state.success = true;
        if (action.payload) {
          state.holidaysRejectResponse = action.payload;
        }
      })

      .addCase(updateHolidays.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(updateHolidays.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdateSuccess = true;
        state.isUpdateFailure = false;
        if (action.payload) {
          state.updateResponse = action.payload.data;
        }
      })

      .addCase(updateHolidays.rejected, (state, action) => {
        state.isLoading = false;
        state.isUpdateSuccess = false;
        state.isUpdateFailure = true;
        if (action.payload && action.payload) {
          state.updateResponse = action.payload;
        } else {
          state.updateResponse = null;
        }
      })

      .addCase(deleteHolidays.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(deleteHolidays.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isDeleteSuccess = true;
        if (action.payload) {
          state.deleteResponse = action.payload.data;
        }
      })

      .addCase(deleteHolidays.rejected, (state, action) => {
        state.isLoading = false;
        state.isDeleteFailure = true;

        if (action.payload) {
          state.deleteResponse = action.payload.data;
        }
      });
  },
});

export const { reset } = holidaysSlice.actions;
export const holidaySelectors = (state) => state.holidays;
export default holidaysSlice.reducer;
