import AddIcon from "@mui/icons-material/Add";
import AddchartIcon from "@mui/icons-material/Addchart";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CelebrationIcon from "@mui/icons-material/Celebration";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import GroupIcon from "@mui/icons-material/Group";
import HistoryIcon from "@mui/icons-material/History";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import ListIcon from "@mui/icons-material/List";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReportIcon from "@mui/icons-material/Report";

export const adminMenu = [
  {
    id: 1,
    icon: <GroupIcon />,
    title: "Associate",
    path: "/app/associate",
  },
  {
    id: 2,
    icon: <EventAvailableIcon />,

    title: "Projects",
    path: "/app/projects",
  },
  {
    id: 3,
    icon: <AssignmentIcon />,

    title: "Task",
    path: "/app/task",
  },
  {
    id: 4,
    icon: <CelebrationIcon />,
    title: "Holidays",
    path: "/app/holidays",
  },
  {
    id: 5,
    icon: <NotificationsOutlinedIcon />,
    title: "Todos",
    path: "/app/todos",
  },
  {
    id: 6,
    icon: <ManageAccountsIcon />,
    title: "Manage",
    path: "/app/add",
  },
  {
    id: 7,
    icon: <HistoryIcon />,
    title: "Leave History",
    path: "/app/leaveHistory",
  },
  {
    id: 8,
    icon: <InsertChartIcon />,
    title: "Reports",
    path: "/app/report",
  },
  {
    id: 9,
    icon: <CheckCircleIcon />,
    title: "Status",
    path: "/app/status",
  },
  {
    id: 10,
    icon: <ReceiptIcon />,
    title: "Payslip",
    path: "/app/payslip",
  },
];

export const associateMenu = [
  {
    id: 3,
    icon: <AssignmentIcon />,
    title: "Task",
    path: "/app/task",
  },
  {
    id: 9,
    icon: <EventBusyIcon />,
    title: "Leave",
    path: "/app/leave",
  },
  {
    id: 4,
    icon: <CelebrationIcon />,
    title: "Holidays",
    path: "/app/holidays",
  },
  {
    id: 10,
    icon: <ListIcon />,
    title: "TimeSheets",
    path: "/app/timeSheets",
  },
];

const getNavConfig = (isAdmin) => {
  if (isAdmin === true) {
    return adminMenu;
  } else {
    return associateMenu;
  }
};

const isAuthorized = (user, path) => {
  if (!user) {
    return false;
  }
  const userRole = user;
  let currentUserNavConfig = [];
  currentUserNavConfig = getNavConfig(userRole);
  const currentUserNavConfigPaths = currentUserNavConfig.find(
    (navItem) => navItem.path === path
  );
  if (currentUserNavConfigPaths) {
    return true;
  } else {
    return false;
  }
};
export { isAuthorized };
