import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../common/Loader/Loader";
import { ROLES } from "../config";
import { authSelector } from "../features/auth/authSlice";
import { setMessage } from "../features/notification/notificationSlice";
import { useNavigate } from "react-router-dom";

const RouteNavigator = () => {
  const { isLoading, isSuccess, isError, error, isAuthenticated, user } =
    useSelector(authSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      const { isAdmin, isFirstTimeLogin } = user?.associate || {};
      if (isFirstTimeLogin) {
        dispatch(
          setMessage({
            message: "Successfully Logged in, Please reset your password",
            status: "Success",
          })
        );
        navigate("/reset-password");
      } else {
        if (isAdmin) {
          navigate("/app/associate", { replace: true });
        } else {
          navigate("/app/task", { replace: true });
        }
      }
    } else {
      navigate("/login", { replace: true });
    }
  }, []);

  return (
    <>
      <Loader />
    </>
  );
};

export default RouteNavigator;
