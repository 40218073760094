import "./ForgetPassword.css";

import * as Yup from "yup";

import { Box, Paper, TextField, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { forgotPassword, reset } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Link } from "@mui/material";
import Loader from "../../common/Loader/Loader";
import LoadingButton from "@mui/lab/LoadingButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { authSelector } from "../../features/auth/authSlice";
import { format } from "date-fns";
import { setMessage } from "../../features/notification/notificationSlice";
import { useFormik } from "formik";

const theme = createTheme();

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  dob: Yup.date().required("Date of birth is required"),
});

export default function ForgotPassword() {
  const navigate = useNavigate();
  const {
    isForgotPasswordFailure,
    isForgotPasswordSuccess,
    forgotPasswordResponse,
  } = useSelector(authSelector);

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      dob: null,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  function handleSubmit(values) {
    const dobDate = new Date(values.dob);

    if (isNaN(dobDate)) {
      console.error("Invalid date of birth");
      return;
    }

    const formattedDob = format(dobDate, "yyyyMMdd");
    setIsSubmitting(true);
    dispatch(
      forgotPassword({
        email: values.email,
        dob: formattedDob,
      })
    );
  }

  useEffect(() => {
    if (isForgotPasswordSuccess) {
      setIsSubmitting(false);
      dispatch(
        setMessage({
          message: forgotPasswordResponse.result,
          status: forgotPasswordResponse.status,
        })
      );
      navigate("/login");
      dispatch(reset());
    }
  }, [isForgotPasswordSuccess, forgotPasswordResponse]);

  useEffect(() => {
    if (isForgotPasswordFailure) {
      setIsSubmitting(false);
      dispatch(
        setMessage({
          message: forgotPasswordResponse.error,
          status: "error",
        })
      );
    }
  }, [isForgotPasswordFailure, forgotPasswordResponse]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Paper elevation={3} sx={{ padding: 3, maxWidth: 500 }}>
          <div style={{}}>
            <img
              src="./assets/Zyxan log3-01.png"
              alt=""
              style={{ width: "95px", height: "95px" }}
            />
          </div>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ textAlign: "center", marginLeft: "7rem" }}
          >
            Forgot your Password?
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              mb: 2,
              textAlign: "center",
              marginLeft: "4rem",
            }}
          >
            No Worries, We will send you reset instructions.
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <div className="Forget">
              <TextField
                label="Email Address"
                type="email"
                id="email"
                {...formik.getFieldProps("email")}
                margin="normal"
                variant="outlined"
                required
                fullWidth
                error={formik.touched.email && formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
            <div className="Date2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of birth"
                  variant="outlined"
                  margin="normal"
                  value={formik.values.dob}
                  onChange={(date) => formik.setFieldValue("dob", date)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={formik.touched.dob && formik.errors.dob}
                      helperText={formik.touched.dob && formik.errors.dob}
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>

            <br />

            <Link
              variant="subtitle2"
              underline="hover"
              component={RouterLink}
              to="/login"
            >
              Login?
            </Link>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={loading}
              fullWidth
              sx={{ mt: 3, mb: 2 }}
            >
              Recover my Password
            </LoadingButton>
          </form>
        </Paper>
      </Box>
      {isSubmitting && (
        <Loader
          open={isSubmitting}
          handleClose={() => setIsSubmitting(false)}
        />
      )}
    </ThemeProvider>
  );
}
