import { adminMenu, associateMenu } from "../common/SideBar/menu";

import { ROLES } from "../config";
import format from "date-fns/format";
import moment from "moment";

function getWeekNumber(d) {
  d = new Date(+d);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  var yearStart = new Date(d.getFullYear(), 0, 1);
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  return [d.getFullYear(), weekNo];
}

function getWeeksInYear(year) {
  var d = new Date(year, 11, 31);
  var week = getWeekNumber(d)[1];
  return week == 1 ? 52 : week;
}

function getCurrentWeekNumber() {
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1);
  const dayOfYear = Math.floor((today - startOfYear) / (24 * 60 * 60 * 1000));
  const weekNumber = Math.ceil((dayOfYear + 1) / 7);
  return weekNumber;
}

const getCurrentYear = () => {
  const currentYear = moment().year();
  return currentYear;
};

const getCurrentWeekDays = () => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const curr = moment();
  const week = [];

  for (let i = 0; i <= 6; i++) {
    const day = curr.clone().day(i);
    const Day = day.format("DD");
    const DateFormatted = day.format("YYYYMMDD");
    week.push({
      day: `${days[i]} ${Day}`,
      date: `${DateFormatted}`,
    });
  }
  return week;
};

const weekDays = getCurrentWeekDays();
console.log(weekDays);

// const getWeekDaysByWeekNumber = (weekNum) => {
//   const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//   const year = getCurrentYear();
//   const curr = new Date(year, 0, 1 + (weekNum - 1) * 7);

//   const week = [];

//   for (let i = 0; i <= 6; i++) {
//     const first = curr.getDate() - curr.getDay() + i;
//     const day = new Date(curr.setDate(first)).toLocaleDateString().slice(0, 10);
//     week.push(`${days[i]} ${day}`);
//   }
//   return week;
// };
const getWeekDaysByWeekNumber = (weekNum) => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const year = getCurrentYear();
  const curr = moment().year(year).startOf("year").week(weekNum);

  const week = [];

  for (let i = 0; i <= 6; i++) {
    const day = curr.clone().day(i);
    const Day = day.format("DD");
    const DateFormatted = day.format("YYYYMMDD");
    week.push({
      day: `${days[i]} ${Day}`,
      date: `${DateFormatted}`,
    });
  }
  return week;
};

const getDaysFromWeekNumber = (weekNum, year) => {
  if (weekNum && year) {
    const today = new Date(year, 0, 1 + (weekNum - 1) * 7);
    const currentWeekDates = [];

    for (let i = 0; i <= 6; i++) {
      let day = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - today.getDay() + i,
        0,
        0,
        0
      );
      day = `${day.getFullYear()} ${("0" + (day.getMonth() + 1)).slice(-2)} ${(
        "0" + day.getDate()
      ).slice(-2)}`;
      currentWeekDates.push(day);
    }
    const finalWeekDates = currentWeekDates.map((date) =>
      parseInt(date.replace(/\s/g, ""))
    );
    return finalWeekDates;
  }
};
const getDatesBetween = (startDate, endDate) => {
  const sD = moment(startDate).format("MM/DD/YYYY");
  const eD = moment(endDate).format("MM/DD/YYYY");

  const dates = [];

  const currDate = moment(sD).startOf("day");
  const lastDate = moment(eD).startOf("day");

  while (currDate.add(1, "days").diff(lastDate) < 0) {
    const date = moment(currDate.toDate())
      .format("YYYY-MM-DD")
      .replaceAll("-", "");
    dates.push(parseInt(date));
  }
  return dates;
};
const generateRandomId = () => {
  const randomId =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
  return randomId;
};

const getRole = () => {
  let user = JSON.parse(localStorage.getItem("user"));

  if (user) {
    let role;
    let matchedMenu = [];

    if (user?.isAdmin === true) {
      role = "admin";
      matchedMenu = adminMenu;
    } else {
      role = "associate";
      matchedMenu = associateMenu;
    }

    return matchedMenu;
  }
};
const isValidToken = (user) => {
  if (user && user.exp < Date.now() / 1000) {
    return false;
  }
  return true;
};

export {
  getCurrentWeekDays,
  getWeekDaysByWeekNumber,
  getDatesBetween,
  getCurrentWeekNumber,
  getDaysFromWeekNumber,
  getCurrentYear,
  generateRandomId,
  isValidToken,
  getRole,
  getWeeksInYear,
};
