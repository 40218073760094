import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { LeaveService } from "../../services/leave.service";

const intialState = {
  user: null,
  isLoading: false,
  isError: false,
  error: null,
  isSuccess: false,
  success: null,
  isGetLeaveDetailsSuccess: false,
  isGetLeaveDetailsFailure: false,
  isCreateLeaveSuccess: false,
  isCreateLeaveFailure: false,
  isGetLeaveListSuccess: false,
  isGetLeaveListFailure: false,
  isDeleteSuccess: false,
  isDeleteFailure: false,
  isUpdateLeaveSuccess: false,
  isUpdateLeaveFailure: false,
  createResponse: null,
  updateResponse: null,
  leaveResponse: null,
  isSuccessResponse: null,
  deleteResponse: null,
};
export const getLeaveDetails = createAsyncThunk(
  "Leave/getLeaveDetails",
  async (data, thunkAPI) => {
    const leaveService = new LeaveService();

    const leaveId = data.leaveId;
    try {
      const response = await leaveService.getLeaveDetails(leaveId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createLeave = createAsyncThunk(
  "Leave/createLeave",

  async (leave, thunkAPI) => {
    const leaveService = new LeaveService();
    try {
      const responce = await leaveService.createLeave(leave);
      return responce;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getLeaveList = createAsyncThunk(
  "Leave/getLeaveList",

  async (associateId, thunkAPI) => {
    const leaveService = new LeaveService();

    try {
      const responce = await leaveService.getLeaveList(associateId);
      return responce;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateLeave = createAsyncThunk(
  "Leave/updateLeave",

  async (data, thunkAPI) => {
    const leaveService = new LeaveService();
    const leaveId = data.leaveId;
    try {
      const responce = await leaveService.updateLeave(data);
      return responce;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteLeave = createAsyncThunk(
  "leaves/deleteLeave",
  async (data, thunkAPI) => {
    const leaveService = new LeaveService();
    const leaveId = data;
    try {
      const response = await leaveService.deleteLeave(leaveId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const leaveSlice = createSlice({
  name: "leave",
  initialState: intialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.error = null;
      state.isSuccess = false;
      state.success = null;
      state.isGetLeaveDetailsSuccess = false;
      state.isGetLeaveDetailsFailure = false;
      state.getResponse = null;
      state.leaveResponse = null;
      state.isCreateLeaveSuccess = false;
      state.isCreateLeaveFailure = false;
      state.createResponse = null;
      state.isUpdateLeaveSuccess = false;
      state.isUpdateLeaveFailure = false;
      state.updateResponse = null;
      state.isGetLeaveListFailure = false;
      state.isGetLeaveListSuccess = false;
      state.isSuccessResponse = null;
      state.isDeleteSuccess = false;
      state.isDeleteFailure = false;
      state.deleteResponse = null;
    },
  },

  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getLeaveDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getLeaveDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isGetLeaveDetailsSuccess = true;
        if (action.payload) {
          state.getResponse = action.payload.data;
        }
        state.isAuthenticated = true;
      })
      .addCase(getLeaveDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isGetLeaveDetailsFailure = true;
        if (action.payload) {
          state.error = action.payload.data;
        }
      })
      .addCase(createLeave.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createLeave.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreateLeaveSuccess = true;
        if (action.payload) {
          state.createResponse = action.payload.data;
        }
      })
      .addCase(createLeave.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateLeaveFailure = true;
        if (action.payload) {
          state.createResponse = action.payload.response.data;
        }
      })
      .addCase(getLeaveList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getLeaveList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isGetLeaveListSuccess = true;
        if (action.payload) {
          state.leaveResponse = action.payload.data.result;
        }
      })

      .addCase(getLeaveList.rejected, (state, action) => {
        state.isLoading = false;
        state.isGetLeaveListFailure = true;
        if (action.payload) {
          state.leaveResponse = action.payload.response.data;
        }
      })
      .addCase(updateLeave.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateLeave.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdateLeaveSuccess = true;
        if (action.payload) {
          state.updateResponse = action.payload.data;
        }
      })
      .addCase(updateLeave.rejected, (state, action) => {
        state.isLoading = false;
        state.isUpdateLeaveFailure = true;
        if (action.payload && action.payload) {
          state.updateResponse = action.payload;
        } else {
          state.updateResponse = null;
        }
      })
      .addCase(deleteLeave.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(deleteLeave.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isDeleteSuccess = true;
        if (action.payload) {
          state.deleteResponse = action.payload.data;
        }
      })

      .addCase(deleteLeave.rejected, (state, action) => {
        state.isLoading = false;
        state.isDeleteFailure = true;

        if (action.payload) {
          state.deleteResponse = action.payload.data;
        }
      });
  },
});
export const { reset } = leaveSlice.actions;

export const leaveSelector = (state) => state.leave;

export default leaveSlice.reducer;
