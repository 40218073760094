/* eslint-disable no-useless-catch */

import { HttpClient } from "../utils/httpClient";
import jwtDecode from "jwt-decode";

class AuthService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  
  async login(user) {
    try {
      const { email, Password } = user;

      const response = await this.httpClient.post("/ui/auth", {
        email: email,
        password: Password,
      });

      if (response.data) {
        localStorage.setItem(
          "jwt",
          JSON.stringify(response.data.result[0].token)
        );
        localStorage.setItem(
          "COMPANY_ID",
          response.data.result[0].associate.companyId
        );
        localStorage.setItem(
          "user",
          JSON.stringify(response.data.result[0].associate)
        );
        return {
          jwt: response.data.result[0].token,
          user: response.data.result[0],
        };
      }

      return { jwt: null, user: null };
    } catch (error) {
      throw error;
    }
  }
  async Signup(user) {
    try {
      const { name, WebSiteName, phone, email, firstName, lastName } = user;
      const response = await this.httpClient.post("/ui/company", {
        name: name,
        webSiteName: WebSiteName,
        phone: phone,
        email: email,
        firstName: firstName,
        lastName: lastName,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  async changePassword(data) {
    try {
      const { newPassword, password, confirmPassword } = data;
      const user = JSON.parse(localStorage.getItem("user"));
      const response = await this.httpClient.post("/ui/changepassword", {
        password: password,
        newPassword: newPassword,
        // confirmPassword: confirmPassword,
        associateId: user.associateId,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    return null;
  }

  async logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("jwt");
    return {
      status: "success",
      data: {
        jwt: null,
        user: null,
      },
    };
  }
  async forgotPassword(data) {
    try {
      const response = await this.httpClient.put("/ui/forgotPassword", {
        email: data.email,
        dob: data.dob,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  async resetPassword(password, confirmPassword, token) {
    try {
      const response = await this.httpClient.put(
        `/ui/associate/resetPassword/${token}`,
        {
          password,
          confirmPassword,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export { AuthService };
