import { HttpClient } from "../utils/httpClient";
import jwtDecode from "jwt-decode";

class LeaveService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getLeaveDetails(leaveId) {
    try {
      const response = await this.httpClient.get(`/ui/leave/:leaveId`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async createLeave(Leave) {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const { leaveType, description, fromDate, toDate } = Leave;
      const response = await this.httpClient.post("/ui/leave", {
        leaveType: leaveType,
        description: description,
        fromDate: fromDate,
        toDate: toDate,
        associateId: user.associateId,
        companyId: user.companyId,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  // async getLeaveList() {
  //   try {
  //     const response = await this.httpClient.get("/ui/query/leave");
  //     return response;
  //   } catch (error) {
  //     throw error;
  //   }
  // }
  async getLeaveList(associateId) {
    try {
      const response = await this.httpClient.get(
        `/ui/query/leave?associateId=${associateId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateLeave(Leave) {
    try {
      const response = await this.httpClient.put("/ui/leave", Leave);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async deleteLeave(leaveId) {
    try {
      const response = await this.httpClient.delete(`/ui/leave/${leaveId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export { LeaveService };
