import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AssociateService } from "../../services/associate.service";
import { Satellite } from "@material-ui/icons";

const initialState = {
  isLoading: false,
  isError: false,
  error: null,
  isSuccess: false,
  success: null,
  isGetAssociateDetailsSuccess: false,
  isGetAssociateDetailsFailure: false,
  isGetCompanyDetailsSuccess: false,
  isGetCompanyDetailsFailure: false,
  isGetAssociateListFailure: false,
  isGetAssociateListSuccess: false,
  isUpdateSuccess: false,
  isUpdateFailure: false,
  isDeleteSuccess: false,
  isDeleteFailure: false,
  isCreateSuccess: false,
  isCreateFailure: false,
  workLocations: null,
  associateResponse: null,
  createSuccessResponse: null,
  createFailureResponse: null,
  updateResponse: null,
  deleteResponse: null,
};

export const createAssociate = createAsyncThunk(
  "associate/createAssociate",
  async (data, thunkAPI) => {
    const associateService = new AssociateService();
    try {
      const response = await associateService.createAssociate(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAssociateDetails = createAsyncThunk(
  "associate/getAssociateDetails",
  async (data, thunkAPI) => {
    const associateService = new AssociateService();
    const associateId = data.associateId;
    try {
      const response = await associateService.getAssociateDetails(associateId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCompanyDetails = createAsyncThunk(
  "company/getCompanyDetails",
  async (data, thunkAPI) => {
    const associateService = new AssociateService();
    const companyId = data;
    try {
      const response = await associateService.getCompanyDetails(companyId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAssociateList = createAsyncThunk(
  "associate/getAssociateList",
  async (companyId, thunkAPI) => {
    const associateService = new AssociateService();
    try {
      const response = await associateService.getAssociateList(companyId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const updateAssociate = createAsyncThunk(
//   "associate/updateAssociate",
//   async (data, thunkAPI) => {
//     const associateService = new AssociateService();
//     try {
//       const response = await associateService.updateAssociate(data);
//       return response;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );
export const updateAssociate = createAsyncThunk(
  "associate/updateAssociate",
  async (data, thunkAPI) => {
    const associateService = new AssociateService();
    try {
      const { password, ...updateData } = data;

      const response = await associateService.updateAssociate(updateData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAssociate = createAsyncThunk(
  "associate/deleteAssociate",
  async (data, thunkAPI) => {
    const associateService = new AssociateService();
    const associateId = data;
    try {
      const response = await associateService.updateAssociate(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const associateSlice = createSlice({
  name: "associate",
  initialState: initialState,

  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.success = null;
      state.error = null;
      state.isGetAssociateDetailsSuccess = false;
      state.isGetAssociateDetailsFailure = false;
      state.isGetCompanyDetailsSuccess = false;
      state.isGetCompanyDetailsFailure = false;
      state.isGetAssociateListSuccess = false;
      state.isGetAssociateListFailure = false;
      state.isCreateFailure = false;
      state.isCreateSuccess = false;
      state.isUpdateSuccess = false;
      state.isUpdateFailure = false;
      state.isDeleteSuccess = false;
      state.isDeleteFailure = false;
      state.associateResponse = null;
      state.workLocations = null;
      state.createSuccessResponse = null;
      state.createFailureResponse = null;
      state.updateResponse = null;
      state.deleteResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAssociate.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(createAssociate.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          const message = action.payload.data;
          if (message.error) {
            state.isCreateSuccess = false;
            state.isCreateFailure = true;
            state.createFailureResponse = action.payload.data;
          } else {
            state.isCreateSuccess = true;
            state.createSuccessResponse = action.payload.data;
          }
        }
      })

      .addCase(createAssociate.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateFailure = true;

        if (action.payload) {
          state.isCreateFailure = action.payload;
        }
      })

      .addCase(getAssociateDetails.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getAssociateDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isGetAssociateDetailsSuccess = true;

        if (action.payload) {
          state.associateResponse = action.payload;
        }
      })

      .addCase(getAssociateDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isGetAssociateDetailsFailure = true;

        if (action.payload) {
          state.associateResponse = action.payload;
        }
      })

      .addCase(getCompanyDetails.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getCompanyDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isGetCompanyDetailsSuccess = true;
        if (
          action.payload &&
          action.payload.data.result &&
          action.payload.data.result.length > 0
        ) {
          state.workLocations = action.payload.data.result[0].workLocations;
        }
      })

      .addCase(getCompanyDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isGetCompanyDetailsFailure = true;

        if (action.payload) {
          state.companyResponse = action.payload;
        }
      })

      .addCase(getAssociateList.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getAssociateList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isGetAssociateListSuccess = true;

        if (action.payload) {
          state.associateResponse = action.payload.data.result;
        }
      })

      .addCase(getAssociateList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isGetAssociateListFailure = true;

        if (action.payload) {
          state.associateResponse = action.payload;
        }
      })

      .addCase(updateAssociate.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(updateAssociate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isUpdateSuccess = true;

        if (action.payload) {
          state.updateResponse = action.payload.data;
        }
      })

      .addCase(updateAssociate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isUpdateFailure = true;

        if (action.payload) {
          state.updateResponse = action.payload;
        } else {
          state.updateResponse = null;
        }
      })

      .addCase(deleteAssociate.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(deleteAssociate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isDeleteSuccess = true;

        if (action.payload) {
          state.deleteResponse = action.payload;
        }
      })

      .addCase(deleteAssociate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isDeleteFailure = true;

        if (action.payload) {
          state.deleteResponse = action.payload;
        }
      });
  },
});
export const { reset } = associateSlice.actions;
export const associateSelectors = (state) => state.associate;
export default associateSlice.reducer;
