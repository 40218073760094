import "./SideBar.css";

import { Link, Navigate } from "react-router-dom";
import { ListItemButton, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { Box } from "@mui/system";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { getRole } from "../../utils";
import { useLocation } from "react-router-dom";

const SideBar = () => {
  const location = useLocation();
  const [menuItems, setMenuItems] = useState([]);
  const [activeItem, setActiveItem] = useState({
    1: true,
  });
  const menuItemsMemoized = useMemo(() => {
    return getRole() || [];
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      let MenuItemsByRole = getRole();
      if (isMounted && MenuItemsByRole) {
        setMenuItems([...MenuItemsByRole]);

        // Update active item based on the current location
        const currentPath = location.pathname;
        const activeItem = MenuItemsByRole.find(
          (item) => item.path === currentPath
        );
        if (activeItem) {
          setActiveItem({
            [activeItem.id]: true,
          });
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [location.pathname]);

  const handleClick = (item) => {
    setActiveItem({
      [item.id]: true,
    });
  };

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      {menuItems.map((item, key) => (
        <SingleLevel
          key={key}
          item={item}
          handleClick={handleClick}
          activeItem={activeItem}
        />
      ))}
    </Box>
  );
};

const SingleLevel = ({ item, activeItem, handleClick }) => {
  const [selectedItem, setSelectedItem] = useState(activeItem);

  useEffect(() => {
    if (activeItem !== selectedItem) {
      setSelectedItem(activeItem);
    }
  }, [activeItem, selectedItem]);

  return (
    <Link
      style={{
        backgroundColor: selectedItem[item.id] ? "white" : "transparent",
      }}
      to={item.path}
    >
      <ListItemButton
        sx={{
          bgcolor: selectedItem[item.id] ? " #f9b500" : "transparent",
          color: selectedItem[item.id] ? "#f9b500" : "white",
          "&.MuiButtonBase-root:hover": {
            bgcolor: selectedItem[item.id] ? "#f9b500" : "transparent",
          },
        }}
        button
        onClick={() => handleClick(item)}
      >
        <Tooltip title={item.title} placement="right" arrow>
          <ListItemIcon
            sx={{
              color: selectedItem[item.id] ? "white" : "white",
            }}
          >
            {item.icon}
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primaryTypographyProps={{
            color: selectedItem[item.id] ? "white" : "white",
          }}
          primary={item.title}
        />
      </ListItemButton>
    </Link>
  );
};
export default SideBar;
