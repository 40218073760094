import "./PaySlips.css";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import React from "react";
import { forwardRef } from "react";

const DisplayDataTable = forwardRef(
  (
    {
      payslipHeader,
      employeeName,
      empId,
      designation,
      department,
      dateOfJoining,
      totalWorkingDays,
      lop,
      paidDays,
      basicSalary,
      houseRentAllowances,
      medicalAllowances,
      specialAllowances,
      stipend,
      grossSalary,
      epf,
      healthInsurense,
      pfTax,
      totalDeductions,
      totalNetPayable,
      conveyanceAllowances,
      grossSalary2,
    },
    ref
  ) => {
    return (
      <div className="main2" ref={ref}>
        <TableContainer
          component={Paper}
          className="TableContainer"
          style={{ border: "2px solid #164037", borderSpacing: "4px" }}
        >
          <Table>
            <TableHead className="TableHead">
              <TableRow>
                <TableCell colSpan={2} className="payslipHeader">
                  {payslipHeader}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead className="TableHead">EMPLOYEE SUMMARY</TableHead>
            <TableBody className="tablebody">
              <div className="employedata">
                <div className="employedleft">
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Employee Name
                    </TableCell>
                    <TableCell className="TableCell1">{employeeName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Employee Id
                    </TableCell>
                    <TableCell className="TableCell1">{empId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Designation
                    </TableCell>
                    <TableCell className="TableCell1">{designation}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Department
                    </TableCell>
                    <TableCell className="TableCell1">{department}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Date of Joining
                    </TableCell>
                    <TableCell className="TableCell1">
                      {dateOfJoining}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Gross Salary
                    </TableCell>
                    <TableCell className="TableCell1">{grossSalary2}</TableCell>
                  </TableRow>
                </div>
                <div className="employright">
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Total Working Days
                    </TableCell>
                    <TableCell className="TableCell1">
                      {totalWorkingDays}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      LOP Days
                    </TableCell>
                    <TableCell className="TableCell1">{lop}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Paid Days
                    </TableCell>
                    <TableCell className="TableCell1">{paidDays}</TableCell>
                  </TableRow>
                </div>
              </div>
              <div className="employedata">
                <div>
                  <TableHead className="TableHead">Earnings</TableHead>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Basic Salary
                    </TableCell>
                    <TableCell className="TableCell1">{basicSalary}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      House Rent Allowances
                    </TableCell>
                    <TableCell className="TableCell1">
                      {houseRentAllowances}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Conveyance Allowances
                    </TableCell>
                    <TableCell className="TableCell1">
                      {conveyanceAllowances}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Medical Allowances
                    </TableCell>
                    <TableCell className="TableCell1">
                      {medicalAllowances}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Special Allowances
                    </TableCell>
                    <TableCell className="TableCell1">
                      {specialAllowances}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Stipend
                    </TableCell>
                    <TableCell className="TableCell1">{stipend}</TableCell>
                  </TableRow>
                </div>
                <div>
                  <TableHead className="TableHead">Deductions</TableHead>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      EPF
                    </TableCell>
                    <TableCell className="TableCell1">{epf}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Health Insurance/ESI
                    </TableCell>
                    <TableCell className="TableCell1">
                      {healthInsurense}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Professional Tax
                    </TableCell>
                    <TableCell className="TableCell1">{pfTax}</TableCell>
                  </TableRow>
                </div>
              </div>
              <div className="employedata">
                <div>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Gross Salary
                    </TableCell>
                    <TableCell className="TableCell1">{grossSalary}</TableCell>
                  </TableRow>
                </div>
                <div>
                  <TableRow>
                    <TableCell
                      className="TableCell"
                      style={{ fontWeight: "bold" }}
                    >
                      Total Deductions
                    </TableCell>
                    <TableCell className="TableCell1">
                      {totalDeductions}
                    </TableCell>
                  </TableRow>
                </div>
              </div>
              <div className="Tpay">
                <TableRow>
                  <TableCell
                    className="TableCell"
                    style={{ fontWeight: "bold", fontSize: "X-large" }}
                  >
                    Total Net Payable
                  </TableCell>
                  <TableCell
                    className="TableCell1"
                    style={{
                      fontWeight: "bold",
                      fontSize: "X-large",
                      textAlign: "center",
                    }}
                  >
                    {totalNetPayable}
                  </TableCell>
                </TableRow>
              </div>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
);

export default DisplayDataTable;
