import { combineReducers, configureStore } from "@reduxjs/toolkit";

import CounterReducer from "../features/counter/counterSlice";
import addReducer from "../features/add/addSlice";
import associateReducer from "../features/associate/associateSlice";
import authReducer from "../features/auth/authSlice";
import createSagaMiddleware from "redux-saga";
import holidaysReducer from "../features/holidays/holidaysSlice";
import leaveSlicereducer from "../features/Leaves/LeaveSlice";
import notificationReducer from "../features/notification/notificationSlice";
import projectReducer from "../features/Projects/Projectslice";
import reducer from "../features/reports/ReportSlice";
import taskReducer from "../features/task/taskSlice";
import timesheetReducer from "../features/timesheet/timesheet.Slice";
import todoReducer from "../features/todo/todoSlice";

// Combining all reducers and initializing the store

const rootReducer = combineReducers({
  counter: CounterReducer,
  auth: authReducer,
  leave: leaveSlicereducer,
  notification: notificationReducer,
  holidays: holidaysReducer,
  associate: associateReducer,
  projects: projectReducer,
  task: taskReducer,
  todo: todoReducer,
  timesheet: timesheetReducer,
  add: addReducer,
  report: reducer,
  status: reducer,
  payslip: reducer,
});
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware),
});

export default store;
