import { Navigate, useLocation } from "react-router-dom";
import { authSelector, logout } from "../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";

import { isAuthorized } from "../common/SideBar/menu";
import { isValidToken } from "../utils/index";
import { getRole } from "../utils";
import { setMessage } from "../features/notification/notificationSlice";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, user } = useSelector(authSelector);
  const dispatch = useDispatch();
  const role = getRole();

  if (!isAuthenticated && !user) {
    return <Navigate to="/login" state={{ from: location }} />;
  } else {
    if (!isValidToken(user)) {
      dispatch(logout());
      dispatch(
        setMessage({
          status: "error",
          message: "Your session has expired. Please login again.",
        })
      );
    }
  }

  return children;
};

export default ProtectedRoute;
