import "./Holidays.css";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  createHolidays,
  deleteHolidays,
  getHolidaysList,
  holidaySelectors,
  reset,
  updateHolidays,
} from "../../features/holidays/holidaysSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import DataTable from "../../common/DataTable/DataTable";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../common/Loader/Loader";
import format from "date-fns/format";
import { formateDate } from "../../utils/date";
import moment from "moment/moment";
import { setMessage } from "../../features/notification/notificationSlice";

export default function Holidays() {
  const {
    isGetHolidaysListSuccess,
    isUpdateSuccess,
    isUpdateFailure,
    isDeleteSuccess,
    isDeleteFailure,
    isCreateSuccess,
    isCreateFailure,
    holidaysResponse,
    createSuccessResponse,
    createFailureResponse,
    updateResponse,
    deleteResponse,
  } = useSelector(holidaySelectors);
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const [holidaysList, setHolidaysList] = useState([]);
  const [Snackbar, setSnackbar] = useState([]);
  const [deleteRowId, setDeleteRowId] = useState(null);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    holidayId: false,
    companyId: false,
    id: false,
  });
  const checkEmpty = (params) => {
    if (params.props.value === "") {
      params.props.error = true;

      setSnackbar({
        children: "field cannot be empty",
        severity: "error",
      });

      return params.props;
    } else {
      params.props.error = false;
      return params.props;
    }
  };

  const columns = [
    {
      field: "id",
      headerName: " S.No.",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "holidayId",
      headerName: "S.No.",
      flex: 1,

      preProcessEditCellProps: checkEmpty,
    },
    {
      field: "companyId",
      headerName: "CompanyId",
      editable: false,
      flex: 1,

      preProcessEditCellProps: checkEmpty,
    },
    {
      field: "name",
      headerName: "Holiday",
      editable: true,
      minWidth: 200,
      maxWidth: 300,
      width: 300,
      preProcessEditCellProps: checkEmpty,
    },
    {
      field: "holidaysDates",
      headerName: "Date",
      flex: 1,
      editable: true,
      type: "date",
      preProcessEditCellProps: checkEmpty,
      valueFormatter: (params) => moment(params?.value).format("DD/MMM/YYYY"),
    },
    {
      field: "status",
      headerName: "Type",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Mandatory", "Optional"],
      preProcessEditCellProps: checkEmpty,
    },
  ];

  useEffect(() => {
    dispatch(getHolidaysList());
  }, []);

  useEffect(() => {
    if (holidaysResponse) {
      const newArray = holidaysResponse.map((v) => {
        return {
          ...v,
          id: v.holidayId,
          holidaysDates: formateDate(v.holidaysDates + ""),
        };
      });
      setHolidaysList(newArray);
    }
  }, [isGetHolidaysListSuccess, holidaysResponse]);
  useEffect(() => {}, []);
  function handleAdd(data) {
    setIsSubmitting(true);
    const formattedHolidaysDates = format(data.holidaysDates, "yyyyMMdd");
    dispatch(
      createHolidays({
        holidayId: data.holidayId,
        companyId: user.companyId,
        name: data.name,
        status: data.status,
        holidaysDates: formattedHolidaysDates,
      })
    );
  }

  function handleEdit(data) {
    setIsSubmitting(true);
    const formattedHolidaysDates = format(data.holidaysDates, "yyyyMMdd");
    dispatch(
      updateHolidays({
        holidayId: data.holidayId,
        companyId: user.companyId,
        name: data.name,
        status: data.status,
        holidaysDates: formattedHolidaysDates,
      })
    );
  }
  function handleDelete(id) {
    setIsOpenConfirmation(true);
    setDeleteRowId(id);
  }

  useEffect(() => {
    if (isCreateSuccess && createSuccessResponse) {
      dispatch(
        setMessage({
          message: createSuccessResponse,
          status: "Success",
        })
      );
      dispatch(getHolidaysList());
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isCreateSuccess, createSuccessResponse]);

  useEffect(() => {
    if (isCreateFailure && createFailureResponse) {
      dispatch(
        setMessage({
          message: createFailureResponse.error,
          status: "error",
        })
      );
      dispatch(getHolidaysList());
      setIsSubmitting(false);
    }
  }, [isCreateFailure, createFailureResponse]);

  useEffect(() => {
    if ((updateResponse && isUpdateSuccess) || isUpdateFailure) {
      if (updateResponse.status === "Success") {
        dispatch(
          setMessage({
            message: updateResponse.result[0],
            status: updateResponse.status,
          })
        );
        dispatch(getHolidaysList());
        dispatch(reset());
      } else {
        dispatch(
          setMessage({
            message: updateResponse.result,
            status: updateResponse.status,
          })
        );
      }
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isUpdateSuccess, updateResponse, isUpdateFailure]);

  useEffect(() => {
    if (isDeleteSuccess || isDeleteFailure) {
      dispatch(
        setMessage({
          message: deleteResponse.result,
          status: deleteResponse.status,
        })
      );
      dispatch(getHolidaysList());
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isDeleteSuccess, deleteResponse, isDeleteFailure]);

  const deleteConfirmDialog = () => {
    return (
      <>
        <Dialog maxWidth="xs" open={isOpenConfirmation}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent dividers>
            You want to Delete this Holiday.
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleConfirmationNo}
              variant="outlined"
              color="error"
              size="small"
              startIcon={<CancelIcon />}
            >
              No
            </Button>
            <Button
              onClick={handleConfirmationYes}
              variant="outlined"
              color="success"
              size="small"
              startIcon={<DeleteIcon />}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const handleConfirmationNo = () => {
    setIsOpenConfirmation(false);
  };

  const handleConfirmationYes = () => {
    setIsSubmitting(true);
    dispatch(deleteHolidays(deleteRowId));
    setIsOpenConfirmation(false);
  };

  return (
    <>
      <div className="pageheader">
        <div className="pagetitle">Holidays</div>
        {!user.isAdmin && (
          <p className="note">
            <span className="noteHead"> NOTE: </span>
            Only one optional holiday will be considered.
          </p>
        )}
      </div>
      <Box>
        <div className="holyDayGrid">
          {deleteConfirmDialog()}
          <DataTable
            columns={columns}
            rows={holidaysList}
            pageSize={13}
            checkboxSelection={false}
            isDeleteEnabled={true && user.isAdmin}
            isEditEnabled={true && user.isAdmin}
            isAddEnabled={true && user.isAdmin}
            columnVisibilityModel={columnVisibilityModel}
            addButtonText="Add New Holiday"
            handleEdit={(data) => {
              handleEdit(data);
            }}
            handleDelete={(data) => {
              handleDelete(data);
            }}
            handleAdd={(data) => {
              handleAdd(data);
            }}
          ></DataTable>
        </div>
      </Box>

      {isSubmitting && (
        <Loader
          open={isSubmitting}
          handleClose={() => setIsSubmitting(false)}
        />
      )}
    </>
  );
}
