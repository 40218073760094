import "./report.css";

import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  associateSelectors,
  getAssociateList,
} from "../../features/associate/associateSlice";
import {
  generateReport,
  reportSelector,
  reset,
} from "../../features/reports/ReportSlice";
import {
  getProjectsList,
  projectSelectors,
} from "../../features/Projects/Projectslice";
import { useDispatch, useSelector } from "react-redux";

import DescriptionIcon from "@material-ui/icons/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { InputLabel } from "@mui/material";
import Loader from "../../common/Loader/Loader";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import format from "date-fns/format";
import html2pdf from "html2pdf.js";
import { makeStyles } from "@material-ui/core/styles";
import { setMessage } from "../../features/notification/notificationSlice";
import { useEffect } from "react";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  tab: {
    fontWeight: 700,
    "&:focus": {
      outline: "none",
    },
  },
});

const ReportPage = () => {
  const dispatch = useDispatch();
  const {
    isGenerateReportSuccess,
    isGenerateReportFailure,
    generateReportResponse,
  } = useSelector(reportSelector);
  const { isGetAssociateListSuccess, associateResponse } =
    useSelector(associateSelectors);
  const { isGetProjectsListSuccess, projectsResponse } =
    useSelector(projectSelectors);
  const { companyId, associateManager } =
    JSON.parse(localStorage.getItem("user")) || {};

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [associateId, setAssociateId] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [associateList, setAssociateList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pdfBlobUrl, setPdfBlobUrl] = useState("");
  const [finalResponse, setFinalResponse] = useState(null);

  useEffect(() => {
    dispatch(getAssociateList(companyId));
    dispatch(getProjectsList(companyId));
  }, [dispatch, companyId]);
  useEffect(() => {
    if (associateResponse && Array.isArray(associateResponse)) {
      const users = associateResponse.map((user) => ({
        value: user.associateId,
        label: `${user.firstName} ${user.lastName}`,
      }));
      setAssociateList(users);
    }
  }, [isGetAssociateListSuccess, associateResponse]);
  useEffect(() => {
    if (projectsResponse && Array.isArray(projectsResponse)) {
      let projects = [];
      projectsResponse.map((project) => {
        let userD = {
          value: project.projectId,
          label: project.title,
        };
        projects = [...projects, userD];
      });
      setProjectList(projects);
    }
  }, [isGetProjectsListSuccess, projectsResponse]);
  const handleGenerateReport = async () => {
    setIsSubmitting(true);
    if (!startDate || !endDate || !associateId) {
      dispatch(
        setMessage({
          message: "Please fill in all required fields",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }
    if (new Date(endDate) < new Date(startDate)) {
      dispatch(
        setMessage({
          message: "End date must not be less than start date",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }
    if (!associateId.length) {
      dispatch(
        setMessage({
          message: "Please select at least one associate",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }
    if (!startDate) {
      dispatch(
        setMessage({
          message: "please select start date",
        })
      );
      setIsSubmitting(false);
      return;
    }
    if (!endDate) {
      dispatch(
        setMessage({
          message: "please select end date",
        })
      );
      setIsSubmitting(false);
      return;
    }
    const formattedStartDate = format(new Date(startDate), "yyyyMMdd");
    const formattedEndDate = format(new Date(endDate), "yyyyMMdd");
    const response = await dispatch(
      generateReport({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        managerId: user.associateId,
        associates: associateId,
        projects: projectId,
      })
    );
    const result = response.payload.data.response;
    let finalResponse = "";

    result.forEach((contentString) => {
      finalResponse = finalResponse + contentString.replaceAll('\\"', '"');
    });
    setFinalResponse(finalResponse);
  };
  const downloadReport = async () => {
    if (!finalResponse) {
      dispatch(
        setMessage({
          message: "Please generate the report before downloading.",
          status: "error",
        })
      );
      return;
    }
    const fileName = associateId
      .map(
        (associateId) =>
          associateList.find((associate) => associate.value === associateId)
            ?.label
      )
      .join("_");
    html2pdf(finalResponse, {
      margin: 10,
      filename: `${fileName}_Report.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a2", orientation: "portrait" },
    })
      .from(finalResponse)
      .outputPdf()
      .then((pdfAsArrayBuffer) => {
        const blob = new Blob([pdfAsArrayBuffer], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        setPdfBlobUrl(blobUrl);
        setFinalResponse(null);
      });
  };
  useEffect(() => {
    if (finalResponse) {
      const ele = document.getElementById("reportGenerate");
      ele.innerHTML = finalResponse;
    }
  }, [finalResponse]);

  useEffect(() => {
    if (isGenerateReportSuccess) {
      if (generateReportResponse && generateReportResponse) {
        dispatch(
          setMessage({
            message: "Report generated successfully",
            status: "Success",
          })
        );
      }
    }
    setIsSubmitting(false);
    dispatch(reset());
  }, [isGenerateReportSuccess, generateReportResponse]);

  useEffect(() => {
    if (isGenerateReportFailure && generateReportResponse) {
      if (generateReportResponse) {
        dispatch(
          setMessage({
            message: generateReportResponse.error,
            status: "error",
          })
        );
      }
    }
    setIsSubmitting(false);
    dispatch(reset());
  }, [isGenerateReportFailure, generateReportResponse]);

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setAssociateId([]);
    setProjectId("");
    setFinalResponse("");
  };
  const handleAssociateChange = (e) => {
    setAssociateId(
      Array.isArray(e.target.value) ? e.target.value : [e.target.value]
    );
  };

  const handleProjectChange = (e) => {
    setProjectId(e.target.value);
  };

  return (
    <>
      <div className="reportPage">
        <div>
          {" "}
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              justifyItems: "center",
              marginLeft: "4rem",
            }}
          >
            Generate Report
          </Typography>
        </div>
        <div className="genetarerepotbox">
          <div className="forminputs">
            <div className="reportAssociate">
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="associateId">
                  Associates<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  label="Associate ID"
                  multiple
                  value={associateId}
                  onChange={handleAssociateChange}
                  inputProps={{
                    name: "associateId",
                    id: "associateId",
                  }}
                  renderValue={(selected) => (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={
                            associateList.find(
                              (associate) => associate.value === value
                            )?.label
                          }
                          style={{ margin: 2 }}
                        />
                      ))}
                    </div>
                  )}
                >
                  {associateList.map((associate) => (
                    <MenuItem key={associate.value} value={associate.value}>
                      <Checkbox
                        checked={associateId.includes(associate.value)}
                      />
                      <ListItemText primary={associate.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="reportProject">
              {" "}
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="associateId">Projects</InputLabel>
                <Select
                  label="project ID"
                  value={projectId}
                  onChange={handleProjectChange}
                  inputProps={{
                    name: "projectId",
                    id: "projectId",
                  }}
                >
                  {projectList.map((project) => (
                    <MenuItem key={project.value} value={project.value}>
                      {project.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div>
              <TextField
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                fullWidth
                margin="normal"
                label={
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1">Start Date</Typography>
                    <span style={{ color: "red" }}>*</span>
                  </Box>
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div>
              <TextField
                label={
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1">End Date</Typography>
                    <span style={{ color: "red" }}>*</span>
                  </Box>
                }
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        </div>
        {isSubmitting && (
          <Loader
            open={isSubmitting}
            handleClose={() => setIsSubmitting(false)}
          />
        )}
      </div>
      <div className="ReportButtons">
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateReport}
          startIcon={<DescriptionIcon />}
        >
          Generate Report
        </Button>{" "}
        <Button
          variant="contained"
          color="primary"
          onClick={downloadReport}
          startIcon={<FileDownloadIcon />}
        >
          Download Report
        </Button>
        <Button
          variant="contained"
          onClick={handleReset}
          startIcon={<RotateLeftIcon />}
        >
          Reset
        </Button>
      </div>
      <br />
      <div className="empty">
        {finalResponse ? (
          <div id="reportGenerate"></div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "416px",
              fontSize: "25px",
              color: "#164037",
              opacity: 0.7,
            }}
          >
            Please select data to generate report
          </div>
        )}
      </div>
    </>
  );
};

export default ReportPage;
