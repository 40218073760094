import { HttpClient } from "../utils/httpClient";

export class AssociateService {
  constructor() {
    this.httpClient = new HttpClient();
  }
  async createAssociate(associate) {
    try {
      const response = await this.httpClient.post(`/ui/associate`, associate);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getAssociateDetails(associateId) {
    try {
      const response = await this.httpClient.get(
        `/ui/associate/${associateId}/`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getCompanyDetails(companyId) {
    try {
      const response = await this.httpClient.get(`/ui/company/${companyId}/`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getAssociateList() {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const companyId = user.companyId;
      const response = await this.httpClient.get(
        `/ui/query/associate?status=${"Active"}&companyId=${companyId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateAssociate(associate) {
    try {
      const response = await this.httpClient.put(`/ui/associate`, associate);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async deleteAssociate(associateId) {
    try {
      const response = await this.httpClient.delete(
        `/ui/associate/:associateId:${associateId}/`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}
