import { Box } from "@mui/material";
import { forwardRef } from "react";

const Page = forwardRef(({ children, title = "", meta, ...other }) => (
  <>
    <title>{`${title} | Zyxan_EDM`}</title>
    {meta}

    <Box {...other}>{children}</Box>
  </>
));

export default Page;
