import { Link } from 'react-router-dom';

export default function Page404() {
  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <a>
        <Link to="/">Go to Homepage</Link>
      </a>
    </div>
  );
}
