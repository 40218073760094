import { Outlet } from 'react-router-dom';
import React from 'react';
import { styled } from "@mui/material/styles";
import "./OuterLayout.css";
const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

/**
 * This component is the outer layout of the application which contains the header and the footer only.
//  * @returns {React.Component}
 * We will use this when user is not logged in.
 */

const OuterLayout = () => {
  return (
    <RootStyle>
      <div className="layout">
        <Outlet />
      </div>
    </RootStyle>
  );
};

export default OuterLayout;
