import "./projects.css";

import * as React from "react";

import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Modal from "react-bootstrap/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { associateSelectors } from "../../features/associate/associateSlice";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddAssociate = ({
  projectDetails,
  handleModal,
  handleAssociateAction,
  handleCloseModal,
}) => {
  const { isGetAssociateListSuccess, associateResponse } =
    useSelector(associateSelectors);
  const [open, setOpen] = React.useState(handleModal);
  const [associateList, setAssociateList] = useState([]);
  const [addedAssociates, setAddedAssociates] = useState([]);
  const [disableSave, setDisableSave] = useState(true);

  const handleClose = () => {
    setDisableSave(true);
    setOpen(false);
    handleCloseModal(); // Call the handleCloseModal prop to handle any necessary cleanup or actions
  };

  const handleChange = (event, value, reason) => {
    setDisableSave(false);
    if (reason === "removeOption") {
      setAddedAssociates(value);
    } else if (reason === "selectOption") {
      let newMember = value[value.length - 1];
      let newArray = [...addedAssociates];
      newArray.push(newMember);
      setAddedAssociates(newArray);
    }
  };

  useEffect(() => {
    if (associateResponse) {
      let users = [];
      let associatesList = [];
      associateResponse.map((user) => {
        const filterMember = projectDetails.members?.find((member) => {
          if (user.associateId === member) return member;
        });
        let userD = {
          value: user.associateId,
          label: user.firstName + " " + user.lastName,
          Selected: filterMember ? true : false,
        };
        users = [...users, userD];
        if (filterMember) {
          associatesList = [...associatesList, userD];
        }
      });
      setAddedAssociates(associatesList);
      setAssociateList(users);
    }
  }, [isGetAssociateListSuccess, associateResponse]);

  return (
    <div>
      <div style={{ width: 700, padding: 30 }}>
        {" "}
        <Modal show={open} onHide={handleClose}>
          {" "}
          <Modal.Header closeButton>
            {" "}
            <Modal.Title onClose={handleClose}>
              Associate Mapping
            </Modal.Title>{" "}
          </Modal.Header>{" "}
          <Modal.Body>
            <FormControl sx={{ m: 1, width: 300 }}>
              <Autocomplete
                multiple
                options={associateList}
                renderInput={(params) => (
                  <TextField {...params} label="Associate Mapping" />
                )}
                sx={{ width: "450px" }}
                value={addedAssociates}
                onChange={(event, newValue, reason) => {
                  handleChange(event, newValue, reason);
                }}
                clear
              />
            </FormControl>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outlined"
              color="success"
              onClick={() => {
                handleAssociateAction(addedAssociates);
                setDisableSave(true);
              }}
              disabled={disableSave}
            >
              Submit
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
export default AddAssociate;
