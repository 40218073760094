import "./history.css";

import React, { useEffect, useState } from "react";
import {
  associateSelectors,
  getAssociateList,
} from "../../features/associate/associateSlice";
import {
  getAssociateLeaveList,
  isLoading,
  isSuccess,
  reset,
  todoSelector,
} from "../../features/todo/todoSlice";
import { useDispatch, useSelector } from "react-redux";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import DataTable from "../../common/DataTable/DataTable";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import format from "date-fns/format";
import moment from "moment";

export default function LeavesHistory() {
  const {
    isGetAssociateLeaveListSuccess,
    isGetAssociateLeaveListFailure,
    getAssociateLeaveListResponse,
  } = useSelector(todoSelector);
  const {
    isGetAssociateListSuccess,
    isGetAssociateListFailure,
    associateResponse,
  } = useSelector(associateSelectors);
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();

  const [GetAssociateLeaveList, setGetAssociateLeaveList] = useState([]);
  const [selectedAssociate, setSelectedAssociate] = useState(null);
  const [associate, setAssociate] = useState([]);
  const [leaveStartDate, setLeaveStartDate] = useState(null);
  const [leaveEndDate, setLeaveEndDate] = useState(null);
  const [selectedAssociateId, setSelectedAssociateId] = useState("");
  const [assignedTo, setAssignedTo] = useState([]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    dueDate: false,
  });

  const columns = [
    {
      field: "id",
      headerName: "id",
      flex: 1,
    },
    {
      field: "actionType",
      headerName: "ActionType",
      minWidth: 150,
      maxWidth: 200,
      editable: true,
    },

    {
      field: "date",
      headerName: "Date",
      minWidth: 150,
      maxWidth: 200,
      editable: true,
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "associateName",
      headerName: "from",
      minWidth: 150,
      maxWidth: 200,
      editable: true,
    },
    {
      field: "dueDate",
      headerName: "DueDate",
      minWidth: 150,
      maxWidth: 200,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      maxWidth: 200,
      editable: true,
    },
    {
      field: "leaveStartDate",
      headerName: "StartDate",
      minWidth: 150,
      maxWidth: 200,
      editable: true,
      valueFormatter: (params) => {
        const dateValue = params?.value;
        if (!dateValue) return "";
        const formattedDate = moment(dateValue.toString(), "YYYYMMDD").format(
          "DD/MM/YYYY"
        );
        return formattedDate;
      },
    },
    {
      field: "leaveEndDate",
      headerName: "EndDate",
      minWidth: 150,
      maxWidth: 200,
      editable: true,
      valueFormatter: (params) => {
        const dateValue = params?.value;
        if (!dateValue) return "";
        const formattedDate = moment(dateValue.toString(), "YYYYMMDD").format(
          "DD/MM/YYYY"
        );
        return formattedDate;
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 560,
      editable: true,
      autoHeight: true,
    },
  ];

  useEffect(() => {
    dispatch(getAssociateList(user.companyId));
  }, []);
  useEffect(() => {
    dispatch(
      getAssociateLeaveList({
        companyId: user.companyId,
        associateId: selectedAssociateId,
        assignedTo: user.associateId,
        actionType: "Leave Request",
      })
    );
  }, []);
  const handleSearchClick = () => {
    let formattedStartDate = "";
    let formattedEndDate = "";

    if (leaveStartDate && leaveEndDate) {
      const startDate = new Date(leaveStartDate);
      const endDate = new Date(leaveEndDate);
      formattedStartDate = parseInt(format(startDate, "yyyyMMdd"));
      formattedEndDate = parseInt(format(endDate, "yyyyMMdd"));
    }

    dispatch(
      getAssociateLeaveList({
        companyId: user.companyId,
        associateId: selectedAssociateId,
        assignedTo: user.associateId,
        actionType: "Leave Request",
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      })
    );
  };

  useEffect(() => {
    if (isGetAssociateLeaveListSuccess && getAssociateLeaveListResponse) {
      const newArray = getAssociateLeaveListResponse
        .filter((v) => v.status === "Reject" || v.status === "Approve")
        .map((v) => {
          return {
            ...v,
            id: v.todoId,
            todoStatus: v.status,
          };
        });
      setGetAssociateLeaveList(newArray);
    }
    dispatch(reset());
  }, [isGetAssociateLeaveListSuccess, getAssociateLeaveListResponse]);

  useEffect(() => {
    if (associateResponse && Array.isArray(associateResponse)) {
      let users = [];
      associateResponse.map((user) => {
        let userD = {
          value: user.associateId,
          label: user.firstName + " " + user.lastName,
        };
        users = [...users, userD];
      });
      setAssociate(users);
    }
  }, [isGetAssociateListSuccess, associateResponse]);

  return (
    <>
      <div className="associateinputs">
        <div className="associatefields">
          <Autocomplete
            options={associate}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label="Selected Associate" />
            )}
            value={selectedAssociate}
            onChange={(event, newValue) => {
              setSelectedAssociate(newValue);
              if (newValue) {
                setSelectedAssociateId(newValue.value);
              } else {
                setSelectedAssociateId("");
              }
            }}
            clear
          />
        </div>
        <div className="associatefields">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              required
              label="Start Date"
              value={leaveStartDate}
              onChange={(date) => {
                setLeaveStartDate(date);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>

        <div className="associatefields">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              required
              label="End Date"
              value={leaveEndDate}
              onChange={(date) => {
                setLeaveEndDate(date);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <Button
          variant="contained"
          color="info"
          onClick={handleSearchClick}
          startIcon={<SearchIcon />}
        >
          Search
        </Button>
      </div>

      <DataTable
        columns={columns}
        rows={GetAssociateLeaveList}
        pageSize={8}
        checkboxSelection={false}
        columnVisibilityModel={columnVisibilityModel}
      />
    </>
  );
}
