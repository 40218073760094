import { HttpClient } from "../utils/httpClient";

export class ProjectsService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async createProjects(projects) {
    try {
      const response = await this.httpClient.post(`/ui/project/`, projects);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getProjectsDetails(projectId) {
    try {
      const response = await this.httpClient.get(
        `/ui/project/:projectId:${projectId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getCompanyDetails(companyId) {
    try {
      const response = await this.httpClient.get(`/ui/company/${companyId}/`);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getProjectsList() {
     const user = JSON.parse(localStorage.getItem("user"));
    try {
      const companyId = user.companyId;
      const response = await this.httpClient.get(
        `/ui/query/project?companyId=${companyId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateProjects(projects) {
    try {
      const response = await this.httpClient.put(`/ui/project`, projects);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async deleteProjects(projectId) {
    try {
      const response = await this.httpClient.delete(`/ui/project/${projectId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async addAssociateToProject(projects) {
    try {
      const response = await this.httpClient.put(
        `/ui/project/addAssociate`,
        projects
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async removeAssociateToProject(projects) {
    try {
      const response = await this.httpClient.put(
        `/ui/project/removeAssociate`,
        projects
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getAssociateProjects(associateId) {
    try {
      const response = await this.httpClient.get(
        `/ui/getAssociateProjects/${associateId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}
