import './NavBar.css';

import React from 'react';
import { logout } from '../../features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/login');
    dispatch(logout());
  };
  

  return (
    <div className="container">
      <ul className="ul">
        {/* <li className="listItem">
          <a href="/app/dashboard">Dashboard</a>
        </li> */}
        {/* <li className="listItem">
          <a href="/app/component1">Component1</a>
        </li>
        <li className="listItem">
          <button onClick={handleLogout}>Logout</button>
        </li> */}
      </ul>
    </div>
  );
};

export default NavBar;
