import { HttpClient } from "../utils/httpClient";

export class AddService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async insertOrUpdateProjectCategory(data) {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const response = await this.httpClient.put(`/ui/projectcategories`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async insertOrUpdateWorkLocation(data) {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const { workLocationId, name, city, state, zip, country } = data;
      const response = await this.httpClient.put(`/ui/worklocations`, {
        workLocationId: workLocationId,
        name: name,
        city: city,
        state: state,
        zip: zip,
        country: country,
        companyId: user.companyId,
        id: data.workLocationId,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getCompanyList(data) {
    const user = JSON.parse(localStorage.getItem("user"));
    const companyId = user.companyId;
    try {
      const response = await this.httpClient.get(`/ui/query/company?companyId=${companyId}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async deleteWorkLocation(data) {
    const {
      workLocationId
    } = data
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const companyId = user.companyId;
      const response = await this.httpClient.delete(
        `/ui/workLocation/${workLocationId}/${companyId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async deleteProjectCategory(data) {
    const {
      projectCategoryId
    } = data
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const companyId = user.companyId;
      const response = await this.httpClient.delete(
        `/ui/ProjectCategory/${companyId}/${projectCategoryId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}
