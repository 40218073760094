import * as Yup from "yup";

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { changePassword, reset } from "../../features/auth/authSlice";
import {
  clearMessage,
  setMessage,
} from "../../features/notification/notificationSlice";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../common/Loader/Loader";
import { authSelector } from "../../features/auth/authSlice";
import { useEffect } from "react";
import { useFormik } from "formik";

const theme = createTheme();

const Reset = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isChangePasswordSuccess,
    isChangePasswordFailure,
    changePasswordResponse,
  } = useSelector(authSelector);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long"),
    newPassword: Yup.string()
      .required("New Password is required")
      .min(6, "New Password must be at least 6 characters long"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords do not match"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setIsSubmitting(true);
      const user = JSON.parse(localStorage.getItem("user"));
      dispatch(
        changePassword({
          newPassword: values.newPassword,
          password: values.password,
          confirmPassword: values.confirmPassword,
          associateId: user.associateId,
        })
      );
    },
  });
  const handleToggleCurrentPassword = () => {
    setShowCurrentPassword((prev) => !prev);
  };
  const handleToggleNewPassword = () => {
    setShowNewPassword((prev) => !prev);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };
  useEffect(() => {
    if (isChangePasswordSuccess) {
      setIsSubmitting(false);
      dispatch(
        setMessage({
          message: changePasswordResponse.result[0],
          status: "Success",
        })
      );
      navigate("/login");
      dispatch(reset());
    }
  }, [isChangePasswordSuccess, changePasswordResponse]);

  useEffect(() => {
    if (isChangePasswordFailure) {
      setIsSubmitting(false);
      dispatch(
        setMessage({
          message: changePasswordResponse.error,
          status: "error",
        })
      );
      dispatch(clearMessage());
      setIsSubmitting(false);
    }
  }, [isChangePasswordFailure, changePasswordResponse]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Paper elevation={3} sx={{ padding: 3, maxWidth: 500 }}>
          <div style={{}}>
            <img
              src="./assets/Zyxan log3-01.png"
              alt=""
              style={{ width: "95px", height: "95px" }}
            />
          </div>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ textAlign: "center", marginLeft: "8rem" }}
          >
            Reset Your Password
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              textAlign: "center",
              marginLeft: "9rem",
              marginTop: "0rem",
            }}
          >
            Enter your details below.
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <div className="Reset">
              <TextField
                name="password"
                label="Current Password"
                variant="outlined"
                fullWidth
                sx={{ mt: 3, mb: 2 }}
                type={showCurrentPassword ? "text" : "password"}
                {...formik.getFieldProps("password")}
                error={formik.touched.password && formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleToggleCurrentPassword}
                      >
                        {showCurrentPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                name="newPassword"
                label="New Password"
                variant="outlined"
                fullWidth
                sx={{ mt: 3, mb: 2 }}
                type={showNewPassword ? "text" : "password"}
                {...formik.getFieldProps("newPassword")}
                error={formik.touched.newPassword && formik.errors.newPassword}
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleToggleNewPassword}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                name="confirmPassword"
                label="Confirm Password"
                variant="outlined"
                fullWidth
                sx={{ mt: 3, mb: 2 }}
                type={showConfirmPassword ? "text" : "password"}
                {...formik.getFieldProps("confirmPassword")}
                error={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleToggleConfirmPassword}
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Link
              variant="subtitle2"
              fontWeight="bold"
              underline="hover"
              component={RouterLink}
              to="/login"
            >
              Login?
            </Link>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
              disabled={formik.isSubmitting}
            >
              Reset my Password
            </Button>
          </form>
        </Paper>
      </Box>
      {isSubmitting && (
        <Loader
          open={isSubmitting}
          handleClose={() => setIsSubmitting(false)}
        />
      )}
    </ThemeProvider>
  );
};

export default Reset;
