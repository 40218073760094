import "./Add.css";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from "@mui/material";
import {
  addSelectors,
  deleteProjectCategory,
  deleteWorkLocation,
  getCompanyList,
  insertOrUpdateProjectCategory,
  insertOrUpdateWorkLocation,
  reset,
} from "../../features/add/addSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import DataTable from "../../common/DataTable/DataTable";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../common/Loader/Loader";
import { makeStyles } from "@material-ui/core/styles";
import { setMessage } from "../../features/notification/notificationSlice";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  tab: {
    fontWeight: 700,
    "&:focus": {
      outline: "none",
    },
  },
});

export default function Add() {
  const {
    isInsertProjectCategorySuccess,
    isInsertProjectCategoryFailure,
    isInsertWorkLocationsSuccess,
    isInsertWorkLocationsFailure,
    ProjectCategoryResponse,
    isGetCompanyListSuccess,
    isGetCompanyListFailure,
    getCompanyListResponse,
    workLocationsResponse,
    isDeleteWorkLocationSuccess,
    isDeleteWorkLocationFailure,
    deleteWorkLocationResponse,
    isDeleteProjectCategorySuccess,
    isDeleteProjectCategoryFailure,
    DeleteProjectCategoryResponse,
  } = useSelector(addSelectors);
  const user = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [Snackbar, setSnackbar] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [isOpenWorkLocationConfirmation, setIsOpenWorkLocationConfirmation] =
    useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [deleteWorkLocationRowId, setDeleteWorkLocationRowId] = useState(null);
  const [deleteProjectCategoryRowId, setDeleteProjectCategoryRowId] =
    useState(null);
  const [workLocations, setWorkLocations] = useState({});
  const [projectCategory, setProjectCategory] = useState({});
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
  });

  const checkEmpty = (params) => {
    if (params.props.value === "") {
      params.props.error = true;

      setSnackbar({
        children: "field cannot be empty",
        severity: "error",
      });

      return params.props;
    } else {
      params.props.error = false;
      return params.props;
    }
  };

  const columns = [
    {
      field: "id",
      headerName: " S.No.",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "name",
      headerName: "Company Name",
      flex: 1,
      editable: true,
      minWidth: 80,
      preProcessEditCellProps: checkEmpty,
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      editable: true,
      minWidth: 150,
      preProcessEditCellProps: checkEmpty,
    },
    {
      field: "state",
      headerName: "State",
      flex: 1,
      editable: true,
      minWidth: 150,
      preProcessEditCellProps: checkEmpty,
    },
    {
      field: "zip",
      headerName: "Zip",
      flex: 1,
      editable: true,
      minWidth: 150,
      preProcessEditCellProps: checkEmpty,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      editable: true,
      minWidth: 150,
      preProcessEditCellProps: checkEmpty,
    },
  ];

  const column1 = [
    {
      field: "id",
      headerName: " S.No.",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "categoryDescription",
      headerName: "Project Category Description",
      flex: 1,
      editable: true,
      minWidth: 150,
    },
  ];

  useEffect(() => {
    dispatch(getCompanyList(user.companyId));
  }, []);
  // ...
  useEffect(() => {
    if (getCompanyListResponse && getCompanyListResponse.length > 0) {
      const workLocationArray = [];

      getCompanyListResponse.forEach((company) => {
        const locations = Object.values(company.workLocations);
        locations.forEach((location) => {
          const updatedLocation = {
            ...location,
            id: location.workLocationId || "",
          };
          workLocationArray.push(updatedLocation);
        });
      });

      setWorkLocations(workLocationArray);
    }
    dispatch(reset());
  }, [isGetCompanyListSuccess, getCompanyListResponse]);

  useEffect(() => {
    if (getCompanyListResponse && getCompanyListResponse.length > 0) {
      const projectCategoryArray = [];

      getCompanyListResponse.forEach((company) => {
        const projects = company.projectCategories;
        for (const projectCategoryId in projects) {
          const categoryDescription = projects[projectCategoryId];
          const projectCategory = {
            projectCategoryId,
            categoryDescription,
            id: projectCategoryId,
          };
          projectCategoryArray.push(projectCategory);
        }
      });

      setProjectCategory(projectCategoryArray);
    }
    dispatch(reset());
  }, [isGetCompanyListSuccess, getCompanyListResponse]);

  useEffect(() => {
    if (isInsertWorkLocationsSuccess && workLocationsResponse) {
      dispatch(
        setMessage({
          message: workLocationsResponse.result[0],
          status: workLocationsResponse.status,
        })
      );
      dispatch(getCompanyList(user.companyId));
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isInsertWorkLocationsSuccess, workLocationsResponse]);
  useEffect(() => {
    if (isInsertWorkLocationsFailure && workLocationsResponse) {
      dispatch(
        setMessage({
          message: workLocationsResponse.error,
          status: "error",
        })
      );
      dispatch(getCompanyList(user.companyId));
    }
    dispatch(reset());
  }, [isInsertWorkLocationsFailure, workLocationsResponse]);

  useEffect(() => {
    if (isInsertProjectCategorySuccess && ProjectCategoryResponse) {
      dispatch(
        setMessage({
          message: ProjectCategoryResponse.result[0],
          status: ProjectCategoryResponse.status,
        })
      );
      dispatch(getCompanyList(user.companyId));
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isInsertProjectCategorySuccess, ProjectCategoryResponse]);
  useEffect(() => {
    if (isInsertProjectCategoryFailure && ProjectCategoryResponse) {
      dispatch(
        setMessage({
          message: ProjectCategoryResponse.error,
          status: "error",
        })
      );
      dispatch(getCompanyList(user.companyId));
    }
    dispatch(reset());
  }, [isInsertProjectCategoryFailure, ProjectCategoryResponse]);

  useEffect(() => {
    if (isDeleteWorkLocationSuccess && deleteWorkLocationResponse) {
      dispatch(
        setMessage({
          message: "Work Location Deleted Successfully..",
          status: "Success",
        })
      );
      dispatch(getCompanyList(user.companyId));
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isDeleteWorkLocationSuccess, deleteWorkLocationResponse]);

  useEffect(() => {
    if (isDeleteWorkLocationFailure && deleteWorkLocationResponse) {
      dispatch(
        setMessage({
          message: deleteWorkLocationResponse.error,
          status: "error",
        })
      );
      dispatch(getCompanyList(user.companyId));
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isDeleteWorkLocationFailure, deleteWorkLocationResponse]);

  useEffect(() => {
    if (isDeleteProjectCategorySuccess && DeleteProjectCategoryResponse) {
      dispatch(
        setMessage({
          message: "Project Category Deleted SuccessFully..",
          status: "Success",
        })
      );
      dispatch(getCompanyList(user.companyId));
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isDeleteProjectCategorySuccess, DeleteProjectCategoryResponse]);
  useEffect(() => {
    if (isDeleteProjectCategoryFailure && DeleteProjectCategoryResponse) {
      dispatch(
        setMessage({
          message: DeleteProjectCategoryResponse.error,
          status: "error",
        })
      );
      dispatch(getCompanyList(user.companyId));
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isDeleteProjectCategoryFailure, DeleteProjectCategoryResponse]);

  function handleAdd(data) {
    setIsSubmitting(true);
    if (!data.name || !data.city || !data.state || !data.zip || !data.country) {
      dispatch(
        setMessage({
          message: "Please fill in all required fields",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }
    dispatch(
      insertOrUpdateWorkLocation({
        workLocationId: data.workLocationId,
        name: data.name,
        city: data.city,
        state: data.state,
        zip: data.zip,
        country: data.country,
        id: data.workLocationId,
        companyId: user.companyId,
      })
    );

    dispatch(reset());
  }

  function handleEdit(data) {
    setIsSubmitting(true);
    if (!data.name || !data.city || !data.state || !data.zip || !data.country) {
      dispatch(
        setMessage({
          message: "Please fill in all required fields",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }
    dispatch(
      insertOrUpdateWorkLocation({
        workLocationId: data.workLocationId,
        name: data.name,
        city: data.city,
        state: data.state,
        zip: data.zip,
        country: data.country,
        id: data.workLocationId,
        companyId: user.companyId,
      })
    );

    dispatch(reset());
  }

  function handleAddProject(data) {
    setIsSubmitting(true);
    if (!data.categoryDescription) {
      dispatch(
        setMessage({
          message: "Please fill in all required fields",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }
    dispatch(
      insertOrUpdateProjectCategory({
        projectCategoryId: data.projectCategoryId,
        categoryDescription: data.categoryDescription,
        id: data.projectCategoryId,
        companyId: user.companyId,
      })
    );

    dispatch(reset());
  }

  function handleEditProject(data) {
    setIsSubmitting(true);
    if (!data.categoryDescription) {
      dispatch(
        setMessage({
          message: "Please fill in all required fields",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }
    dispatch(
      insertOrUpdateProjectCategory({
        projectCategoryId: data.projectCategoryId,
        categoryDescription: data.categoryDescription,
        id: data.projectCategoryId,
        companyId: user.companyId,
      })
    );

    dispatch(reset());
  }

  function handleDelete(id) {
    setIsOpenWorkLocationConfirmation(true);
    setDeleteWorkLocationRowId(id);
  }
  function handleDeleteProjectCategory(id) {
    setIsOpenConfirmation(true);
    setDeleteProjectCategoryRowId(id);
  }

  const deleteConfirmDialog = () => {
    return (
      <>
        <Dialog maxWidth="xs" open={isOpenWorkLocationConfirmation}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent dividers>
            You want to Delete this WorkLocation.its irreversable you lost the
            data
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleConfirmationNo}
              variant="outlined"
              color="error"
              size="small"
              startIcon={<CancelIcon />}
            >
              No
            </Button>
            <Button
              onClick={handleConfirmationYes}
              variant="outlined"
              color="success"
              size="small"
              startIcon={<DeleteIcon />}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const handleConfirmationNo = () => {
    setIsOpenWorkLocationConfirmation(false);
  };

  const handleConfirmationYes = () => {
    setIsSubmitting(true);
    dispatch(
      deleteWorkLocation({
        workLocationId: deleteWorkLocationRowId,
      })
    );
    setIsOpenWorkLocationConfirmation(false);
  };

  const projectDeleteConfirmDialog = () => {
    return (
      <>
        <Dialog maxWidth="xs" open={isOpenConfirmation}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent dividers>
            You want to Delete this ProjectCategory.its irreversable you lost
            the data
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleConfirmationProjectNo}
              variant="outlined"
              color="error"
              size="small"
              startIcon={<CancelIcon />}
            >
              No
            </Button>
            <Button
              onClick={handleConfirmationProjectYes}
              variant="outlined"
              color="success"
              size="small"
              startIcon={<DeleteIcon />}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const handleConfirmationProjectNo = () => {
    setIsOpenConfirmation(false);
  };

  const handleConfirmationProjectYes = () => {
    setIsSubmitting(true);
    dispatch(
      deleteProjectCategory({
        projectCategoryId: deleteProjectCategoryRowId,
      })
    );
    setIsOpenConfirmation(false);
  };

  const classes = useStyles();

  return (
    <>
      <div>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          {/* classes={{ selected: label.selected, root: classes.tab }} */}
          <Tab label="Add Company work Location" className={classes.tab} />
          <Tab label="Add project Categories" className={classes.tab} />
        </Tabs>
        {deleteConfirmDialog()}

        {tabIndex === 0 && (
          <div className="Add work location">
            <Box
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  fontSize: 15,
                  fontFamily: "Poppins, sans-serif",
                },
              }}
            >
              <div className="workLocationsGrid">
                <DataTable
                  columns={columns}
                  rows={workLocations}
                  pageSize={8}
                  checkboxSelection={false}
                  isDeleteEnabled={true}
                  isEditEnabled={true}
                  isAddEnabled={true}
                  addButtonText="Add WorkLocation"
                  columnVisibilityModel={columnVisibilityModel}
                  handleAdd={(data) => {
                    handleAdd(data);
                  }}
                  handleEdit={(data) => {
                    handleEdit(data);
                  }}
                  handleDelete={(data) => {
                    handleDelete(data);
                  }}
                />
              </div>
            </Box>
          </div>
        )}

        {projectDeleteConfirmDialog()}
        {tabIndex === 1 && (
          <div className="Add project category">
            <Box
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  fontSize: 15,
                  fontFamily: "Poppins, sans-serif",
                },
              }}
            >
              <div className="projectCategoryGrid">
                <DataTable
                  columns={column1}
                  rows={projectCategory}
                  pageSize={8}
                  checkboxSelection={false}
                  isDeleteEnabled={true}
                  isEditEnabled={true}
                  isAddEnabled={true}
                  addButtonText="Add ProjectCategory"
                  columnVisibilityModel={columnVisibilityModel}
                  handleAdd={(data) => {
                    handleAddProject(data);
                  }}
                  handleEdit={(data) => {
                    handleEditProject(data);
                  }}
                  handleDelete={(data) => {
                    handleDeleteProjectCategory(data);
                  }}
                ></DataTable>
              </div>
            </Box>
          </div>
        )}
      </div>
      {isSubmitting && (
        <Loader
          open={isSubmitting}
          handleClose={() => setIsSubmitting(false)}
        />
      )}
    </>
  );
}
