import { GridValueFormatterParams } from "@mui/x-data-grid";
import _ from "underscore";

const selectOptionFormatter = (params) => {
  const { value, field, api } = params;
  const colDef = api.getColumn(field);

  const { valueOptions } = colDef;
  // <DataGrid columns={columns} rows={products} getRowId={(rows) => rows._id} />

  if (valueOptions && _.isArray(valueOptions) && valueOptions.length > 0) {
    const option = colDef.valueOptions.find((o) => o.value === value);
    if (option) {
      return option.label;
    } else {
      return value;
    }
  }
  return value;
};

export { selectOptionFormatter };
