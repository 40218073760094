import { useNavigate } from "react-router-dom";

const Page403 = () => {
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate("/");
  };
  return (
    <>
      <img src="/assets/403.png" alt="no img" width="70%"></img>
      <br />
      <button
        style={{
          padding: "5px 10px",
          cursor: "pointer",
          textDecoration: "none",
          borderRadius: "5px",
          border: "none",
          color: "#2e7da3",
        }}
        onClick={navigateTo}
      >
        Goto Home page
      </button>
    </>
  );
};

export default Page403;
