import "bootstrap/dist/css/bootstrap.min.css";
import "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "./leave.css";

import * as React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Tab,
  Tabs,
} from "@mui/material";
import {
  createLeave,
  deleteLeave,
  getLeaveDetails,
  getLeaveList,
  leaveSelector,
  reset,
  updateLeave,
} from "../../features/Leaves/LeaveSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import DataTable from "../../common/DataTable/DataTable";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import FullCalendar from "@fullcalendar/react";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Loader from "../../common/Loader/Loader";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MuiAlert from "@mui/material/Alert";
import PropTypes from "prop-types";
import SaveIcon from "@material-ui/icons/Save";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import TextArea from "antd/es/input/TextArea";
import TextField from "@mui/material/TextField";
import dayGridPlugin from "@fullcalendar/daygrid";
import format from "date-fns/format";
import { formateDate } from "../../utils/date";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment/moment";
import { setMessage } from "../../features/notification/notificationSlice";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  tab: {
    fontWeight: 700,
    "&:focus": {
      outline: "none",
    },
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle xs={{ m: 0, p: 8 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export function Leave() {
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const [leaveList, setLeaveList] = useState([]);
  const [leaveDeatils, setleaveDeatils] = useState([]);
  const [leaveType, setLeaveType] = useState("");
  const [id, setId] = useState(null);
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const buttonText = id ? "Update" : "Apply";
  const [error, setError] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [isOpenConfirmation2, setIsOpenConfirmation2] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
  });
  const user = JSON.parse(localStorage.getItem("user"));

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleChange = (event) => {
    setLeaveType(event.target.value);
  };
  const handleClose = () => setOpen(false);

  const handleSelect = (info) => {
    const { start, end } = info;
    setOpen(true);
    setLeaveType("");
    setDescription("");
    setStartDate(start);
    setEndDate(start);
    setId("");
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "leaveType") {
      setLeaveType(value);
    }
    if (id === "description") {
      setDescription(value);
    }
    if (id === startDate) {
      setStartDate(value);
    }
    if (id === endDate) {
      setEndDate(value);
    }
  };
  const eventClick = (e) => {
    var eventObj = e.event;
    if (
      eventObj.id &&
      eventObj.extendedProps.approvalStatus !== "Approve" &&
      eventObj.extendedProps.approvalStatus !== "Reject"
    ) {
      setLeaveType(eventObj.title);
      setDescription(eventObj.extendedProps.description);
      setStartDate(eventObj.start);
      if (!eventObj.end) {
        setEndDate(eventObj.start);
      } else {
        setEndDate(eventObj.end);
      }
      setId(eventObj.id);
      setOpen(true);
    } else {
      console.log('Leave with status "Approve" or "Reject" cannot be edited.');
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(false);
    setIsOpenConfirmation(true);
  };
  // Leave.js
  const handleCancelLeave = () => {
    if (id) {
      setIsOpenConfirmation2(true);
    } else {
    }
  };

  const submitConfirmDialog2 = () => {
    return (
      <>
        <Dialog maxWidth="xs" open={isOpenConfirmation2}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent dividers>
            You want to cancel this Leave?.
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleConfirmationNo2}
              variant="outlined"
              color="error"
              size="small"
              startIcon={<CancelIcon />}
            >
              No
            </Button>
            <Button
              onClick={handleConfirmationYes2}
              variant="outlined"
              color="success"
              size="small"
              startIcon={<DeleteIcon />}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  const handleConfirmationYes2 = (e) => {
    setIsSubmitting(true);
    setIsOpenConfirmation2(false);
    setOpen(false);
    dispatch(deleteLeave(id));
  };
  const handleConfirmationNo2 = () => {
    setIsOpenConfirmation2(false);
  };
  const submitConfirmDialog = () => {
    return (
      <>
        <Dialog maxWidth="xs" open={isOpenConfirmation}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent dividers>You want to apply this Leave?.</DialogContent>
          <DialogActions>
            <Button
              onClick={handleConfirmationNo}
              variant="outlined"
              color="error"
              size="small"
              startIcon={<CancelIcon />}
            >
              No
            </Button>
            <Button
              onClick={handleConfirmationYes}
              variant="outlined"
              color="success"
              size="small"
              startIcon={<CheckCircleIcon />}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  const handleConfirmationNo = () => {
    setIsOpenConfirmation(false);
  };
  const handleConfirmationYes = (e) => {
    e.preventDefault();
    setOpen(false);
    setIsSubmitting(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const formattedStartDate = format(startDate, "yyyyMMdd");
    const formattedEndDate = format(endDate, "yyyyMMdd");
    if (!leaveType) {
      setError("Please enter Leave Type");
      setIsError(true);
      setIsSubmitting(false);
      setOpen(true);
      setIsOpenConfirmation(false);
      return;
    }

    if (!description) {
      setError("Please enter Leave Description");
      setIsError(true);
      setIsSubmitting(false);
      setOpen(true);
      setIsOpenConfirmation(false);
      return;
    }

    if (id === null || id === undefined || id === "") {
      dispatch(
        createLeave({
          leaveType,
          description,
          fromDate: formattedStartDate,
          toDate: formattedEndDate,
          associateId: user.associateId,
          companyId: user.companyId,
        })
      ).then(() => {
        setLeaveType("");
        setDescription("");
        setStartDate(new Date());
        setEndDate(new Date());
        setIsSubmitting(false);
      });
    } else {
      setIsUpdating(true);
      if (!description) {
        setError("Please enter Leave Description");
        setIsError(true);
        setIsSubmitting(false);
        setOpen(true);
        setIsOpenConfirmation(false);
        return;
      }

      if (formattedEndDate === "Invalid time value") {
        setError("Please select a valid To Date");
        setIsError(true);
        setIsSubmitting(false);
        setOpen(true);
        setIsOpenConfirmation(false);
        return;
      }
      dispatch(
        updateLeave({
          leaveId: id,
          leaveType,
          description,
          fromDate: formattedStartDate,
          toDate: formattedEndDate,
          associateId: user.associateId,
          companyId: user.companyId,
        })
      ).then(() => {
        setIsUpdating(false);
        setLeaveType("");
        setDescription("");
        setStartDate(new Date());
        setEndDate(new Date());
        setId("");
        setIsSubmitting(false);
      });
    }
    setIsOpenConfirmation(false);
  };
  const columns = [
    {
      field: "leaveType",
      headerName: "LeaveType",
      width: 300,
      editable: true,
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
      editable: true,
    },
    {
      field: "fromDate",
      headerName: "FromDate",
      width: 200,
      editable: true,
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "toDate",
      headerName: "ToDate",
      width: 200,
      editable: true,
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "approvalStatus",
      headerName: "ApprovalStatus",
      width: 300,
      editable: true,
    },
  ];

  const {
    leaveResponse,
    isGetLeaveDetailsSuccess,
    isGetLeaveListSuccess,
    isCreateLeaveSuccess,
    isCreateLeaveFailure,
    isUpdateLeaveSuccess,
    isUpdateLeaveFailure,
    createResponse,
    updateResponse,
    getResponse,
    isDeleteSuccess,
    isDeleteFailure,
    deleteResponse,
  } = useSelector(leaveSelector);

  useEffect(() => {
    dispatch(getLeaveDetails({}));

    dispatch(getLeaveList(user.associateId));
  }, []);
  useEffect(() => {
    if (leaveResponse) {
      const newArray = leaveResponse.map((v) => {
        return {
          ...v,
          id: v.leaveId,
          fromDate: formateDate(v.fromDate + ""),
          toDate: formateDate(v.toDate + ""),
        };
      });
      setleaveDeatils(newArray);
    }
  }, [isGetLeaveDetailsSuccess, leaveResponse]);

  useEffect(() => {
    if (leaveResponse) {
      setLeaveList(leaveResponse);
    }
  }, [isGetLeaveListSuccess, leaveResponse]);
  useEffect(() => {
    if (isUpdateLeaveSuccess && updateResponse) {
      dispatch(
        setMessage({
          message: updateResponse.result,
          status: updateResponse.status,
        })
      );
      dispatch(getLeaveList(user.associateId));
      setIsSubmitting(false);
    }
  }, [isUpdateLeaveSuccess, updateResponse]);
  useEffect(() => {
    if (isUpdateLeaveFailure && updateResponse) {
      dispatch(
        setMessage({
          message: updateResponse.error,
          status: "error",
        })
      );
      dispatch(getLeaveList(user.associateId));
    }
    setIsSubmitting(false);
    dispatch(reset());
  }, [isUpdateLeaveFailure, updateResponse]);

  useEffect(() => {
    if (isCreateLeaveSuccess) {
      if (createResponse && createResponse) {
        dispatch(
          setMessage({
            message: createResponse.result,
            status: createResponse.status,
          })
        );
      }
      dispatch(getLeaveList(user.associateId));
    }
    setIsSubmitting(false);
    dispatch(reset());
  }, [isCreateLeaveSuccess, createResponse]);

  useEffect(() => {
    if (isCreateLeaveFailure && createResponse) {
      if (createResponse) {
        dispatch(
          setMessage({
            message: createResponse.error,
            status: "error",
          })
        );
      }
      dispatch(getLeaveList(user.associateId));
    }
    setIsSubmitting(false);
    dispatch(reset());
  }, [isCreateLeaveFailure, createResponse]);
  useEffect(() => {
    if (isDeleteSuccess || isDeleteFailure) {
      if (deleteResponse) {
        dispatch(
          setMessage({
            message: deleteResponse.result,
            status: deleteResponse.status,
          })
        );
      }
      dispatch(getLeaveList(user.associateId));
      setIsSubmitting(false);
      dispatch(reset());
    }
  }, [isDeleteSuccess, deleteResponse, isDeleteFailure]);

  useEffect(() => {
    if (leaveResponse) {
      setLeaveList(
        leaveResponse.map((leave) => {
          let startDate = leave.fromDate + "";
          let endDate = leave.toDate + "";
          let color;
          switch (leave.approvalStatus) {
            case "Approve":
              color = "green";
              break;
            case "Reject":
              color = "red";
              break;
            default:
              color = "orange";
              break;
          }
          return {
            id: leave.leaveId,
            title: leave.leaveType,
            start: formateDate(startDate),
            end: formateDate(endDate),
            approvalStatus: leave.approvalStatus,
            description: leave.description,
            color: color,
          };
        })
      );
    }
  }, [isGetLeaveDetailsSuccess, leaveResponse]);

  const classes = useStyles();

  return (
    <>
      <div>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Apply Leave" className={classes.tab} />
          <Tab label="History" className={classes.tab} />
        </Tabs>
        {tabIndex === 0 && (
          <div>
            <div className="Calender" style={{ marginTop: "2rem" }}>
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
                headerToolbar={{
                  left: "today prev next",
                  center: "title",
                  right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                }}
                initialView="dayGridMonth"
                weekends={true}
                events={leaveList}
                select={handleSelect}
                eventColor=""
                selectable
                displayEventTime={false}
                eventClick={eventClick}
                selectAllow={(selectInfo) => {
                  const clickedStartDate = selectInfo.start;
                  const clickedEndDate = selectInfo.end;
                  const today = new Date();
                  const currentWeekStart = new Date(today);
                  const currentWeekEnd = new Date(today);
                  currentWeekStart.setDate(today.getDate() - today.getDay());
                  currentWeekEnd.setDate(
                    today.getDate() + (6 - today.getDay())
                  );
                  const isOverlap = leaveList.some((event) => {
                    const eventStartDate = new Date(event.start);
                    const eventEndDate = new Date(event.end || event.start);
                    return (
                      clickedStartDate <= eventEndDate &&
                      clickedEndDate >= eventStartDate
                    );
                  });

                  return (
                    (clickedStartDate >= currentWeekStart ||
                      clickedStartDate > today) &&
                    clickedStartDate.getDay() !== 0 &&
                    !isOverlap
                  );
                }}
              />
            </div>
            <div>
              {submitConfirmDialog()}
              {submitConfirmDialog2()}
              <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                style={{ width: "auto" }}
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={handleClose}
                >
                  APPLY LEAVE
                </BootstrapDialogTitle>
                <DialogContent dividers>
                  <InputLabel id="leaveType" required>
                    LeaveType
                  </InputLabel>
                  <Select
                    labelId="LeaveType"
                    fullWidth
                    id="leaveType"
                    value={leaveType}
                    label="LeaveType"
                    required
                    onChange={handleChange}
                  >
                    <MenuItem value={"Privilege Leave or Earned Leave"}>
                      Privilege Leave or Earned Leave
                    </MenuItem>
                    <MenuItem value={"Casual Leave"}>Casual Leave</MenuItem>
                    <MenuItem value={"Sick Leave"}>Sick Leave </MenuItem>
                    <MenuItem value={"Marriage Leave"}>Marriage Leave</MenuItem>
                    <MenuItem value={"Half-day leave"}>Half-day leave</MenuItem>
                  </Select>
                  <br />
                  <br />
                  <div style={{ display: "flex" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        required
                        label="StartDate"
                        value={startDate}
                        onChange={(date) => {
                          setStartDate(date.$d);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    &nbsp;
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        required
                        label="EndDate"
                        value={endDate}
                        onChange={(date) => {
                          setEndDate(date.$d);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                  <br />
                  <br />
                  <TextArea
                    className="dark-mode"
                    autoComplete="given-name"
                    name="Description"
                    required
                    fullWidth
                    id="description"
                    label="Description"
                    placeholder="Description"
                    onChange={handleInputChange}
                    value={description}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={handleSubmit}
                    startIcon={<SaveIcon />}
                    disabled={isUpdating}
                  >
                    {buttonText}
                  </Button>
                  {id && (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleCancelLeave}
                      startIcon={<CancelIcon />}
                    >
                      Cancel leave
                    </Button>
                  )}
                </DialogActions>
              </BootstrapDialog>
            </div>
            {isSubmitting && (
              <Loader
                open={isSubmitting}
                handleClose={() => setIsSubmitting(false)}
              />
            )}
          </div>
        )}
        {tabIndex === 1 && (
          <div className="Hystory">
            <Box
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  fontSize: 15,
                  fontFamily: "Poppins, sans-serif",
                  height: "50rem",
                },
              }}
            >
              <DataTable
                columns={columns}
                rows={leaveDeatils}
                pageSize={8}
                checkboxSelection={false}
                isDeleteEnabled={false}
                isEditEnabled={false}
                isAddEnabled={false}
                columnVisibilityModel={columnVisibilityModel}
              ></DataTable>
            </Box>
          </div>
        )}
      </div>
      {isError && (
        <Snackbar
          open={isError}
          autoHideDuration={4000}
          onClose={() => setIsError(false)}
          style={{
            top: -300,
            marginLeft: 600,
          }}
        >
          <MuiAlert onClose={() => setIsError(false)} severity="error">
            {error}
          </MuiAlert>
        </Snackbar>
      )}
    </>
  );
}
