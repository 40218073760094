import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ProjectsService } from "../../services/projects.service";

const initialState = {
  isLoading: false,
  isError: false,
  error: null,
  isSuccess: false,
  success: null,
  isGetProjectsDetailsSuccess: false,
  isGetProjectsDetailsFailure: false,
  isGetCompanyDetailsSuccess: false,
  isGetCompanyDetailsFailure: false,
  isGetProjectsListSuccess: false,
  isGetProjectsListFailure: false,
  isUpdateSuccess: false,
  isUpdateFailure: false,
  isDeleteSuccess: false,
  isDeleteFailure: false,
  isCreateSuccess: false,
  isCreateFailure: false,
  isAddAssociateToProjectSuccess: false,
  isAddAssociateToProjectFailure: false,
  addAssociateToProjectResponse: null,
  isRemoveAssociateToProjectSuccess: false,
  isRemoveAssociateToProjectFailure: false,
  removeAssociateToProjectResponse: null,
  isGetAssociateProjectsSuccess: false,
  isGetAssociateProjectsFailure: false,
  getAssociateProjectsResponse: null,
  projects: null,
  projectsResponse: null,
  projectCategories: null,
  createSuccessResponse: null,
  createFailureResponse: null,
  updateResponse: null,
  deleteResponse: null,
};
export const addAssociateToProject = createAsyncThunk(
  "projects/addAssociateToProject",
  async (data, thunkAPI) => {
    const projectsService = new ProjectsService();
    try {
      const response = await projectsService.addAssociateToProject(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const removeAssociateToProject = createAsyncThunk(
  "projects/removeAssociateToProject",
  async (data, thunkAPI) => {
    const projectsService = new ProjectsService();
    try {
      const response = await projectsService.removeAssociateToProject(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getAssociateProjects = createAsyncThunk(
  "projects/getAssociateProjects",
  async (associateId, thunkAPI) => {
    const projectsService = new ProjectsService();
    try {
      const response = await projectsService.getAssociateProjects(associateId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createProjects = createAsyncThunk(
  "projects/createProjects",
  async (data, thunkAPI) => {
    const projectsService = new ProjectsService();
    try {
      const response = await projectsService.createProjects(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProjectsDetails = createAsyncThunk(
  "projects/getProjectsDetails",
  async (data, thunkAPI) => {
    const projectsService = new ProjectsService();
    const projectId = data.projectId;
    try {
      const response = await projectsService.getProjectsDetails(projectId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCompanyDetails = createAsyncThunk(
  "company/getCompanyDetails",
  async (data, thunkAPI) => {
    const projectsService = new ProjectsService();
    const companyId = data;
    try {
      const response = await projectsService.getCompanyDetails(companyId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProjectsList = createAsyncThunk(
  "projects/getProjectsList",
  async (companyId, thunkAPI) => {
    const projectsService = new ProjectsService();

    try {
      const response = await projectsService.getProjectsList(companyId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getList = createAsyncThunk(
  "projects/getList",
  async (data, thunkAPI) => {
    const projectService = new ProjectsService();
    try {
    } catch (error) {}
  }
);
export const updateProjects = createAsyncThunk(
  "projects/updateProjects",
  async (data, thunkAPI) => {
    const projectsService = new ProjectsService();
    try {
      const response = await projectsService.updateProjects(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteProjects = createAsyncThunk(
  "projects/deleteProjects",
  async (data, thunkAPI) => {
    const projectsService = new ProjectsService();
    try {
      const response = await projectsService.deleteProjects(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const projectsSlice = createSlice({
  name: "projects",
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.error = null;
      state.isSuccess = false;
      state.success = null;
      state.isGetProjectsDetailsSuccess = false;
      state.isGetProjectsDetailsFailure = false;
      state.isGetCompanyDetailsSuccess = false;
      state.isGetCompanyDetailsFailure = false;
      state.isGetProjectsListSuccess = false;
      state.isGetProjectsListFailure = false;
      state.isUpdateSuccess = false;
      state.isUpdateFailure = false;
      state.isCreateSuccess = false;
      state.isCreateFailure = false;
      state.isDeleteSuccess = false;
      state.isDeleteFailure = false;
      state.isAddAssociateToProjectSuccess = false;
      state.isAddAssociateToProjectFailure = false;
      state.addAssociateToProjectResponse = null;
      state.projects = null;
      state.createSuccessResponse = null;
      state.createFailureResponse = null;
      state.projectCategories = null;
      state.deleteResponse = null;
      state.updateResponse = null;
      state.projectsResponse = null;
      state.isRemoveAssociateToProjectSuccess = false;
      state.isRemoveAssociateToProjectFailure = false;
      state.removeAssociateToProjectResponse = null;
      state.isGetAssociateProjectsSuccess = false;
      state.isGetAssociateProjectsFailure = false;
      state.getAssociateProjectsResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(createProjects.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(createProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreateSuccess = true;
        if (action.payload) {
          state.createSuccessResponse = action.payload.data;
        }
      })

      .addCase(createProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateFailure = true;
        if (action.payload) {
          state.createFailureResponse = action.payload;
        }
      })

      .addCase(getProjectsDetails.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getProjectsDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isGetProjectsDetailsSuccess = true;

        if (action.payload) {
          state.projectsResponse = action.payload;
        }
      })

      .addCase(getProjectsDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isGetProjectsDetailsFailure = true;

        if (action.payload) {
          state.projectsResponse = action.payload;
        }
      })
      .addCase(getCompanyDetails.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getCompanyDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isGetCompanyDetailsSuccess = true;
        if (action.payload) {
          state.projectCategories =
            action.payload.data.result[0].projectCategories;
        }
      })

      .addCase(getCompanyDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isGetCompanyDetailsFailure = true;

        if (action.payload) {
          state.companyResponse = action.payload;
        }
      })
      .addCase(getProjectsList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProjectsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isGetProjectsListSuccess = true;
        state.success = true;
        if (action.payload) {
          state.projectsResponse = action.payload.data.result;
        }
      })

      .addCase(getProjectsList.rejected, (state, action) => {
        state.isLoading = false;
        state.isGetProjectsListFailure = true;
        state.success = true;

        if (action.payload) {
          state.projectsResponse = action.payload.data;
        }
      })

      .addCase(updateProjects.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(updateProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdateSuccess = true;
        if (
          action.payload &&
          action.payload.data &&
          action.payload.data.result &&
          action.payload.data.result.length > 0
        ) {
          state.updateResponse = action.payload.data;
        } else {
          state.updateResponse = null;
        }
      })

      .addCase(updateProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.isUpdateFailure = true;
        if (action.payload && action.payload) {
          state.updateResponse = action.payload;
        } else {
          state.updateResponse = null;
        }
      })

      .addCase(deleteProjects.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(deleteProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isDeleteSuccess = true;

        if (action.payload) {
          state.deleteResponse = action.payload.data;
        }
      })

      .addCase(deleteProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.isDeleteFailure = true;

        if (action.payload) {
          state.deleteResponse = action.payload.data;
        }
      })
      .addCase(addAssociateToProject.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(addAssociateToProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAddAssociateToProjectSuccess = true;
        state.isAddAssociateToProjectFailure = false;
        if (action.payload) {
          state.addAssociateToProjectResponse = action.payload.data;
        }
      })

      .addCase(addAssociateToProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isAddAssociateToProjectSuccess = false;
        state.isAddAssociateToProjectFailure = true;
        if (action.payload) {
          state.addAssociateToProjectResponse = action.payload;
        }
      })
      .addCase(removeAssociateToProject.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(removeAssociateToProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isRemoveAssociateToProjectSuccess = true;
        if (
          action.payload &&
          action.payload.data &&
          action.payload.data.result &&
          action.payload.data.result[0]
        ) {
          state.removeAssociateToProjectResponse = action.payload.data;
        }
      })

      .addCase(removeAssociateToProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isRemoveAssociateToProjectFailure = true;
        if (action.payload) {
          state.removeAssociateToProjectResponse = action.payload;
        }
      })

      .addCase(getAssociateProjects.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getAssociateProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isGetAssociateProjectsSuccess = true;
        if (
          action.payload &&
          action.payload.data &&
          action.payload.data.result
        ) {
          state.getAssociateProjectsResponse = action.payload.data.result;
        }
      })

      .addCase(getAssociateProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.isGetAssociateProjectsFailure = true;
        if (action.payload) {
          state.getAssociateProjectsResponse = action.payload.data;
        }
      });
  },
});

export const { reset } = projectsSlice.actions;
export const projectSelectors = (state) => state.projects;
export default projectsSlice.reducer;
