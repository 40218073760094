import { Box, Typography } from "@mui/material";

import NoDataImage from "../../images/noData.png";
import React from "react";

const NoRowsOverlay = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      textAlign: "center",
    }}
  >
    <img
      src={NoDataImage}
      alt="No data available"
      style={{
        width: 100,
        height: 100,
        marginBottom: 16,
      }}
    />
    <Typography variant="body2" color="#164037">
      No data available
    </Typography>
  </Box>
);

export default NoRowsOverlay;
