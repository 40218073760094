// reportSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ReportService } from "../../services/report.service";

const initialState = {
  isLoading: false,
  isError: false,
  error: null,
  isSuccess: false,
  success: null,
  reportData: null,
  isGenerateReportSuccess: false,
  isGenerateReportFailure: false,
  generateReportResponse: null,
  isGetTimesheetSubmitStatusSuccess: false,
  isGetTimesheetSubmitStatusFailure: false,
  TimesheetSubmitStatusResponse: null,
};

export const generateReport = createAsyncThunk(
  "Report/generateReport",
  async ({ startDate, endDate, managerId, associates, projects }, thunkAPI) => {
    const reportService = new ReportService();

    try {
      const response = await reportService.generateReport(
        startDate,
        endDate,
        managerId,
        associates,
        projects
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTimesheetSubmitStatus = createAsyncThunk(
  "Report/getTimesheetSubmitStatus",
  async ({ weekNum, yearVal, companyId }, thunkAPI) => {
    const reportService = new ReportService();

    try {
      const response = await reportService.getTimesheetSubmitStatus(
        weekNum,
        yearVal,
        companyId
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const reportSlice = createSlice({
  name: "report",
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.error = null;
      state.isSuccess = false;
      state.success = null;
      state.reportData = null;
      state.isGenerateReportSuccess = false;
      state.isGenerateReportFailure = false;
      state.generateReportResponse = null;
      state.isGetTimesheetSubmitStatusSuccess = false;
      state.isGetTimesheetSubmitStatusFailure = false;
      state.TimesheetSubmitStatusResponse = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(generateReport.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(generateReport.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          const message = action.payload.data;
          if (message.error) {
            state.isGenerateReportSuccess = false;
            state.isGenerateReportFailure = true;
            state.generateReportResponse = action.payload.data;
          } else {
            state.isGenerateReportSuccess = true;
            state.generateReportResponse = action.payload.data;
          }
        }
      })
      .addCase(generateReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isGenerateReportFailure = true;
        if (action.payload) {
          state.generateReportResponse = action.payload.response.data;
        }
      })
      .addCase(getTimesheetSubmitStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTimesheetSubmitStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          const message = action.payload.data;
          if (message.error) {
            state.isGetTimesheetSubmitStatusSuccess = false;
            state.isGetTimesheetSubmitStatusFailure = true;
            state.TimesheetSubmitStatusResponse = action.payload.data;
          } else {
            state.isGetTimesheetSubmitStatusSuccess = true;
            state.TimesheetSubmitStatusResponse = action.payload.data;
          }
        }
      })
      .addCase(getTimesheetSubmitStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isGetTimesheetSubmitStatusFailure = true;
        if (action.payload) {
          state.TimesheetSubmitStatusResponse = action.payload.response.data;
        }
      });
  },
});

export const { reset } = reportSlice.actions;

export const reportSelector = (state) => state.report;

export default reportSlice.reducer;
