import { HttpClient } from "../utils/httpClient";
import jwtDecode from "jwt-decode";

class taskService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getTaskDetails(taskId) {
    try {
      const response = await this.httpClient.get(`/ui/task/${taskId}/`);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getCompanyDetails(companyId) {
    try {
      const response = await this.httpClient.get(`/ui/company/${companyId}/`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async createTask(task) {
    try {
      const {
        projectId,
        title,
        description,
        priority,
        taskCategory,
        startDate,
        endDate,
        estimatedHoursTotal,
        estimatedHoursPerDay,
        assignedTo,
        taskStatus,
      } = task;
      const response = await this.httpClient.post("/ui/task", {
        projectId: projectId,
        title: title,
        description: description,
        priority: priority,
        taskCategory: taskCategory,
        startDate: startDate,
        endDate: endDate,
        estimatedHoursTotal: estimatedHoursTotal,
        estimatedHoursPerDay: estimatedHoursPerDay,
        assignedTo: assignedTo,
        taskStatus: taskStatus,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  // async getTaskList() {
  //   try {
  //     const response = await this.httpClient.get(
  //       "/ui/query/task"
  //     );
  //     return response;
  //   } catch (error) {
  //     throw error;
  //   }
  // }
  async getTaskList(assignedTo, startDate, endDate) {
    try {
      const response = await this.httpClient.get(
        `/ui/query/task?assignedTo=${assignedTo}&startDate=${startDate}&endDate=${endDate}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getCurrentWeekTasks(associateId, startDate, endDate) {
    try {
      const response = await this.httpClient.get(
        `/ui/query/getCurrentWeekTasks?associateId=${associateId}&startDate=${startDate}&endDate=${endDate}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async updateTask(task) {
    try {
      const response = await this.httpClient.put("/ui/task", task);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async deleteTask(taskId) {
    try {
      const response = await this.httpClient.delete(`/ui/task/${taskId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
}
export { taskService };
