import { Navigate, useLocation } from 'react-router-dom';

import { authSelector } from '../features/auth/authSlice';
import { useSelector } from 'react-redux';

const PublicRoute = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated,user } = useSelector(authSelector);

  if (isAuthenticated && user) {
   return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
};

export default PublicRoute;
