const { REACT_APP_API_URL = "http://localhost:3000" } = process.env;

const API_URL = `${REACT_APP_API_URL}`;
const ROLES = {
  True: "True",
  False: "False",
  Employee: "Employee",
};

export { API_URL, ROLES };
