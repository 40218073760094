import "bootstrap/dist/css/bootstrap.css";
import "./projects.css";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  addAssociateToProject,
  createProjects,
  deleteProjects,
  getCompanyDetails,
  getProjectsList,
  projectSelectors,
  reset,
  updateProjects,
} from "../../features/Projects/Projectslice";
import {
  associateSelectors,
  getAssociateList,
} from "../../features/associate/associateSlice";
import { useDispatch, useSelector } from "react-redux";

import AddAssociate from "./AddAssociate";
import CancelIcon from "@mui/icons-material/Cancel";
import DataTable from "../../common/DataTable/DataTable";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../common/Loader/Loader";
import Page from "../../page/page";
import SearchIcon from "@mui/icons-material/Search";
import format from "date-fns/format";
import { formateDate } from "../../utils/date";
import moment from "moment/moment";
import { selectOptionFormatter } from "../../utils/dataGrid";
import { setMessage } from "../../features/notification/notificationSlice";
import { useState } from "react";

export default function Projects() {
  const {
    projectCategories,
    isGetProjectsListSuccess,
    isUpdateSuccess,
    isUpdateFailure,
    isDeleteSuccess,
    isDeleteFailure,
    isCreateSuccess,
    isCreateFailure,
    projectsResponse,
    createSuccessResponse,
    createFailureResponse,
    updateResponse,
    deleteResponse,
    isAddAssociateToProjectSuccess,
    isAddAssociateToProjectFailure,
    addAssociateToProjectResponse,
    isRemoveAssociateToProjectSuccess,
    isRemoveAssociateToProjectFailure,
    removeAssociateToProjectResponse,
  } = useSelector(projectSelectors);
  const { isGetAssociateListSuccess, associateResponse } =
    useSelector(associateSelectors);
  const [open, setOpen] = React.useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [Snackbar, setSnackbar] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [projectCategoriesList, setProjectCategoriesList] = useState(null);
  const [deleteRowId, setDeleteRowId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    projectId: false,
    id: false,
    fileAttachments: false,
    dateUpdated: false,
    dateCreated: false,
    createdBy: false,
  });
  const dispatch = useDispatch();
  const [allAssociates, setAllAssociates] = useState();
  const [selectedProjectDetails, setSelectedProjectDetails] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);

  const handleAddAssociate = (data) => {
    setSelectedProjectDetails(data);
    setOpen(true);
  };
  const handleAddOrRemoveAssociateToProject = (data) => {
    setIsSubmitting(true);
    if (selectedProjectDetails) {
      const newMembers = data.map((item) => {
        return item.value;
      });
      setOpen(false);

      dispatch(
        addAssociateToProject({
          companyId: user.companyId,
          projectId: selectedProjectDetails.projectId,
          members: newMembers,
        })
      );
      // dispatch(getProjectsList(user.companyId));
    }
  };

  const handleAssociateAction = (finalAssociates) => {
    handleAddOrRemoveAssociateToProject(finalAssociates);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const deleteConfirmDialog = () => {
    return (
      <>
        <Dialog maxWidth="xs" open={isOpenConfirmation}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent dividers>
            You want to Delete this Project?
          </DialogContent>
          <DialogActions>
            <Button
              // ref={noButtonRef}
              onClick={handleConfirmationNo}
              variant="outlined"
              color="error"
              size="small"
              startIcon={<CancelIcon />}
            >
              No
            </Button>
            <Button
              onClick={handleConfirmationYes}
              variant="outlined"
              color="success"
              size="small"
              startIcon={<DeleteIcon />}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const handleConfirmationNo = () => {
    setIsOpenConfirmation(false);
  };

  const handleConfirmationYes = () => {
    setIsSubmitting(true);
    dispatch(deleteProjects(deleteRowId));
    setIsOpenConfirmation(false);
  };

  useEffect(() => {
    dispatch(getAssociateList());
    dispatch(getCompanyDetails(user.companyId));
  }, []);

  useEffect(() => {
    if (user.companyId) {
      dispatch(getProjectsList(user.companyId));
    } else {
      dispatch(getProjectsList(0));
    }
  }, [user.companyId, dispatch]);

  useEffect(() => {
    if (Array.isArray(associateResponse) && projectCategoriesList !== null) {
      setAllAssociates(associateResponse);
      let managers = [];
      associateResponse.map((user) => {
        if (user.isAdmin) {
          let managerD = {
            value: user.associateId,
            label: user.firstName + " " + user.lastName,
          };
          managers = [...managers, managerD];
        }
      });
      setManagerList(managers);
    }
  }, [isGetAssociateListSuccess, associateResponse, projectCategoriesList]);

  function handleAdd(data) {
    if (
      !data.title ||
      !data.startDate ||
      !data.endDate ||
      !data.projectCategory ||
      !data.projectManager ||
      !data.projectValue ||
      !data.estimatedHours ||
      !data.customerName ||
      !data.targetMargin ||
      !data.status ||
      !data.description
    ) {
      dispatch(
        setMessage({
          message: "Please fill in all the required fields",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(true);
    const formattedStartDate = format(data.startDate, "yyyyMMdd");
    const formattedEndDate = format(data.endDate, "yyyyMMdd");

    // Validate projectValue
    let projectValue;
    if (!isNaN(parseFloat(data.projectValue)) && isFinite(data.projectValue)) {
      projectValue = parseFloat(data.projectValue);
    } else {
      dispatch(
        setMessage({
          message: "Please Enter The projectValue as a valid number",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }

    // Validate targetMargin
    let targetMargin;
    if (!isNaN(parseFloat(data.targetMargin)) && isFinite(data.targetMargin)) {
      targetMargin = parseFloat(data.targetMargin);
    } else {
      dispatch(
        setMessage({
          message: "Please Enter The targetMargin as a valid number",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }

    // Validate estimatedHours
    let estimatedHours;
    if (
      !isNaN(parseFloat(data.estimatedHours)) &&
      isFinite(data.estimatedHours)
    ) {
      estimatedHours = parseFloat(data.estimatedHours);
    } else {
      dispatch(
        setMessage({
          message: "Please Enter The Estimated Hours as a valid number",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }

    const companyId = user.companyId;

    dispatch(
      createProjects({
        title: data.title,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        projectCategory: data.projectCategory,
        projectManager: data.projectManager,
        projectValue: projectValue,
        estimatedHours: estimatedHours,
        fileAttachments: data.attachments,
        customerName: data.customerName,
        targetMargin: targetMargin,
        status: data.status,
        description: data.description,
        companyId: companyId,
      })
    );
  }

  function handleEdit(data) {
    setIsSubmitting(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const formattedStartDate = format(data.startDate, "yyyyMMdd");
    const formattedEndDate = format(data.endDate, "yyyyMMdd");

    let projectManager;
    if (
      !isNaN(parseFloat(data.projectManager)) &&
      isFinite(data.projectManager)
    ) {
      projectManager = parseInt(data.projectManager);
    } else {
      dispatch(
        setMessage({
          message: "Please Enter The projectManager",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }

    let projectCategory;
    if (
      !isNaN(parseFloat(data.projectCategory)) &&
      isFinite(data.projectCategory)
    ) {
      projectCategory = parseInt(data.projectCategory);
    } else {
      dispatch(
        setMessage({
          message: "Please Enter The projectCategory",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }
    dispatch(
      updateProjects({
        title: data.title,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        projectCategory: data.projectCategory,
        projectManager: data.projectManager,
        projectValue: data.projectValue,
        estimatedHours: data.estimatedHours,
        fileAttachments: data.attachments,
        customerName: data.customerName,
        targetMargin: data.targetMargin,
        status: data.status,
        description: data.description,
        companyId: user.companyId,
        projectId: data.projectId,
      })
    );
  }

  function handleDelete(id) {
    setIsOpenConfirmation(true);
    setDeleteRowId(id);
  }

  const columns = [
    {
      field: "id",
      headerName: "S.NO.",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "projectId",
      headerName: "S.NO.",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "title",
      headerName: "Title",
      editable: true,
      minWidth: 250,
      maxWidth: 300,
      width: 200,
    },
    {
      field: "description",
      headerName: "Description",
      editable: true,
      minWidth: 350,
      maxWidth: 400,
      width: 350,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 1,
      editable: true,
      minWidth: 180,
      maxWidth: 200,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      valueFormatter: (params) => moment(params?.value).format("DD/MMM/YYYY"),
      flex: 1,
      editable: true,
      minWidth: 200,
      maxWidth: 250,
      type: "date",
    },
    {
      field: "endDate",
      headerName: "End Date",
      valueFormatter: (params) => moment(params?.value).format("DD/MMM/YYYY"),
      flex: 1,
      editable: true,
      minWidth: 200,
      maxWidth: 250,
      type: "date",
    },
    {
      field: "estimatedHours",
      headerName: "Estimated Hours",
      flex: 1,
      editable: true,
      minWidth: 180,
      maxWidth: 200,
      preProcessEditCellProps: (params) => {
        const startDate = moment(params.otherFieldsProps?.startDate.value);
        const endDate = moment(params.otherFieldsProps?.endDate.value);
        const difference = endDate.diff(startDate, "days") + 1;
        const estimatedHours = difference * 8;

        return {
          ...params.props,
          value: estimatedHours ? estimatedHours : 0,
        };
      },
    },

    {
      field: "projectCategory",
      headerName: "Project Category",
      flex: 1,
      editable: true,
      minWidth: 200,
      maxWidth: 250,
      type: "singleSelect",
      valueFormatter: selectOptionFormatter,
      valueOptions: projectCategoriesList,
      valueGetter: (params) => {
        return params.row?.projectCategory || "";
      },
    },
    {
      field: "projectValue",
      headerName: "Project Value",
      flex: 1,
      editable: true,
      minWidth: 160,
      maxWidth: 250,
    },
    {
      field: "targetMargin",
      headerName: "Target Margin",
      flex: 1,
      editable: true,
      minWidth: 160,
      maxWidth: 200,
    },
    {
      field: "projectManager",
      headerName: "Project Manager",
      flex: 1,
      editable: true,
      minWidth: 180,
      maxWidth: 250,
      type: "singleSelect",
      valueFormatter: selectOptionFormatter,
      valueOptions: managerList,
      valueGetter: (params) => {
        return params.row?.projectManager || "";
      },
    },

    {
      field: "fileAttachments",
      headerName: "File Attachements",
      flex: 1,
      editable: false,
      minWidth: 180,
      maxWidth: 200,
    },
    {
      field: "dateUpdated",
      headerName: "Date Updated",
      flex: 1,
      editable: false,
      minWidth: 180,
      maxWidth: 200,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      editable: false,
      minWidth: 180,
      maxWidth: 200,
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 1,
      editable: false,
      minWidth: 180,
      maxWidth: 200,
    },
    {
      field: "status",
      headerName: " Status ",
      flex: 1,
      editable: true,
      minWidth: 100,
      maxWidth: 200,
      type: "singleSelect",
      valueOptions: ["Active", "In-Active"],
    },
  ];
  useEffect(() => {
    dispatch(getAssociateList());
  }, []);
  useEffect(() => {
    dispatch(getCompanyDetails(user.companyId));
  }, []);

  useEffect(() => {
    if (projectCategories) {
      const array = Object.values(projectCategories);
      const newArray = Array.isArray(array)
        ? array.map((categories, index) => {
            return {
              value: index + 1,
              label: categories,
            };
          })
        : [];
      setProjectCategoriesList(newArray);
    }
  }, [projectCategories]);

  useEffect(() => {}, []);
  useEffect(() => {
    if (projectsResponse && projectCategoriesList !== null) {
      const newArray = Array.isArray(projectsResponse)
        ? projectsResponse.map((v) => {
            const projectCat = projectCategoriesList.find((cat) => {
              if (v.projectCategory === cat.value) return cat;
            });

            const projectManager = associateResponse.find((name) => {
              if (name.associateId === v.projectManager) {
                return name.firstName + " " + name.lastName;
              }
            });
            return {
              ...v,
              id: v.projectId,
              projectCategory: projectCat.label,
              startDate: formateDate(v.startDate + ""),
              endDate: formateDate(v.endDate + ""),
              projectManager:
                projectManager?.firstName + " " + projectManager?.lastName ||
                "",
            };
          })
        : [];

      console.log("🚀 ~ useEffect ~ newArray:", newArray);
      if (
        searchTerm !== null &&
        searchTerm !== undefined &&
        searchTerm !== ""
      ) {
        const filtered = newArray.filter((project) =>
          project.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredProjects(filtered);
      } else {
        setFilteredProjects(newArray);
      }
    }
  }, [
    isGetProjectsListSuccess,
    projectsResponse,
    projectCategoriesList,
    searchTerm,
  ]);

  useEffect(() => {
    if (isCreateSuccess && createSuccessResponse) {
      dispatch(
        setMessage({
          message: createSuccessResponse.result,
          status: createSuccessResponse.status,
        })
      );
      dispatch(getProjectsList(user.companyId));
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isCreateSuccess, createSuccessResponse]);

  useEffect(() => {
    if (isCreateFailure && createFailureResponse) {
      dispatch(
        setMessage({
          message: createFailureResponse.error,
          status: "error",
        })
      );
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isCreateFailure, createFailureResponse]);

  useEffect(() => {
    if (isUpdateSuccess && updateResponse) {
      dispatch(
        setMessage({
          message: updateResponse.result[0],
          status: updateResponse.status,
        })
      );
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isUpdateSuccess, updateResponse]);

  useEffect(() => {
    if (isUpdateFailure && updateResponse) {
      dispatch(
        setMessage({
          message: updateResponse.error,
          status: "error",
        })
      );
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isUpdateFailure, updateResponse]);

  useEffect(() => {
    if (isDeleteSuccess || isDeleteFailure) {
      dispatch(
        setMessage({
          message: deleteResponse.result,
          status: deleteResponse.status,
        })
      );
      dispatch(getProjectsList(user.companyId));
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isDeleteSuccess, deleteResponse, isDeleteFailure]);

  useEffect(() => {
    if (
      (addAssociateToProjectResponse && isAddAssociateToProjectSuccess) ||
      isAddAssociateToProjectFailure
    ) {
      if (addAssociateToProjectResponse.status === "Success") {
        dispatch(
          setMessage({
            message: addAssociateToProjectResponse.result[0],
            status: addAssociateToProjectResponse.status,
          })
        );
        dispatch(getProjectsList(user.companyId));
        dispatch(reset());
      } else {
        dispatch(
          setMessage({
            message: addAssociateToProjectResponse.result[0],
            status: addAssociateToProjectResponse.status,
          })
        );
      }
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [
    isAddAssociateToProjectSuccess,
    addAssociateToProjectResponse,
    isAddAssociateToProjectFailure,
  ]);

  useEffect(() => {
    if (
      isRemoveAssociateToProjectSuccess ||
      isRemoveAssociateToProjectFailure
    ) {
      if (removeAssociateToProjectResponse.status === 200) {
        dispatch(
          setMessage({
            message: removeAssociateToProjectResponse.result[0],
            status: removeAssociateToProjectResponse.status,
          })
        );
        dispatch(getProjectsList(user.companyId));
        dispatch(reset());
      } else {
        dispatch(
          setMessage({
            message: removeAssociateToProjectResponse.result[0],
            status: removeAssociateToProjectResponse.status,
          })
        );
      }
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [
    isRemoveAssociateToProjectSuccess,
    removeAssociateToProjectResponse,
    isRemoveAssociateToProjectFailure,
  ]);

  return (
    <div>
      <TextField
        label="Search project by title"
        variant="outlined"
        fullWidth
        size="small"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ marginBottom: 2, width: 300 }}
      />

      <div>
        <Page title="Projects">
          {deleteConfirmDialog()}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={-5}
          ></Stack>
          <Box
            sx={{
              marginTop: 2,
            }}
          >
            <div className="projectGrid">
              <DataTable
                columns={columns}
                rows={filteredProjects}
                pageSize={13}
                title="Projects"
                checkboxSelection={false}
                isDeleteEnabled={true}
                isEditEnabled={true}
                isAddEnabled={true}
                isAddAssociateEnable={true}
                isFabEnable={true}
                columnVisibilityModel={columnVisibilityModel}
                addButtonText="Add New Project"
                handleEdit={(data) => {
                  handleEdit(data);
                }}
                handleDelete={(data) => {
                  handleDelete(data);
                }}
                handleAdd={(data) => {
                  handleAdd(data);
                }}
                handleAddAssociate={(data) => {
                  handleAddAssociate(data);
                }}
              ></DataTable>
            </div>
          </Box>
        </Page>

        {isSubmitting && (
          <Loader
            open={isSubmitting}
            handleClose={() => setIsSubmitting(false)}
          />
        )}

        {open ? (
          <AddAssociate
            handleModal={open}
            projectDetails={selectedProjectDetails}
            associates={allAssociates}
            handleAssociateAction={handleAssociateAction}
            handleCloseModal={handleCloseModal}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
