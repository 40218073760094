import "./PaySlips.css";

import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DisplayDataTable from "./Table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import ZyxanLogo from "../../images/zyxanlogocercle.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const EmployeePaySlip = () => {
  const pdfRef = useRef();
  const [isPdfGenerated, setIsPdfGenerated] = useState(false);
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [totalNetPayable, setTotalNetPayable] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [basicSalary, setBasicSalary] = useState("");
  const [houseRentAllowances, setHouseRentAllowances] = useState("");
  const [specialAllowances, setSpecialAllowances] = useState("");
  const [medicalAllowances, setMedicalAllowances] = useState("");
  const [stipend, setStipend] = useState("");
  const [grossSalary, setGrossSalary] = useState("");
  const [epf, setEpf] = useState("");
  const [pfTax, setPfTax] = useState("");
  const [totalDeductions, setTotalDeductions] = useState("");
  const [healthInsurense, setHealthInsurense] = useState("");
  const [grossSalary2, setGrossSalary2] = useState("");
  const [empId, setEmpId] = useState("");
  const [designation, setDesignation] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [totalWorkingDays, setTotalWorkingDays] = useState("");
  const [lop, setLop] = useState("");
  const [paidDays, setPaidDays] = useState("");
  const [department, setDepartMent] = useState("");
  const [conveyanceAllowances, setConveyanceAllowances] = useState("");

  const handleReset = () => {
    setEmpId("");
    setDesignation("");
    setDateOfJoining("");
    setTotalWorkingDays("");
    setLop("");
    setPaidDays("");
    setDepartMent("");
    setEmployeeName("");
    setBasicSalary("");
    setHouseRentAllowances("");
    setSpecialAllowances("");
    setMedicalAllowances("");
    setStipend("");
    setGrossSalary("");
    setEpf("");
    setPfTax("");
    setTotalDeductions("");
    setHealthInsurense("");
    setGrossSalary2("");
    setTotalNetPayable("");
  };
  const handleNextClick = () => {
    setIsNextClicked(true);
  };
  const handleBackClick = () => {
    setIsNextClicked(false);
    setIsPdfGenerated(false);
  };

  const handleGrossSalary2 = (event) => {
    const value = event.target.value.replace(/[^\d,]/g, "");
    const formattedValue = formatCurrency(value);

    setGrossSalary2(formattedValue);
  };

  const handleEpf = (event) => {
    const value = event.target.value.replace(/[^\d,]/g, "");
    const formattedValue = formatCurrency(value);

    setEpf(formattedValue);
  };
  const handlePfTax = (event) => {
    const value = event.target.value.replace(/[^\d,]/g, "");
    const formattedValue = formatCurrency(value);

    setPfTax(formattedValue);
  };
  const handlTotalDeductions = (event) => {
    const value = event.target.value.replace(/[^\d,]/g, "");
    const formattedValue = formatCurrency(value);

    setTotalDeductions(formattedValue);
  };
  const handleHealthInsurense = (event) => {
    const value = event.target.value.replace(/[^\d,]/g, "");
    const formattedValue = formatCurrency(value);

    setHealthInsurense(formattedValue);
  };

  const handleHouseRentAllowances = (event) => {
    const value = event.target.value.replace(/[^\d,]/g, "");
    const formattedValue = formatCurrency(value);

    setHouseRentAllowances(formattedValue);
  };
  const handleConveyanceAllowances = (event) => {
    const value = event.target.value.replace(/[^\d,]/g, "");
    const formattedValue = formatCurrency(value);

    setConveyanceAllowances(formattedValue);
  };
  const handleSpecialAllowances = (event) => {
    const value = event.target.value.replace(/[^\d,]/g, "");
    const formattedValue = formatCurrency(value);

    setSpecialAllowances(formattedValue);
  };
  const handleMedicalAllowances = (event) => {
    const value = event.target.value.replace(/[^\d,]/g, "");
    const formattedValue = formatCurrency(value);

    setMedicalAllowances(formattedValue);
  };
  const handleStipend = (event) => {
    const value = event.target.value.replace(/[^\d,]/g, "");
    const formattedValue = formatCurrency(value);

    setStipend(formattedValue);
  };
  const handleGrossSalary = (event) => {
    const value = event.target.value.replace(/[^\d,]/g, "");
    const formattedValue = formatCurrency(value);

    setGrossSalary(formattedValue);
  };

  const handleBasicSalary = (event) => {
    const value = event.target.value.replace(/[^\d,]/g, "");
    const formattedValue = formatCurrency(value);

    setBasicSalary(formattedValue);
  };

  const handleTotalNetPayableChange = (event) => {
    const value = event.target.value.replace(/[^\d,]/g, "");
    const formattedValue = formatCurrency(value);

    setTotalNetPayable(formattedValue);
  };
  const formatCurrency = (value) => {
    // Remove leading zeros and commas
    const numericValue = Number(value.replace(/^0+|,/g, ""));

    // Check if the numericValue is a valid number
    if (isNaN(numericValue)) {
      return "";
    }

    // Format the number as currency with ₹ symbol and commas
    const formattedValue = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(numericValue);

    return formattedValue;
  };
  const generatePDF = () => {
    const input = pdfRef.current;
    input.style.fontSize = "20px";
    const pdf = new jsPDF("p", "mm", "a4");
    const scale = 2.2;
    html2canvas(input, { scale }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      pdf.addImage(imgData, "PNG", 10, 10, 180, 130);
      const fileName = employeeName || "payslip";
      pdf.save(`${fileName}_Payslip.pdf`);
    });
    setIsPdfGenerated(true);
  };

  useEffect(() => {
    setCurrentDate(new Date());
  }, []);

  // const getCurrentDate = () => {
  //   const options = { month: "short", year: "numeric" };
  //   const formattedDate = currentDate.toLocaleDateString("en-US", options);
  //   const [month, year] = formattedDate.split(" ");
  //   return `${month}/${year}`;
  // };
  const getCurrentDate = () => {
    const options = { month: "long", year: "numeric" };
    const formattedDate = currentDate.toLocaleDateString("en-US", options);
    const [month, year] = formattedDate.split(" ");
    return `${month}-${year}`;
  };

  const handlePreviousMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() - 1);
    setCurrentDate(newDate);
  };
  const handleNextMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + 1);
    setCurrentDate(newDate);
  };

  return (
    <>
      {!isNextClicked && (
        <div>
          <div className="centered-content2 ">
            <Tooltip title="Previous Month">
              <IconButton className="icon-button" onClick={handlePreviousMonth}>
                <ArrowBackIosNewIcon style={{ color: "#164037" }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Next Month">
              <IconButton className="icon-button" onClick={handleNextMonth}>
                <ArrowForwardIosIcon style={{ color: "#164037" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div className="main">
            <div className="payslipHeader">
              <div>
                <img
                  src={ZyxanLogo}
                  alt=""
                  style={{
                    width: "170px",
                    height: "159px",
                  }}
                />
              </div>

              <div className="payHead">
                <Typography variant="h4">
                  Zyxan Technologies Private Limited
                </Typography>
                <Typography variant="h5">
                  Sri Lakshmi Nivas, 5-24-398/S-2, Road No. 5
                </Typography>
                <Typography variant="h5">
                  K P H B Phase 3, Kukatpally, Hyderabad
                </Typography>
                <Typography variant="h6">
                  Payslip - {getCurrentDate()}
                </Typography>
              </div>
              <div
                style={{
                  width: "170px",
                  height: "159px",
                  background: "white",
                }}
              ></div>
            </div>

            <hr style={{ margin: "10px 0", borderColor: "#164037" }} />
            <div className="employdetails">
              <Typography variant="h6" fontWeight={"bold"} gutterBottom>
                EMPLOYEE SUMMARY
              </Typography>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel">Employee Name</InputLabel>
                  <TextField
                    fullWidth
                    value={employeeName}
                    onChange={(e) => setEmployeeName(e.target.value)}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputLabel className="inputlabel">Employee ID</InputLabel>
                  <TextField
                    fullWidth
                    value={empId}
                    onChange={(e) => setEmpId(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel">Designation</InputLabel>
                  <TextField
                    fullWidth
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel">Department</InputLabel>
                  <TextField
                    fullWidth
                    value={department}
                    onChange={(e) => setDepartMent(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel">
                    Date of Joining
                  </InputLabel>
                  <TextField
                    type="date"
                    fullWidth
                    value={dateOfJoining}
                    onChange={(e) => setDateOfJoining(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel">Gross Salary</InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    value={grossSalary2}
                    onChange={handleGrossSalary2}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputLabel className="inputlabel">
                    Total Working Days
                  </InputLabel>
                  <TextField
                    type="number"
                    fullWidth
                    value={totalWorkingDays}
                    onChange={(e) => setTotalWorkingDays(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputLabel className="inputlabel">LOP Days</InputLabel>
                  <TextField
                    type="number"
                    fullWidth
                    value={lop}
                    onChange={(e) => setLop(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputLabel className="inputlabel">Paid Days</InputLabel>
                  <TextField
                    type="number"
                    fullWidth
                    value={paidDays}
                    onChange={(e) => setPaidDays(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>

            <div className="ernings">
              <Grid item xs={3}>
                <Typography variant="h6" fontWeight={"bold"}>
                  EARNINGS
                </Typography>
              </Grid>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel">Basic Salary</InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    value={basicSalary}
                    onChange={handleBasicSalary}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel">
                    House Rent Allowances
                  </InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    value={houseRentAllowances}
                    onChange={handleHouseRentAllowances}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel">
                    Conveyance Allowances
                  </InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    value={conveyanceAllowances}
                    onChange={handleConveyanceAllowances}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel">
                    Medical Allowances
                  </InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    value={medicalAllowances}
                    onChange={handleMedicalAllowances}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel">
                    Special Allowances
                  </InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    value={specialAllowances}
                    onChange={handleSpecialAllowances}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel">Stipend</InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    value={stipend}
                    onChange={handleStipend}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel">Gross Salary</InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    value={grossSalary}
                    onChange={handleGrossSalary}
                  />
                </Grid>
              </Grid>
            </div>

            <div className="deductions">
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight={"bold"}>
                  DEDUCTIONS
                </Typography>
              </Grid>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel">EPF</InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    value={epf}
                    onChange={handleEpf}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel">
                    Health Insurance/ESI
                  </InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    value={healthInsurense}
                    onChange={handleHealthInsurense}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel">
                    Professional Tax
                  </InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    value={pfTax}
                    onChange={handlePfTax}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel">
                    Total Deductions
                  </InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    value={totalDeductions}
                    onChange={handlTotalDeductions}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className="inputlabel2">
                    TOTAL NET PAYABLE
                  </InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    value={totalNetPayable}
                    onChange={handleTotalNetPayableChange}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      )}
      {!isNextClicked && (
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNextClick}
            startIcon={<NavigateNextIcon />}
          >
            Next
          </Button>
          &emsp;
          <Button
            variant="contained"
            color="secondary"
            onClick={handleReset}
            startIcon={<RotateLeftIcon />}
          >
            Reset
          </Button>
        </div>
      )}
      {isNextClicked && (
        <DisplayDataTable
          ref={pdfRef}
          employeeName={employeeName}
          empId={empId}
          grossSalary={grossSalary}
          designation={designation}
          department={department}
          dateOfJoining={dateOfJoining}
          totalWorkingDays={totalWorkingDays}
          lop={lop}
          paidDays={paidDays}
          basicSalary={basicSalary}
          houseRentAllowances={houseRentAllowances}
          medicalAllowances={medicalAllowances}
          specialAllowances={specialAllowances}
          stipend={stipend}
          grossSalary2={grossSalary2}
          epf={epf}
          healthInsurense={healthInsurense}
          pfTax={pfTax}
          totalDeductions={totalDeductions}
          totalNetPayable={totalNetPayable}
          conveyanceAllowances={conveyanceAllowances}
          payslipHeader=<div className="payslipHeader">
            <div>
              <img
                src={ZyxanLogo}
                alt=""
                style={{
                  width: "170px",
                  height: "159px",
                }}
              />
            </div>

            <div className="payHead">
              <Typography variant="h4">
                Zyxan Technologies Private Limited
              </Typography>
              <Typography variant="h5">
                Sri Lakshmi Nivas, 5-24-398/S-2, Road No. 5
              </Typography>
              <Typography variant="h5">
                K P H B Phase 3, Kukatpally, Hyderabad
              </Typography>
              <Typography variant="h6">Payslip - {getCurrentDate()}</Typography>
            </div>
            <div
              style={{
                width: "170px",
                height: "159px",
                background: "white",
              }}
            ></div>
          </div>
        />
      )}
      {isNextClicked && (
        <div className="button2">
          {" "}
          <Button
            variant="contained"
            color="primary"
            onClick={generatePDF}
            startIcon={<FileDownloadIcon />}
          >
            Generate PDF
          </Button>
          &emsp;
          <Button
            variant="contained"
            color="primary"
            onClick={handleBackClick}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
        </div>
      )}
    </>
  );
};

export default EmployeePaySlip;
