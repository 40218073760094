import { Navigate, useRoutes } from "react-router-dom";

import Add from "../components/Add/Add.jsx";
import Associate from "../components/Associate/Associate";
import Component1 from "../components/Component1/Component1";
import Dashboard from "../components/Dashboard/Dashboard";
import EmployeePaySlip from "../components/PaySlips/PaySlips.jsx";
import ForgotPassword from "../components/ForgetPassword/ForgetPassword";
import Holidays from "../components/Holidays/Holidays";
import Layout from "../common/Layout/Layout";
import { Leave } from "../components/Leaves/Leave";
import LeavesHistory from "../components/LeaveHystory/LeaveHystory.jsx";
import Login from "../components/Login/Login";
import NotAuthorized from "../pages/page403";
import NotFound from "../pages/page404";
import OuterLayout from "../common/Layout/OuterLayout";
import Projects from "../components/Projects/projects";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import ReportPage from "../components/Report/report.jsx";
import Reset from "../components/ResetPassword/ResetPassword";
import RouteNavigator from "./RouteNavigator";
import Signup from "../components/Signup/Signup";
import StatusPage from "../components/Status/status.jsx";
import Task from "../components/Task/Task";
import Timesheet from "../components/Timesheet/Timesheet";
import Todo from "../components/Todo/todo";

// layouts

export default function Router() {
  return useRoutes([
    {
      path: "/app",
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ),
      children: [
        { path: "holidays", element: <Holidays /> },
        { path: "leave", element: <Leave /> },
        { path: "task", element: <Task /> },
        { path: "associate", element: <Associate /> },
        { path: "projects", element: <Projects /> },
        { path: "todos", element: <Todo /> },
        { path: "timeSheets", element: <Timesheet /> },
        { path: "add", element: <Add /> },
        { path: "leaveHistory", element: <LeavesHistory /> },
        { path: "report", element: <ReportPage /> },
        { path: "status", element: <StatusPage /> },
        { path: "payslip", element: <EmployeePaySlip /> },
      ],
    },
    {
      path: "/",
      element: <OuterLayout />,
      children: [
        {
          path: "/",
          element: <RouteNavigator />,
        },
        {
          path: "login",
          element: (
            <PublicRoute>
              <Login />
            </PublicRoute>
          ),
        },
        {
          path: "register",
          element: (
            <PublicRoute>
              <Signup />
            </PublicRoute>
          ),
        },
        {
          path: "reset-password",
          element: <Reset />,
          children: [{ path: ":token", element: <Reset /> }],
        },
        {
          path: "forgot-password",
          element: <ForgotPassword />,
        },

        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
        { path: "403", element: <NotAuthorized /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
