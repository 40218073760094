import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { todoService } from "../../services/todo.service";

const intialState = {
  user: null,
  isLoading: false,
  isError: false,
  error: null,
  isSuccess: false,
  success: null,
  isExtendTaskSuccess: false,
  isExtendTaskFailure: false,
  isGetTaskExtendStatusSuccess: false,
  isGetTaskExtendStatusFailure: false,
  isGetTodoDetailsSuccess: false,
  isGetTodoDetailsFailure: false,
  isCreateTodoSuccess: false,
  isCreateTodoFailure: false,
  isGetListSuccess: false,
  isGetListFailure: false,
  isUpdateTodoSuccess: false,
  isUpdateTodoFailure: false,
  isGetApprovalStatusSuccess: false,
  isGetApprovalStatusFailure: false,
  isGetDetailsSuccess: false,
  isGetDetailsFailure: false,
  isGetAssociateLeaveListSuccess: false,
  isGetAssociateLeaveListFailure: false,
  isGetTaskByTodoSuccess: false,
  isGetTaskByTodoFailure: false,
  getAssociateLeaveListResponse: null,
  createResponse: null,
  updateResponse: null,
  todoResponse: null,
  createSuccessResponse: null,
  createFailureResponse: null,
  todoRejectResponse: null,
  extendTaskResponse: null,
  getApprovalStatusResponse: null,
  getListResponse: null,
  getTaskExtendStatusResponse: null,
  getDetailsResponse: null,
  getTaskByTodoResponse: null,
};

export const extendTask = createAsyncThunk(
  "task/extendTask",
  async (data, thunkAPI) => {
    const TodoService = new todoService();
    try {
      const response = await TodoService.extendTask(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createTodo = createAsyncThunk(
  "todo/createTodo",
  async (data, thunkAPI) => {
    const TodoService = new todoService();

    const todoId = data.todoId;
    try {
      const response = await TodoService.createTodo(todoId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getTaskExtendStatus = createAsyncThunk(
  "todo/getTaskExtendStatus ",
  async (data, thunkAPI) => {
    const TodoService = new todoService();

    const taskId = data.taskId;
    try {
      const response = await TodoService.getTaskExtendStatus(taskId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getApprovalStatus = createAsyncThunk(
  "todo/getApprovalStatus",
  async (data, thunkAPI) => {
    const TodoService = new todoService();
    try {
      const response = await TodoService.getApprovalStatus(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getList = createAsyncThunk(
  "todo/getList",

  async (companyId, thunkAPI) => {
    const TodoService = new todoService();
    try {
      const response = await TodoService.getList(companyId);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTaskByTodo = createAsyncThunk(
  "todo/getTaskByTodo",

  async (data, thunkAPI) => {
    const TodoService = new todoService();
    try {
      const response = await TodoService.getTaskByTodo(data);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAssociateLeaveList = createAsyncThunk(
  "todo/getAssociateLeaveList",
  async (data, thunkAPI) => {
    const TodoService = new todoService();
    try {
      const response = await TodoService.getAssociateLeaveList(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTodo = createAsyncThunk(
  "todo/updateTodo",

  async (data, thunkAPI) => {
    const TodoService = new todoService();
    try {
      const response = await TodoService.updateTodo(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getDetails = createAsyncThunk(
  "todo/getDetails",
  async (data, thunkAPI) => {
    const TodoService = new todoService();
    try {
      const response = await TodoService.getDetails(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const todoSlice = createSlice({
  name: "todo",
  initialState: intialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.error = null;
      state.isSuccess = false;
      state.success = null;
      state.isExtendTaskSuccess = false;
      state.isExtendTaskFailure = false;
      state.isGetTaskExtendStatusSuccess = false;
      state.isGetTaskExtendStatusFailure = false;
      state.isGetTodoDetailsSuccess = false;
      state.isGetTodoDetailsFailure = false;
      state.isCreateTodoSuccess = false;
      state.isCreateTodoFailure = false;
      state.isGetListSuccess = false;
      state.isGetListFailure = false;
      state.isUpdateTodoSuccess = false;
      state.isUpdateTodoFailure = false;
      state.isGetApprovalStatusSuccess = false;
      state.isGetApprovalStatusFailure = false;
      state.isGetDetailsSuccess = false;
      state.isGetDetailsFailure = false;
      state.isGetAssociateLeaveListSuccess = false;
      state.isGetAssociateLeaveListFailure = false;
      state.isGetTaskByTodoSuccess = false;
      state.isGetTaskByTodoFailure = false;
      state.getAssociateLeaveListResponse = null;
      state.createResponse = null;
      state.updateResponse = null;
      state.todoResponse = null;
      state.createSuccessResponse = null;
      state.createFailureResponse = null;
      state.todoRejectResponse = null;
      state.extendTaskResponse = null;
      state.getApprovalStatusResponse = null;
      state.getListResponse = null;
      state.getTaskExtendStatusResponse = null;
      state.getDetailsResponse = null;
      state.getTaskByTodoResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(extendTask.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(extendTask.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isExtendTaskSuccess = true;
        if (action.payload) {
          state.extendTaskResponse = action.payload.data;
        }
      })
      .addCase(extendTask.rejected, (state, action) => {
        state.isLoading = false;
        state.isExtendTaskFailure = true;
        if (action.payload) {
          state.extendTaskResponse = action.payload.response.data;
        }
      })
      .addCase(getApprovalStatus.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getApprovalStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isGetApprovalStatusSuccess = true;
        if (action.payload) {
          state.getApprovalStatusResponse = action.payload.data;
        }
      })
      .addCase(getApprovalStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isGetApprovalStatusFailure = true;
        if (action.payload) {
          state.getApprovalStatusResponse = action.payload;
        }
      })
      .addCase(getDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isGetDetailsSuccess = true;
        if (action.payload) {
          state.getDetailsResponse = action.payload.data;
        }
      })
      .addCase(getDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isGetDetailsFailure = true;
        if (action.payload) {
          state.getDetailsResponse = action.payload;
        }
      })
      .addCase(getList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isGetListSuccess = true;
        if (action.payload) {
          state.getListResponse = action.payload.data.result;
        }
      })

      .addCase(getList.rejected, (state, action) => {
        state.isLoading = false;
        state.isGetListFailure = true;
        if (action.payload) {
          state.getListResponse = action.payload.response.data;
        }
      })

      .addCase(getAssociateLeaveList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAssociateLeaveList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isGetAssociateLeaveListSuccess = true;
        if (action.payload) {
          console.log("action.payload::", action.payload);
          state.getAssociateLeaveListResponse = action.payload.data.result;
        }
      })

      .addCase(getAssociateLeaveList.rejected, (state, action) => {
        state.isLoading = false;
        state.isGetAssociateLeaveListFailure = true;
        if (action.payload) {
          state.getAssociateLeaveListResponse = action.payload.response.data;
        }
      })
      .addCase(updateTodo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateTodo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdateTodoSuccess = true;
        if (action.payload) {
          state.updateResponse = action.payload.data;
        }
      })
      .addCase(updateTodo.rejected, (state, action) => {
        state.isLoading = false;
        state.isUpdateTodoFailure = true;
        if (action.payload && action.payload.response) {
          state.updateResponse = action.payload.response.data;
        }
      })

      .addCase(getTaskExtendStatus.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTaskExtendStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isGetTaskExtendStatusSuccess = true;
        if (action.payload) {
          state.getTaskExtendStatusResponse = action.payload.data;
        }
      })
      .addCase(getTaskExtendStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isGetTaskExtendStatusFailure = true;
        if (action.payload) {
          state.getTaskExtendStatusResponse = action.payload;
        }
      })
      .addCase(getTaskByTodo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTaskByTodo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isGetTaskByTodoSuccess = true;
        if (action.payload) {
          state.getTaskByTodoResponse = action.payload.data;
        }
      })
      .addCase(getTaskByTodo.rejected, (state, action) => {
        state.isLoading = false;
        state.isGetTaskByTodoFailure = true;
        if (action.payload) {
          state.getTaskByTodoResponse = action.payload;
        }
      });
  },
});
export const { reset } = todoSlice.actions;

export const todoSelector = (state) => state.todo;

export default todoSlice.reducer;
