import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";

import { clearMessage } from "../../features/notification/notificationSlice";
import { useDispatch } from "react-redux";

function Notification(props) {
  const { type, message, variant, vertical, horizontal, duration } = props;
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const notificationTimer = setTimeout(() => {
      dispatch(clearMessage());
    }, 10000);

    return () => clearTimeout(notificationTimer);
  }, []);

  // Define default colors based on the type
  const defaultColors = {
    success: { backgroundColor: "#90EE90", color: "black" },
    error: { backgroundColor: "#FF9999", color: "black" },
    warning: { backgroundColor: "#ff9f00", color: "black" },
    info: { backgroundColor: "#87cefa", color: "black" },
  };

  const alertStyle = {
    ...defaultColors[type],
  };

  return (
    <Snackbar
      style={{ marginTop: "4rem" }}
      open={open}
      autoHideDuration={duration}
      anchorOrigin={{
        vertical: vertical !== undefined ? vertical : "top",
        horizontal: horizontal !== undefined ? horizontal : "center",
      }}
      onClose={() => setOpen(false)}
    >
      <Alert
        style={alertStyle}
        variant={variant}
        severity={type}
        onClose={() => setOpen(false)}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default Notification;
