import "./Associate.css";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import {
  associateSelectors,
  createAssociate,
  deleteAssociate,
  getAssociateList,
  getCompanyDetails,
  reset,
  updateAssociate,
} from "../../features/associate/associateSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import DataTable from "../../common/DataTable/DataTable";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../common/Loader/Loader";
import Page from "../../page/page";
import { formateDate } from "../../utils/date";
import moment from "moment";
import { selectOptionFormatter } from "../../utils/dataGrid";
import { setMessage } from "../../features/notification/notificationSlice";

export default function Associate() {
  const {
    workLocations,
    isGetCompanyDetailsSuccess,
    isGetAssociateListSuccess,
    isUpdateSuccess,
    isUpdateFailure,
    isDeleteSuccess,
    isDeleteFailure,
    isCreateSuccess,
    isCreateFailure,
    createSuccessResponse,
    createFailureResponse,
    updateResponse,
    deleteResponse,
    associateResponse,
  } = useSelector(associateSelectors);
  const user = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();
  const [associateList, setAssociateList] = useState([]);
  const [Snackbar, setSnackbar] = useState([]);
  const [workLocationsList, setWorkLocationsList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [deleteRowId, setDeleteRowId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    associateId: false,
    companyId: false,
    id: false,
    password: false,
    isFirstTimeLogin: false,
  });
  const checkEmpty = (params) => {
    if (params.props.value === "") {
      params.props.error = true;

      setIsSubmitting(false); // Set isSubmitting to false
      dispatch(
        setMessage({
          message: "Field cannot be empty",
          status: "error",
        })
      );

      return params.props;
    } else {
      params.props.error = false;
      return params.props;
    }
  };

  const columns = [
    {
      field: "id",
      headerName: " S.No.",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "associateId",
      headerName: "Code",
      flex: 1,
      editable: false,
      minWidth: 80,
    },
    {
      field: "associateNumber",
      headerName: "Employee ID",
      flex: 1,
      editable: true,
      minWidth: 150,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      editable: true,
      minWidth: 150,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      editable: true,
      minWidth: 150,
    },
    {
      field: "designation",
      headerName: "Designation",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        "Trainee Engineer",
        "Software Engineer",
        "Intern",
        "Admin",
        "HR Manager",
        "TechnicalLead",
        "ProjectManager",
        "UX/UI Designer",
        "QA Analyst",
      ],
      minWidth: 200,
    },

    {
      field: "dateOfBirth",
      headerName: "Date Of Birth",
      flex: 1,
      editable: true,
      minWidth: 150,
      type: "date",
      valueFormatter: (params) => {
        const date = moment(params.value).isValid()
          ? moment(params.value).format("DD-MM-YYYY")
          : "dd-mm-yyyy";
        return date;
      },
      valueGetter: (params) => {
        return params.row.dateOfBirth &&
          moment(params.row.dateOfBirth).isValid()
          ? moment(params.row.dateOfBirth).toDate()
          : "";
      },
    },
    {
      field: "dateOfJoining",
      headerName: "Date Of Joining",
      flex: 1,
      editable: true,
      minWidth: 150,
      type: "date",
      valueFormatter: (params) => {
        const date = moment(params.value).isValid()
          ? moment(params.value).format("DD-MM-YYYY")
          : "dd-mm-yyyy";
        return date;
      },
      valueGetter: (params) => {
        return params.row.dateOfJoining &&
          moment(params.row.dateOfJoining).isValid()
          ? moment(params.row.dateOfJoining).toDate()
          : "";
      },
    },
    {
      field: "gmailId",
      headerName: "Gmail",
      flex: 1,
      editable: true,
      minWidth: 250,
    },
    {
      field: "workEmail",
      headerName: "Work Email",
      flex: 1,
      editable: true,
      minWidth: 250,
    },
    {
      field: "cellPhone",
      headerName: "Cell Phone",
      flex: 1,
      editable: true,
      minWidth: 200,
    },
    {
      field: "workLocation",
      headerName: "Work Location",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueFormatter: selectOptionFormatter,
      valueOptions: workLocationsList,
      valueGetter: (params) => {
        return params.row?.workLocation || "";
      },
      minWidth: 250,
    },
    {
      field: "password",
      headerName: "Password",
      flex: 1,
      editable: false,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Permanent", "Contract"],
      minWidth: 150,
    },
    {
      field: "isFirstTimeLogin",
      headerName: "IsFirstTimeLogin",
      flex: 1,
      editable: false,
    },
    {
      field: "companyId",
      headerName: "CompanyId",
      editable: false,
      flex: 1,
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: true,
      type: "singleSelect",
      minWidth: 100,
      valueOptions: ["Active", "Inactive"],
    },
    {
      field: "associateManager",
      headerName: "Manager",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueFormatter: selectOptionFormatter,
      valueOptions: managerList,
      valueGetter: (params) => {
        if (params.row?.associateManager === "undefined undefined") {
          return "";
        } else {
          return params.row?.associateManager || "";
        }
      },
      minWidth: 200,
    },
  ];

  useEffect(() => {
    dispatch(getAssociateList(user.companyId));
  }, []);

  useEffect(() => {
    dispatch(getCompanyDetails(user.companyId));
  }, []);

  useEffect(() => {
    if (workLocations !== null) {
      const array = Object.values(workLocations);
      setWorkLocationsList(
        array.map((location) => ({
          ...location,
          value: location.workLocationId,
          label: location.name + " " + location.city,
        }))
      );
    }
  }, [workLocations]);

  useEffect(() => {}, [associateList]);

  useEffect(() => {}, [managerList]);

  useEffect(() => {
    if (associateResponse) {
      let managers = [];
      associateResponse.map((user) => {
        if (user.isAdmin) {
          let managerD = {
            value: user.associateId,
            label: user.firstName + " " + user.lastName,
          };
          managers = [...managers, managerD];
        }
      });
      setManagerList(managers);

      const newArray = associateResponse.map((v) => {
        let workLocation = workLocationsList.find((location) => {
          if (location.value === v.workLocation) {
            return location.name + " " + location.city;
          }
        });

        const associateManager = associateResponse.find((name) => {
          if (name.associateId === v.associateManager) {
            return name.firstName + " " + name.lastName;
          }
        });
        workLocation = { ...workLocation };

        return {
          ...v,
          id: v.associateId,
          workLocation: workLocation.label,
          dateOfJoining: formateDate(v.dateOfJoining + ""),
          dateOfBirth: formateDate(v.dateOfBirth + ""),
          associateManager:
            associateManager?.firstName + " " + associateManager?.lastName ||
            "",
        };
      });
      setAssociateList(newArray);
    }
  }, [isGetAssociateListSuccess, associateResponse, workLocationsList]);

  useEffect(() => {}, []);

  function handleAdd(data) {
    setIsSubmitting(true);
    const isEmailExists = associateList.some(
      (associate) => associate.gmailId === data.gmailId
    );

    if (isEmailExists) {
      dispatch(
        setMessage({
          message: "Email already exists",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }

    const dateOfJoining = parseInt(
      moment(data.dateOfJoining).format("YYYYMMDD")
    );
    const dateOfBirth = parseInt(moment(data.dateOfBirth).format("YYYYMMDD"));

    let workLocation;
    if (!isNaN(parseFloat(data.workLocation)) && isFinite(data.workLocation)) {
      workLocation = parseInt(data.workLocation);
    } else {
      dispatch(
        setMessage({
          message: "Please Enter The Work Location",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }

    let associateManager;
    if (
      !isNaN(parseFloat(data.associateManager)) &&
      isFinite(data.associateManager)
    ) {
      associateManager = parseInt(data.associateManager);
    } else {
      dispatch(
        setMessage({
          message: "Please Select The Associate Manager",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@zyxantech\.com$/;
    if (!emailPattern.test(data.workEmail)) {
      dispatch(
        setMessage({
          message:
            "Invalid work email format. It should be in the form of zyxantech.com",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }

    const phoneNumber = data.cellPhone;
    const phoneNumberPattern = /^\d{10}$/;

    if (!phoneNumberPattern.test(phoneNumber)) {
      dispatch(
        setMessage({
          message: "Phone number should be a 10-digit number",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }

    const newAssociate = {
      associateNumber: data.associateNumber,
      companyId: user.companyId,
      status: data.status,
      isAdmin: "false",
      designation: data.designation,
      type: data.type,
      workLocation: workLocation,
      cellPhone: data.cellPhone,
      gmailId: data.gmailId,
      workEmail: data.workEmail,
      dateOfJoining: dateOfJoining,
      dateOfBirth: dateOfBirth,
      lastName: data.lastName,
      firstName: data.firstName,
      associateManager: associateManager,
    };

    dispatch(createAssociate(newAssociate));
  }

  function handleEdit(data) {
    setIsSubmitting(true);

    const dateOfJoining = parseInt(
      moment(data.dateOfJoining).format("YYYYMMDD")
    );
    const dateOfBirth = parseInt(moment(data.dateOfBirth).format("YYYYMMDD"));

    let workLocation;
    if (!isNaN(parseFloat(data.workLocation)) && isFinite(data.workLocation)) {
      workLocation = parseInt(data.workLocation);
    } else {
      dispatch(
        setMessage({
          message: "Please Enter The Work Location",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }

    let associateManager;
    if (
      !isNaN(parseFloat(data.associateManager)) &&
      isFinite(data.associateManager)
    ) {
      associateManager = parseInt(data.associateManager);
    } else {
      dispatch(
        setMessage({
          message: "Please Select The Associate Manager",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@zyxantech\.com$/;
    if (!emailPattern.test(data.workEmail)) {
      dispatch(
        setMessage({
          message:
            "Invalid work email format. It should be in the form of zyxantech.com",
          status: "error",
        })
      );
      setIsSubmitting(false);
      return;
    }

    const updatedAssociate = {
      associateNumber: data.associateNumber,
      companyId: user.companyId,
      associateId: data.associateId,
      status: data.status,
      designation: data.designation,
      type: data.type,
      workLocation: workLocation,
      cellPhone: data.cellPhone,
      gmailId: data.gmailId,
      workEmail: data.workEmail,
      dateOfJoining: dateOfJoining,
      dateOfBirth: dateOfBirth,
      lastName: data.lastName,
      firstName: data.firstName,
      associateManager: associateManager,
    };

    if (data.hasOwnProperty("isAdmin")) {
      updatedAssociate.isAdmin = data.isAdmin;
    }

    dispatch(updateAssociate(updatedAssociate));
  }

  function handleDelete(id) {
    localStorage.setItem("id", id);
    setIsOpenConfirmation(true);
  }

  useEffect(() => {
    if (isCreateSuccess && createSuccessResponse) {
      dispatch(
        setMessage({
          message: createSuccessResponse.result[0],
          status: createSuccessResponse.status,
        })
      );
      dispatch(getAssociateList());
    }
    dispatch(reset());
    setIsSubmitting(false);
  }, [isCreateSuccess, createSuccessResponse]);

  useEffect(() => {
    if (isCreateFailure && createFailureResponse) {
      dispatch(
        setMessage({
          message: createFailureResponse.error,
          status: "error",
        })
      );
      dispatch(getAssociateList());
    }
    dispatch(reset());
  }, [isCreateFailure, createFailureResponse]);

  useEffect(() => {
    if (isUpdateSuccess && updateResponse) {
      dispatch(
        setMessage({
          message: updateResponse.result,
          status: updateResponse.status,
        })
      );
      dispatch(getAssociateList());
    }
    setIsSubmitting(false);
    dispatch(reset());
  }, [isUpdateSuccess, updateResponse]);
  useEffect(() => {
    if (isUpdateFailure && updateResponse) {
      dispatch(
        setMessage({
          message: updateResponse.error,
          status: "error",
        })
      );
      dispatch(getAssociateList());
    }
    setIsSubmitting(false);
    dispatch(reset());
  }, [isUpdateFailure, updateResponse]);

  useEffect(() => {
    if (isDeleteSuccess || isDeleteFailure) {
      dispatch(
        setMessage({
          message: deleteResponse.result,
          status: deleteResponse.status,
        })
      );
      dispatch(getAssociateList());
    }
  }, [isDeleteSuccess, deleteResponse, isDeleteFailure]);
  const deleteConfirmDialog = () => {
    return (
      <>
        <Dialog maxWidth="xs" open={isOpenConfirmation}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent dividers>
            You want to Inactive this Associate.
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleConfirmationNo}
              variant="outlined"
              color="error"
              size="small"
              startIcon={<CancelIcon />}
            >
              No
            </Button>
            <Button
              onClick={handleConfirmationYes}
              variant="outlined"
              color="success"
              size="small"
              startIcon={<DeleteIcon />}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const handleConfirmationNo = () => {
    setIsOpenConfirmation(false);
  };

  const handleConfirmationYes = () => {
    const id = localStorage.getItem("id");
    const associate = associateList.find(
      (associate) => associate.associateId === parseInt(id)
    );
    const status = "Inactive";
    const data = { ...associate, status };
    dispatch(deleteAssociate({ associateId: id, status }));
    setIsOpenConfirmation(false);
  };
  return (
    <>
      <Page title="Associate">
        {deleteConfirmDialog()}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        ></Stack>
        <Box
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              fontSize: 15,
              fontFamily: "Poppins, sans-serif",
            },
          }}
        >
          <DataTable
            columns={columns}
            rows={associateList}
            pageSize={13}
            title="Associate"
            checkboxSelection={false}
            isDeleteEnabled={false}
            isEditEnabled={true}
            isAddEnabled={true}
            columnVisibilityModel={columnVisibilityModel}
            addButtonText="Add Associate"
            handleEdit={(data) => {
              handleEdit(data);
            }}
            handleAdd={(data) => {
              handleAdd(data);
            }}
          />
        </Box>
      </Page>
      {isSubmitting && (
        <Loader
          open={isSubmitting}
          handleClose={() => setIsSubmitting(false)}
        />
      )}
    </>
  );
}
