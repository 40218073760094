import { Satellite } from "@material-ui/icons";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AddService } from "../../services/add.service";

const initialState = {
  isLoading: false,
  isError: false,
  error: null,
  isSuccess: false,
  success: null,
  isInsertProjectCategorySuccess: false,
  isInsertProjectCategoryFailure: false,
  ProjectCategoryResponse: null,
  isInsertWorkLocationsSuccess: false,
  isInsertWorkLocationsFailure: false,
  isGetCompanyListSuccess: false,
  isGetCompanyListFailure: false,
  getCompanyListResponse: null,
  workLocationsResponse: null,
  isDeleteWorkLocationSuccess: false,
  isDeleteWorkLocationFailure: false,
  deleteWorkLocationResponse: null,
  isDeleteProjectCategorySuccess: false,
  isDeleteProjectCategoryFailure: false,
  DeleteProjectCategoryResponse: null,
};







export const insertOrUpdateProjectCategory = createAsyncThunk(
  "add/insertOrUpdateProjectCategory",
  async (data, thunkAPI) => {
    const addService = new AddService();
    try {
      const response = await addService.insertOrUpdateProjectCategory(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const insertOrUpdateWorkLocation = createAsyncThunk(
  "add/insertOrUpdateWorkLocation",
  async (data, thunkAPI) => {
    console.log("Data::", data);
    const addService = new AddService();
    try {
      const response = await addService.insertOrUpdateWorkLocation(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getCompanyList =createAsyncThunk(
  "get/getCompanyList",
  async(data,thunkAPI) =>{
    const addService = new AddService();
    try{
      const response = await addService.getCompanyList(data);
      return response;
    }catch(error){
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteWorkLocation = createAsyncThunk(
  "add/deleteWorkLocation",
  async (data, thunkAPI) => {
    const addService = new AddService();
    try {
      const response = await addService.deleteWorkLocation(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const deleteProjectCategory = createAsyncThunk(
  "add/deleteProjectCategory",
  async (data, thunkAPI) => {
    const addService = new AddService();
    try {
      const response = await addService.deleteProjectCategory(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);




const addSlice = createSlice({
  name: "add",
  initialState: initialState,

  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.success = null;
      state.error = null;
      state.isInsertProjectCategorySuccess = false;
      state.isInsertProjectCategoryFailure = false;
      state.isInsertWorkLocationsSuccess = false;
      state.isInsertWorkLocationsFailure = false;
      state.ProjectCategoryResponse = null;
      state.workLocationsResponse = null;
      state.isGetCompanyListSuccess = false;
      state.isGetCompanyListFailure = false;
      state.getCompanyListResponse = null;
      state.isDeleteWorkLocationSuccess = false;
      state.isDeleteWorkLocationFailure = false;
      state.deleteWorkLocationResponse = null;
      state.isDeleteProjectCategorySuccess = false;
      state.isDeleteProjectCategoryFailure = false;
      state.DeleteProjectCategoryResponse = null;
      
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(insertOrUpdateProjectCategory.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(insertOrUpdateProjectCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isInsertProjectCategorySuccess = true;

        if (action.payload) {
          state.ProjectCategoryResponse = action.payload.data;
        }
      })

      .addCase(insertOrUpdateProjectCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isInsertProjectCategoryFailure = true;

        if (action.payload) {
          state.ProjectCategoryResponse = action.payload;
        } else {
          state.ProjectCategoryResponse = null;
        }
      })
      .addCase(insertOrUpdateWorkLocation.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(insertOrUpdateWorkLocation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isInsertWorkLocationsSuccess = true;

        if (action.payload) {
          state.workLocationsResponse = action.payload.data;
        }
      })

      .addCase(insertOrUpdateWorkLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isInsertWorkLocationsFailure = true;

        if (action.payload) {
          state.workLocationsResponse = action.payload;
        } else {
          state.workLocationsResponse = null;
        }
      })
      .addCase(getCompanyList.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getCompanyList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isGetCompanyListSuccess = true;

        if (action.payload) {
          state.getCompanyListResponse = action.payload.data.result;
        }
      })

      .addCase(getCompanyList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isGetCompanyListFailure = true;

        if (action.payload) {
          state.getCompanyListResponse = action.payload;
        }
      })

      .addCase(deleteWorkLocation.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteWorkLocation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isDeleteWorkLocationSuccess = true;
        if (action.payload) {
          state.deleteWorkLocationResponse = action.payload;
        }
      })
      .addCase(deleteWorkLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isDeleteWorkLocationFailure = true;
        if (action.payload) {
          state.deleteWorkLocationResponse = action.payload.response;
        }
      })

      builder.addCase(deleteProjectCategory.pending, (state) => {
       
        state.isLoading = true;
        state.isDeleteProjectCategorySuccess = false;
        state.isDeleteProjectCategoryFailure = false;
        state.DeleteProjectCategoryResponse = null;
      });

      builder.addCase(deleteProjectCategory.fulfilled, (state, action) => {
       
        state.isLoading = false;
        state.isDeleteProjectCategorySuccess = true;
        state.isDeleteProjectCategoryFailure = false;
        state.DeleteProjectCategoryResponse = action.payload;
       
      });

      builder.addCase(deleteProjectCategory.rejected, (state, action) => {
       
        state.isLoading = false;
        state.isDeleteProjectCategorySuccess = false;
        state.isDeleteProjectCategoryFailure = true;
        state.DeleteProjectCategoryResponse = action.payload;
       
      });

      
  },
});
export const { reset } = addSlice.actions;
export const addSelectors = (state) => state.add;
export default addSlice.reducer;
