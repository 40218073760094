import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { TimesheetService } from "../../services/timesheet.service";

const initialState = {
  isLoading: false,
  isError: false,
  error: null,
  isSuccess: false,
  success: null,
  isGetTimesheetDetailsSuccess: false,
  isGetTimesheetDetailsFailure: false,
  isGetCompanyDetailsSuccess: false,
  isGetCompanyDetailsFailure: false,
  isGetTimesheetListFailure: false,
  isGetTimesheetListSuccess: false,
  isGetCurrentWeekDaysSuccess: false,
  isGetCurrentWeekDaysFailure: false,
  isSubmitForReviewSuccess: false,
  isSubmitForReviewFailure: false,
  isUpdateSuccess: false,
  isUpdateFailure: false,
  isDeleteSuccess: false,
  isDeleteFailure: false,
  isCreateSuccess: false,
  isCreateFailure: false,
  timesheetResponse: null,
  createSuccessResponse: null,
  createFailureResponse: null,
  isSubmitForReviewSuccessResponse: null,
  isSubmitForReviewFailureResponse: null,
  updateResponse: null,
  deleteResponse: null,
};

export const createTimesheet = createAsyncThunk(
  "timesheet/createTimesheet",
  async (data, thunkAPI) => {
    const timesheetService = new TimesheetService();
    try {
      const response = await timesheetService.createTimesheet(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTimesheetDetails = createAsyncThunk(
  "timesheet/getTimesheetDetails",
  async (data, thunkAPI) => {
    const timesheetService = new TimesheetService();
    const timesheetId = data.timesheetId;
    try {
      const response = await timesheetService.getTimesheetDetails(timesheetId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCompanyDetails = createAsyncThunk(
  "company/getCompanyDetails",
  async (data, thunkAPI) => {
    const timesheetService = new TimesheetService();
    const companyId = data;
    try {
      const response = await timesheetService.getCompanyDetails(companyId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTimesheetList = createAsyncThunk(
  "timesheet/getTimesheetList",
  async ({ associateId, currentWeekNumber, currentYear }, thunkAPI) => {
    const timesheetService = new TimesheetService();
    try {
      const response = await timesheetService.getTimesheetList(
        associateId,
        currentWeekNumber,
        currentYear
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCurrentWeekDays = createAsyncThunk(
  "timesheet/getCurrentWeekDays",
  async (data, thunkAPI) => {
    const timesheetService = new TimesheetService();
    try {
      const response = await timesheetService.getCurrentWeekDays();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTimesheet = createAsyncThunk(
  "timesheet/updateTimesheet",
  async (data, thunkAPI) => {
    const timesheetService = new TimesheetService();
    try {
      const response = await timesheetService.updateTimesheet(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteTimesheet = createAsyncThunk(
  "timesheet/deleteTimesheet",
  async (data, thunkAPI) => {
    const timesheetService = new TimesheetService();
    try {
      const response = await timesheetService.deleteTimesheet(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const submitForReview = createAsyncThunk(
  "timesheet/submitForReview",
  async (data, thunkAPI) => {
    const timesheetService = new TimesheetService();
    try {
      const response = await timesheetService.submitForReview(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const timesheetSlice = createSlice({
  name: "timesheet",
  initialState: initialState,

  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.success = null;
      state.error = null;
      state.isGetTimesheetDetailsSuccess = false;
      state.isGetTimesheetDetailsFailure = false;
      state.isGetCompanyDetailsSuccess = false;
      state.isGetCompanyDetailsFailure = false;
      state.isGetTimesheetListSuccess = false;
      state.isGetTimesheetListFailure = false;
      state.isGetCurrentWeekDaysSuccess = false;
      state.isGetCurrentWeekDaysFailure = false;
      state.isSubmitForReviewSuccess = false;
      state.isSubmitForReviewFailure = false;
      state.isCreateFailure = false;
      state.isCreateSuccess = false;
      state.isUpdateSuccess = false;
      state.isUpdateFailure = false;
      state.isDeleteSuccess = false;
      state.isDeleteFailure = false;
      state.timesheetResponse = null;
      state.createSuccessResponse = null;
      state.createFailureResponse = null;
      state.updateResponse = null;
      state.deleteResponse = null;
      state.isSubmitForReviewSuccessResponse = null;
      state.isSubmitForReviewFailureResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTimesheet.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createTimesheet.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          const message = action.payload.data;
          if (message.error) {
            state.isCreateSuccess = false;
            state.isCreateFailure = true;
            state.createFailureResponse = action.payload.data;
          } else {
            state.isCreateSuccess = true;
            state.createSuccessResponse = action.payload.data;
          }
        }
      })
      .addCase(createTimesheet.rejected, (state, action) => {
        state.isLoading = false;
        state.isCreateFailure = true;

        if (action.payload) {
          state.isCreateFailure = action.payload;
        }
      })

      .addCase(submitForReview.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(submitForReview.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          const message = action.payload.data;
          if (message.error) {
            state.isSubmitForReviewSuccess = false;
            state.isCreateFailure = true;
            state.isSubmitForReviewFailureResponse = action.payload.data;
          } else {
            state.isSubmitForReviewSuccess = true;
            state.isSubmitForReviewSuccessResponse = action.payload.data;
          }
        }
      })
      .addCase(submitForReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isSubmitForReviewFailure = true;

        if (action.payload) {
          state.isSubmitForReviewFailure = action.payload;
        }
      })

      .addCase(getTimesheetDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTimesheetDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isGetTimesheetDetailsSuccess = true;

        if (action.payload) {
          state.timesheetResponse = action.payload;
        }
      })
      .addCase(getTimesheetDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isGetTimesheetDetailsFailure = true;
        if (action.payload) {
          state.timesheetResponse = action.payload;
        }
      })

      .addCase(getCompanyDetails.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getCompanyDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isGetCompanyDetailsSuccess = true;
        if (action.payload) {
          state.workLocations = action.payload.data.result[0].workLocations;
        }
      })

      .addCase(getCompanyDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isGetCompanyDetailsFailure = true;

        if (action.payload) {
          state.companyResponse = action.payload;
        }
      })

      .addCase(getTimesheetList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTimesheetList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isGetTimesheetListSuccess = true;
        if (action.payload) {
          state.timesheetResponse = action.payload;
        }
      })
      .addCase(getTimesheetList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isGetTimesheetListFailure = true;
        if (action.payload) {
          state.timesheetResponse = action.payload;
        }
      })

      .addCase(getCurrentWeekDays.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCurrentWeekDays.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isGetCurrentWeekDaysSuccess = true;
        if (action.payload) {
          state.timesheetResponse = action.payload.data;
        }
      })
      .addCase(getCurrentWeekDays.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isGetCurrentWeekDaysFailure = true;
        if (action.payload) {
          state.timesheetResponse = action.payload.data;
        }
      })

      .addCase(updateTimesheet.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateTimesheet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdateSuccess = true;
        state.isUpdateFailure = false;
        if (action.payload) {
          state.updateResponse = action.payload.data.result;
        }
      })
      .addCase(updateTimesheet.rejected, (state, action) => {
        state.isLoading = false;
        state.isUpdateSuccess = false;
        state.isUpdateFailure = true;
        if (action.payload) {
          state.updateResponse = action.payload.data.result;
        }
      })
      .addCase(deleteTimesheet.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteTimesheet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isDeleteSuccess = true;

        if (action.payload) {
          state.deleteResponse = action.payload;
        }
      })

      .addCase(deleteTimesheet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isDeleteFailure = true;

        if (action.payload) {
          state.deleteResponse = action.payload;
        }
      });
  },
});
export const { reset } = timesheetSlice.actions;
export const timesheetSelectors = (state) => state.timesheet;
export default timesheetSlice.reducer;
