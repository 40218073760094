// reportService.js

import { HttpClient } from "../utils/httpClient";

class ReportService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async generateReport(startDate, endDate, managerId, associates, projects) {
    try {
      const response = await this.httpClient.get(
        `/ui/generateReport?startDate=${startDate}&endDate=${endDate}&managerId=${managerId}&associates=${associates}&projects=${projects}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getTimesheetSubmitStatus(weekNum, yearVal, companyId) {
    try {
      const response = await this.httpClient.get(
        `/ui/reports/timesheetSubmitStatus?weekNum=${weekNum}&yearVal=${yearVal}&companyId=${companyId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export { ReportService };
