import { getDaysFromWeekNumber } from "./index";

import _ from "underscore";

const normalizeTimesheetData = (timesheetData) => {
  let formattedTimesheetData = [];
  if (timesheetData)
    timesheetData.map((taskData) => {
      let formattedTaskData = {};
      const { hours, ...otherFelids } = taskData;
      formattedTaskData = {
        ...formattedTaskData,
        ...otherFelids,
        id: otherFelids.taskId,
      };
      if (hours && _.isObject(hours)) {
        _.mapObject(hours, function (val, key) {
          let temp = { [key]: val };
          formattedTaskData = { ...formattedTaskData, ...temp };
        });
      }
      formattedTimesheetData = [...formattedTimesheetData, formattedTaskData];
      ;
    });
  return formattedTimesheetData;
};

export { normalizeTimesheetData };
