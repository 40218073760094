import { API_URL } from "../config/";
import axios from "axios";

class HttpClient {
  instance;
  constructor() {
    this.instance = axios.create({
      baseURL: API_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.getToken(),
      },
    });

    this.instance.interceptors.response.use(
      (response) => {
        const { data } = response;
        if (this.isErrorResponse(data)) {
          return Promise.reject(data);
        } else {
          return response;
        }
      },
      (error) => {
        // TODO: Add proper error handling
        return Promise.reject(extractError(error));
      }
    );
  }

  getToken() {
    const jwt = localStorage.getItem("jwt");

    if (jwt) {
      return `${JSON.parse(jwt)}`;
    }
    return "";
  }

  get = (url) => {
    return this.instance.get(url);
  };

  post = (url, data) => {
    return this.instance.post(url, data);
  };

  filePost = (url, data, headers) => {
    return this.instance.post(url, data, headers);
  };

  put = (url, data) => {
    return this.instance.put(url, data);
  };

  delete = (url) => {
    return this.instance.delete(url);
  };

  patch = (url, data) => {
    return this.instance.patch(url, data);
  };
  isErrorResponse = (responseData) => {
    if (responseData && responseData.hasOwnProperty("error")) {
      return true;
    }
    return false;
  };
}
const InternalError = {
  error: "Internal Error occurred during the request",
  status: "error",
  statusCode: 500,
};

const extractError = (errorObj) => {
  if (typeof errorObj !== "object" || !errorObj) {
    return InternalError;
  }

  const typedException = errorObj;
  if (
    // eslint-disable-next-line no-prototype-builtins
    errorObj.hasOwnProperty("error") &&
    typeof typedException.error === "string" &&
    // eslint-disable-next-line no-prototype-builtins
    errorObj &&
    errorObj["response"] &&
    errorObj["response"].status &&
    typeof errorObj["response"].status === "number"
  ) {
    return {
      message: errorObj["response"].data.error,
      status: "error",
      statusCode: errorObj["response"].status,
    };
  }

  return InternalError;
};

export { HttpClient };
