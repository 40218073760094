import { Box, styled, useTheme } from '@mui/material';

import React from 'react';

const GridStyledWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(2, 1),
  justifyContent: 'center',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  "& .MuiDataGrid-cell--editing": {
    backgroundColor: "rgb(255,215,115, 0.19)",
    color: "#1a3e72",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    border: "2px solid #f44336",
    borderRadius:"5px"
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#164037",
    color: "#f9b500",
    fontSize: 15,
    
  },
}));

const GridWrapper = ({ children, gridHeight }) => {
  const { palette } = useTheme();
  return (
    <GridStyledWrapper>
      <StyledBox>
        <div
          className={`${
            palette.mode === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'
          }`}
          style={{
            width: '100%',
            height: gridHeight || '450',
          }}
        >
          {children}
        </div>
      </StyledBox>
    </GridStyledWrapper>
  );
};

export default GridWrapper;
