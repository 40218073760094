import "./App.css";

import React, { useEffect, useState } from "react";

import Notification from "../src/common/Alerts/Notifications";
import Router from "./router/routes";
import { notificationSelector } from "../src/features/notification/notificationSlice";
import { useSelector } from "react-redux";

// import { getDefaultMiddleware } from '@reduxjs/toolkit';
function App() {
  // const customizedMiddleware = getDefaultMiddleware({
  //   serializableCheck: false
  // })
  const [notificationDetails, setNotificationDetails] = useState(null);
  const { show, status, message } = useSelector(notificationSelector);

  useEffect(() => {
    if (show && status) {
      setNotificationDetails({ show, status, message });
    } else {
      setNotificationDetails(null);
    }
  }, [show, status, message]);

  return (
    <div className="App">
      {notificationDetails && (
        <Notification
          type={notificationDetails.status}
          message={notificationDetails.message}
          duration={5000}
        />
      )}
      <Router />
    </div>
  );
}
//   return (
//     <div className="App">
//       <Router />
//     </div>

//   );
// }

export default App;
