import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  getCurrentWeekNumber,
  getCurrentYear,
  getDaysFromWeekNumber,
} from "../../utils/index";
import {
  getTimesheetSubmitStatus,
  reportSelector,
  reset,
} from "../../features/reports/ReportSlice";
import { useDispatch, useSelector } from "react-redux";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DataTable from "../../common/DataTable/DataTable";
import format from "date-fns/format";
import { useEffect } from "react";

const StatusPage = () => {
  const dispatch = useDispatch();
  const {
    isGetTimesheetSubmitStatusSuccess,
    isGetTimesheetSubmitStatusFailure,
    TimesheetSubmitStatusResponse,
  } = useSelector(reportSelector);
  const { companyId, associateManager } =
    JSON.parse(localStorage.getItem("user")) || {};
  const [currentWeekNumber, setCurrentWeekNumber] = useState(
    getCurrentWeekNumber()
  );
  const user = JSON.parse(localStorage.getItem("user"));
  const [statusList, setStatusList] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    // taskId: false,
    associateNumber: false,
    associateId: false,
  });
  const getStatus = (weekNumber, currentYear) => {
    const weekDates = getDaysFromWeekNumber(weekNumber, currentYear);

    dispatch(
      getTimesheetSubmitStatus({
        weekNum: weekNumber,
        yearVal: currentYear,
        companyId: user.companyId,
      })
    );
  };

  const handlePreviousWeek = () => {
    setCurrentWeekNumber(currentWeekNumber - 1);
  };

  const handleNextWeek = () => {
    const currentDate = new Date();
    const nextWeekStartDate = getNextMonday(
      currentWeekNumber + 1,
      getCurrentYear()
    );
    if (nextWeekStartDate > currentDate) {
      console.log("Cannot move to future weeks");
      return;
    }
    setCurrentWeekNumber(currentWeekNumber + 1);
  };

  const columns = [
    {
      field: "id",
      headerName: "Id",
      flex: 1,
      editable: false,
    },
    {
      field: "associateNumber",
      headerName: " associateNumber",
      width: 100,
      editable: false,
    },
    {
      field: "associateId",
      headerName: "associateId",
      editable: false,
      width: 200,
    },
    {
      field: "firstName",
      headerName: " First Name",
      width: 300,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      editable: false,
      width: 300,
    },

    {
      field: "weekNum",
      headerName: "WeekNumber",
      //    flex: 1,
      editable: false,
      width: 200,
    },
    {
      field: "yearVal",
      headerName: " YearValue",
      editable: false,
      width: 200,
      type: "date",
    },
    {
      field: "timesheetSubmitStatus",
      headerName: " TimesheetSubmitStatus",
      editable: false,
      width: 400,
    },
  ];
  function getNextMonday(weekNumber, year) {
    const date = new Date();
    date.setFullYear(year, 0, 1);
    date.setDate(date.getDate() + (weekNumber - 1) * 7 - date.getDay());

    // Find the next Monday
    while (date.getDay() !== 1) {
      date.setDate(date.getDate() + 1);
    }

    return date;
  }

  function getNextSaturday(weekNumber, year) {
    const startDate = getNextMonday(weekNumber, year);
    const endDate = new Date(startDate);

    // Add 5 days to get to Saturday
    endDate.setDate(endDate.getDate() + 5);

    return endDate;
  }

  useEffect(() => {
    const currentWeekNumber = getCurrentWeekNumber();
    const currentYear = getCurrentYear();
    dispatch(
      getTimesheetSubmitStatus({
        weekNum: currentWeekNumber,
        yearVal: currentYear,
        companyId: user.companyId,
      })
    );
  }, []);

  useEffect(() => {
    if (isGetTimesheetSubmitStatusSuccess) {
      const newArray = TimesheetSubmitStatusResponse.result.map((v) => {
        return {
          ...v,
          id: v.associateId,
        };
      });

      setStatusList(newArray);
    }
  }, [isGetTimesheetSubmitStatusSuccess, TimesheetSubmitStatusResponse]);
  useEffect(() => {
    getStatus(currentWeekNumber, getCurrentYear());
  }, [currentWeekNumber]);

  return (
    <>
      <div className="page-header">
        <div className="page-title">
          <Typography variant="h6">Time sheet status</Typography>
        </div>
        <div className="centered-content">
          <Tooltip title="Previous Week">
            <IconButton className="icon-button" onClick={handlePreviousWeek}>
              <ArrowBackIosNewIcon style={{ color: "#164037" }} />
            </IconButton>
          </Tooltip>
          <div className="week-info">
            <div className="startWeek">
              <div>Start Date </div>
              <div>
                {format(
                  getNextMonday(currentWeekNumber, getCurrentYear()),
                  "dd/MMM/yyyy"
                )}{" "}
              </div>
            </div>
            <div className="endWeek">
              <div>End Date </div>
              {format(
                getNextSaturday(currentWeekNumber, getCurrentYear()),
                "dd/MMM/yyyy"
              )}
            </div>
          </div>

          <Tooltip title="Next Week">
            <IconButton className="icon-button" onClick={handleNextWeek}>
              <ArrowForwardIosIcon style={{ color: "#164037" }} />
            </IconButton>
          </Tooltip>
        </div>
        <div className="add-task-button">
          <div className="btn"></div>
        </div>
      </div>
      <Box
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            fontSize: 15,
            fontFamily: "Poppins, sans-serif",
          },
        }}
      >
        <div>
          <DataTable
            columns={columns}
            rows={statusList}
            pageSize={13}
            checkboxSelection={false}
            isDeleteEnabled={false}
            isEditEnabled={false}
            isExtendTaskEnable={false}
            isAddEnabled={false}
            columnVisibilityModel={columnVisibilityModel}
          ></DataTable>
        </div>
      </Box>
    </>
  );
};

export default StatusPage;
