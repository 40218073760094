const formateDate = (date) => {
  return new Date(
    date.substring(0, 4),
    parseInt(date.substring(4, 6)) - 1,
    date.substring(6),
    5,
    30,
    0
  );
};

const getCurrentWeekNumber = ()=>{
  const currentDate = new Date();
  const startDate = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
  const week = Math.ceil(days / 7);
  return week;
}

const getCurrentYear = () => {
  const currentDate = new Date();
  return currentDate.getFullYear();
};

export { formateDate, getCurrentWeekNumber, getCurrentYear };
