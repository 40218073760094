import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AuthService } from "../../services/auth.service";

const storedUser = localStorage.getItem("user");
const user = storedUser ? JSON.parse(storedUser) : null;
const storedJwt = localStorage.getItem("jwt");
const jwt = storedJwt ? JSON.parse(storedJwt) : null;
let isAuthenticated = false;

if (jwt && user) {
  isAuthenticated = true;
}

const intialState = {
  user: user,
  isLoading: false,
  isError: false,
  error: null,
  isSuccess: false,
  success: null,
  isAuthenticated: isAuthenticated,
  isloginSuccess: false,
  isloginFailure: false,
  loginResponse: {},
  isSignupSuccess: false,
  isSignupFailure: false,
  SignupResponse: {},
  isForgotPasswordSuccess: false,
  isForgotPasswordFailure: false,
  forgotPasswordResponse: null,
  isResetPasswordSuccess: false,
  isResetPasswordFailure: false,
  resetPasswordResponse: null,
  isChangePasswordSuccess: false,
  isChangePasswordFailure: false,
  changePasswordResponse: null,
};

/**
 * Write your Async reducers here and add builders for them to the extraReducers object
 */
export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  try {
    const authService = new AuthService();
    const response = await authService.login(user);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const logout = createAsyncThunk(
  "auth/logout",
  async (user, thunkAPI) => {
    const authService = new AuthService();
    try {
      const response = await authService.logout();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const signup = createAsyncThunk(
  "auth/signup",

  async (user, thunkAPI) => {
    const authService = new AuthService();
    try {
      const responce = await authService.Signup(user);
      return responce;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (data, thunkAPI) => {
    const authService = new AuthService();
    const email = data;
    const dob = data;
    try {
      const response = await authService.forgotPassword(email, dob);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data, thunkAPI) => {
    const authService = new AuthService();
    try {
      const { password, confirmPassword, otp, token } = data;
      const response = await authService.resetPassword(
        password,
        confirmPassword,
        otp,
        token
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const changePassword = createAsyncThunk(
  "auth/ changePassword",

  async (data, thunkAPI) => {
    const authService = new AuthService();
    try {
      const response = await authService.changePassword(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: intialState,
  reducers: {
    reset: (state) => {
      state.user = null;
      state.isLoading = false;
      state.isError = false;
      state.error = null;
      state.isSuccess = false;
      state.success = null;
      state.isAuthenticated = false;
      state.isloginSuccess = false;
      state.isloginFailure = false;
      state.loginResponse = "";
      state.isSignupSuccess = false;
      state.isSignupFailure = false;
      state.SignupResponse = "";
      state.isForgotPasswordSuccess = false;
      state.isForgotPasswordFailure = false;
      state.forgotPasswordResponse = null;
      state.isResetPasswordSuccess = false;
      state.isResetPasswordFailure = false;
      state.resetPasswordResponse = null;
      state.isChangePasswordSuccess = false;
      state.isChangePasswordFailure = false;
      state.changePasswordResponse = null;
    },
  },
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(login.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isloginSuccess = true;
        // state.success = action.payload;
        state.loginResponse = action.payload.data;
        if (action.payload && action.payload.user) {
          state.user = action.payload.user;

          state.isAuthenticated = true;
        }
        state.isAuthenticated = true;
      })

      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isloginFailure = true;
        state.isError = true;

        if (action.payload) {
          state.loginResponse = action.payload;
        }
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.jwt = null;
        state.isAuthenticated = false;
      })
      .addCase(signup.pending, (state, action) => {
        state.isSignupSuccess = false;
        state.isSignupFailure = false;
      })

      .addCase(signup.fulfilled, (state, action) => {
        state.isSignupSuccess = true;
        state.SignupResponse = action.payload.data;
      })
      .addCase(signup.rejected, (state, action) => {
        state.isLoading = false;
        state.isSignupFailure = true;
        state.isSignupSuccess = false;
        state.error = action.payload ? action.payload.message : "Signup failed";
      })
      .addCase(forgotPassword.pending, (state, action) => {
        state.isForgotPasswordSuccess = false;
      })

      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isForgotPasswordSuccess = true;
        state.forgotPasswordResponse = action.payload.data;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isForgotPasswordFailure = true;
        state.isForgotPasswordSuccess = false;
        state.isError = true;

        if (action.payload) {
          state.forgotPasswordResponse = action.payload;
        }
      })
      .addCase(resetPassword.pending, (state, action) => {
        state.isResetPasswordSuccess = false;
      })

      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isResetPasswordSuccess = true;
        state.resetPasswordResponse = action.payload.data;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isResetPasswordSuccess = false;
        state.isResetPasswordFailure = true;

        if (action.payload.status === 400)
          state.resetPasswordResponse = action.payload;
        else if (action.payload)
          state.resetPasswordResponse = action.payload.response.data;
      })
      .addCase(changePassword.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isChangePasswordSuccess = true;
        state.isChangePasswordFailure = false;
        if (action.payload) {
          state.changePasswordResponse = action.payload.data;
        }
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isChangePasswordSuccess = false;
        state.isChangePasswordFailure = true;
        if (action.payload) {
          state.changePasswordResponse = action.payload;
        }
      });
  },
});

export const { reset } = authSlice.actions;

export const authSelector = (state) => state.auth;

export default authSlice.reducer;
