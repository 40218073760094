import { HttpClient } from "../utils/httpClient";
import jwtDecode from "jwt-decode";

class todoService {
  constructor() {
    this.httpClient = new HttpClient();
  }
  async getTaskExtendStatus(taskId) {
    try {
      const response = await this.httpClient.get(`/ui/taskextstatus/:taskId`);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getList() {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const companyId = user.companyId;
      const response = await this.httpClient.get(
        `/ui/query/todos?companyId=${companyId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getAssociateLeaveList(data) {
    console.log(
      "🚀 ~ file: todo.service.js:47 ~ todoService ~ getAssociateLeaveList ~ data:",
      data
    );
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const {
        fromDate,
        toDate,
        actionType,
        assignedTo,
        companyId,
        associateId,
      } = data;
      const response = await this.httpClient.post(
        "/ui/query/todos/leavesHistory",
        {
          fromDate,
          toDate,
          actionType,
          assignedTo,
          companyId,
          associateId,
        }
      );
      console.log("Response:", response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getDetails(todoId) {
    try {
      const response = await this.httpClient.get(`/ui/todos/:todoId${todoId}/`);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async createTodo(todo) {
    try {
      const { todoId, actionType, associateId, date } = todo;
      const response = await this.httpClient.post("/ui/todo", {
        todoId: todoId,
        actionType: actionType,
        associateId: associateId,
        date: date,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async extendTask(task) {
    try {
      const { taskId, projectId, extendEndDate, reasonForExtend, associateId } =
        task;
      const user = JSON.parse(localStorage.getItem("user"));
      const response = await this.httpClient.post("/ui/todo/extendtask", {
        taskId: taskId,
        projectId: projectId,
        extendEndDate: extendEndDate,
        reasonForExtend: reasonForExtend,
        associateId: associateId,
        companyId: user.companyId,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateTodo(data) {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const { todoId, status, actionType, comment } = data;
      const response = await this.httpClient.put("/ui/todo", {
        todoId: todoId,
        status: status,
        actionType: actionType,
        companyId: user.companyId,
        comment: comment,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getApprovalStatus(data) {
    try {
      const response = await this.httpClient.get(
        `/ui/approvalStatus/${data.todoId}/${data.todoStatus}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getTaskByTodo(todoId) {
    try {
      const response = await this.httpClient.get(`/ui/taskData/${todoId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
}
export { todoService };
