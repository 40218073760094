import { HttpClient } from "../utils/httpClient";

export class HolidaysService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async createHolidays(holidays) {
    try {
      const response = await this.httpClient.post(`/ui/holidays/`, holidays);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getHolidaysDetails(holidayId) {
    try {
      const response = await this.httpClient.get(`/ui/holidays/${holidayId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getHolidaysList() {
    try {
      const response = await this.httpClient.get(`/ui/query/holidays`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateHolidays(holidays) {
    try {
      const response = await this.httpClient.put(`/ui/holidays`, holidays);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async deleteHolidays(holidayId) {
    try {
      const response = await this.httpClient.delete(
        `/ui/holidays/${holidayId}`
      );

      return response;
    } catch (error) {
      throw error;
    }
  }
}
// export {HolidaysService};
