import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { taskService } from "../../services/task.service";

const intialState = {
  user: null,
  isLoading: false,
  isError: false,
  error: null,
  isSuccess: false,
  success: null,
  isGetTaskDetailsSuccess: false,
  isGetTaskDetailsFailure: false,
  isCreateTaskSuccess: false,
  isCreateTaskFailure: false,
  isGetTaskListSuccess: false,
  isGetTaskListFailure: false,
  isUpdateTaskSuccess: false,
  isUpdateTaskFailure: false,
  isDeleteSuccess: false,
  isDeleteFailure: false,
  createResponse: null,
  getResponse: null,
  updateResponse: null,
  deleteResponse: null,
  taskResponse: null,
  createSuccessResponse: null,
  createFailureResponse: null,
  taskRejectResponse: null,
  isGetCompanyDetailsSuccess: false,
  isGetCompanyDetailsFailure: false,
  TaskCategory: null,
  isGetCurrentWeekTasksSuccess: false,
  isGetCurrentWeekTaskFailure: false,
  getCurrentWeekTasksResponse: null,
};
export const getTaskDetails = createAsyncThunk(
  "task/getTaskDetails",
  async (data, thunkAPI) => {
    const TaskService = new taskService();
    try {
      const response = await TaskService.getTaskDetails(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createTask = createAsyncThunk(
  "task/createTask",

  async (task, thunkAPI) => {
    const TaskService = new taskService();
    try {
      const response = await TaskService.createTask(task);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// export const getTaskList = createAsyncThunk(
//   "task/getTaskList",
//   async (assignedTo, thunkAPI, startDate, endDate) => {
//     const TaskService = new taskService();
//     try {
//       const response = await TaskService.getTaskList(
//         assignedTo,
//         startDate,
//         endDate
//       );
//       return response;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );
export const getTaskList = createAsyncThunk(
  "task/getTaskList",
  async ({ assignedTo, startDate, endDate }, thunkAPI) => {
    const TaskService = new taskService();
    try {
      const response = await TaskService.getTaskList(
        assignedTo,
        startDate,
        endDate
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getCurrentWeekTasks = createAsyncThunk(
  "task/getCurrentWeekTasks",
  async ({ associateId, startDate, endDate }, thunkAPI) => {
    const TaskService = new taskService();
    try {
      const response = await TaskService.getCurrentWeekTasks(
        associateId,
        startDate,
        endDate
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateTask = createAsyncThunk(
  "task/updateTask",

  async (data, thunkAPI) => {
    const TaskService = new taskService();
    const taskId = data.taskId;
    try {
      const response = await TaskService.updateTask(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteTask = createAsyncThunk(
  "task/deleteTask",
  async (data, thunkAPI) => {
    const TaskService = new taskService();
    try {
      const response = await TaskService.deleteTask(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getCompanyDetails = createAsyncThunk(
  "company/getCompanyDetails",
  async (data, thunkAPI) => {
    const TaskService = new taskService();
    const companyId = data;
    try {
      const response = await TaskService.getCompanyDetails(companyId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const taskSlice = createSlice({
  name: "task",
  initialState: intialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.error = null;
      state.isSuccess = false;
      state.success = null;
      state.isGetTaskDetailsSuccess = false;
      state.isGetTaskDetailsFailure = false;
      state.getResponse = null;
      state.taskResponse = null;
      state.isCreateTaskSuccess = false;
      state.isCreateTaskFailure = false;
      state.createResponse = null;
      state.isUpdateTaskSuccess = false;
      state.isUpdateTaskFailure = false;
      state.updateResponse = null;
      state.isGetTaskListFailure = false;
      state.isGetTaskListSuccess = false;
      state.isGetCurrentWeekTasksSuccess = false;
      state.isGetCurrentWeekTaskFailure = false;
      state.getCurrentWeekTasksResponse = null;
      state.isDeleteSuccess = false;
      state.isDeleteFailure = false;
      state.deleteResponse = null;
      state.isGetProjectsListSuccess = false;
      state.isGetProjectsListFailure = false;
      state.projectsResponse = null;
      state.isGetCompanyDetailsSuccess = false;
      state.isGetCompanyDetailsFailure = false;
      state.TaskCategory = null;
    },
  },

  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getTaskDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTaskDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isGetTaskDetailsSuccess = true;
        if (action.payload) {
          state.getResponse = action.payload.data;
        }
        state.isAuthenticated = true;
      })
      .addCase(getTaskDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isGetTaskDetailsFailure = true;
        if (action.payload) {
          state.getResponse = action.payload.data;
        }
      })
      .addCase(createTask.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createTask.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreateTaskSuccess = true;
        if (action.payload) {
          state.createResponse = action.payload.data;
        }
      })
      .addCase(createTask.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isCreateTaskFailure = true;
        if (action.payload) {
          state.createResponse = action.payload.response.data;
        }
      })
      .addCase(getTaskList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTaskList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isGetTaskListSuccess = true;
        if (action.payload) {
          state.taskResponse = action.payload.data.result;
        }
      })

      .addCase(getTaskList.rejected, (state, action) => {
        state.isLoading = false;
        state.isGetTaskListFailure = true;
        if (action.payload) {
          state.taskResponse = action.payload.response.data;
        }
      })
      .addCase(updateTask.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdateTaskSuccess = true;
        if (action.payload) {
          state.updateResponse = action.payload.data;
        }
      })
      .addCase(updateTask.rejected, (state, action) => {
        state.isLoading = false;
        state.isUpdateTaskFailure = true;
        if (action.payload && action.payload) {
          state.updateResponse = action.payload;
        } else {
          state.updateResponse = null;
        }
      })
      .addCase(deleteTask.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isDeleteSuccess = true;
        if (action.payload) {
          state.deleteResponse = action.payload.data;
        }
      })
      .addCase(deleteTask.rejected, (state, action) => {
        state.isLoading = false;
        state.isDeleteFailure = true;
        if (action.payload) {
          state.deleteResponse = action.payload.response.data;
        }
      })
      .addCase(getCompanyDetails.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getCompanyDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isGetCompanyDetailsSuccess = true;
        if (action.payload) {
          state.TaskCategory = action.payload.data.result[0].TaskCategory;
        }
      })

      .addCase(getCompanyDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isGetCompanyDetailsFailure = true;

        if (action.payload) {
          state.companyResponse = action.payload;
        }
      })
      .addCase(getCurrentWeekTasks.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCurrentWeekTasks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isGetCurrentWeekTasksSuccess = true;
        if (action.payload && action.payload.data.status === "Success") {
          state.getCurrentWeekTasksResponse = action.payload.data.result;
        }
      })

      .addCase(getCurrentWeekTasks.rejected, (state, action) => {
        state.isLoading = false;
        state.isGetCurrentWeekTaskFailure = true;
        if (action.payload) {
          state.getCurrentWeekTasksResponse = action.payload;
        }
      });
  },
});
export const { reset } = taskSlice.actions;

export const taskSelector = (state) => state.task;

export default taskSlice.reducer;
