import "./Login.css";

import * as React from "react";
import * as Yup from "yup";

import { IconButton, InputAdornment, Paper, TextField } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { authSelector, login, reset } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Loader from "../../common/Loader/Loader";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { setMessage } from "../../features/notification/notificationSlice";
import { useFormik } from "formik";

const theme = createTheme();

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isloginSuccess, isloginFailure, loginResponse } =
    useSelector(authSelector);
  const [Loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClick = (data) => {
    const { email, password } = data;

    setIsSubmitting(true);
    dispatch(login({ email, Password: password }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    if (
      isloginSuccess &&
      loginResponse &&
      loginResponse.data &&
      loginResponse.data.result &&
      loginResponse.data.result.length > 0
    ) {
      setIsSubmitting(false);
      dispatch(
        setMessage({
          message: "Login successfully",
          status: "Success",
        })
      );
      navigate("/reset-password");
      dispatch(reset());
    }
  }, [isloginSuccess, loginResponse]);

  useEffect(() => {
    if (isloginFailure && loginResponse) {
      setIsSubmitting(false);
      dispatch(
        setMessage({
          message: loginResponse.error,
          status: "error",
        })
      );
    }
  }, [isloginFailure, loginResponse]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid Email address")
      .required("Email is Required"),
    password: Yup.string()
      .min(6, "Password Should be Minimum 6 characters")
      .required("Password is Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (data) => handleClick(data),
    validationSchema: LoginSchema,
  });

  return (
    <ThemeProvider theme={theme}>
      {/* <CssBaseline /> */}

      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Paper elevation={3} sx={{ padding: 3, maxWidth: 500 }}>
          <div style={{}}>
            <img
              src="./assets/Zyxan log3-01.png"
              alt=""
              style={{ width: "95px", height: "95px" }}
            />
          </div>
          <div>
            <Typography
              component="h1"
              variant="h5"
              style={{ marginLeft: "10rem" }}
            >
              Zyxan EDM.
            </Typography>
          </div>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              variant="outlined"
              value={formik.values.email}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{ mt: 3 }}
            />

            <TextField
              margin="normal"
              variant="outlined"
              value={formik.values.password}
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Visibility style={{ color: "inherit" }} />
                      ) : (
                        <VisibilityOff style={{ color: "inherit" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 1 }}
            />

            <Stack>
              <LoadingButton
                onClick={formik.handleSubmit}
                loading={Loading}
                fullWidth
                variant="contained"
                sx={{ mt: 5, mb: 2 }}
                type="submit"
              >
                LogIn
              </LoadingButton>
            </Stack>

            <Grid container>
              <Grid item xs>
                <Link
                  href="#"
                  variant="body2"
                  to="/forgot-password"
                  component={RouterLink}
                >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="#"
                  variant="body2"
                  to="/register"
                  component={RouterLink}
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
      {isSubmitting && (
        <Loader
          open={isSubmitting}
          handleClose={() => setIsSubmitting(false)}
        />
      )}
    </ThemeProvider>
  );
}
