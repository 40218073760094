import "./Todo.css";

import * as React from "react";

import { Cancel, Done } from "@material-ui/icons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  associateSelectors,
  getAssociateList,
} from "../../features/associate/associateSlice";
import {
  getApprovalStatus,
  getDetails,
  getList,
  getTaskByTodo,
  getTaskExtendStatus,
  reset,
  todoSelector,
  updateTodo,
} from "../../features/todo/todoSlice";
import {
  getCurrentWeekDays,
  getCurrentWeekNumber,
  getCurrentYear,
  getDaysFromWeekNumber,
  getWeekDaysByWeekNumber,
} from "../../utils/index";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import DataTable from "../../common/DataTable/DataTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import Loader from "../../common/Loader/Loader";
import Page from "../../page/page";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment/moment";
import { setMessage } from "../../features/notification/notificationSlice";

export default function Todo() {
  const {
    isGetListSuccess,
    isUpdateTodoSuccess,
    isUpdateTodoFailure,
    updateResponse,
    getListResponse,
    isGetTaskByTodoSuccess,
    getTaskByTodoResponse,
  } = useSelector(todoSelector);
  const {
    isGetAssociateListSuccess,
    isGetAssociateListFailure,
    associateResponse,
  } = useSelector(associateSelectors);
  const dispatch = useDispatch();
  const [GetList, setGetList] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    dueDate: false,
    status: false,
    id: false,
  });
  const [associateList, setAssociateList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [openCommentPopup, setOpenCommentPopup] = useState(false);
  const [openApproveCommentPopup, setApproveCommentPopup] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [selectedTaskDetails, setSelectedTaskDetails] = useState([]);
  const [openTaskDetailsDialog, setOpenTaskDetailsDialog] = useState(false);
  const [datesOfWeek, setDatesOfWeek] = useState(
    getDaysFromWeekNumber(getCurrentWeekNumber(), getCurrentYear())
  );
  const [daysOfWeek, setDaysOfWeek] = useState(getCurrentWeekDays());
  const [selectedTodo, setSelectedTodo] = useState(null);
  const handleCloseTaskDetails = () => {
    setOpenTaskDetailsDialog(false);
  };
  const handleViewTaskDetails = (todoData) => {
    setSelectedTodo(todoData);
    let weekDetails = getDaysFromWeekNumber(
      todoData.submittedFor.weekNum,
      todoData.submittedFor.year
    );
    setDatesOfWeek(weekDetails);
    let weekDays = getWeekDaysByWeekNumber(todoData.submittedFor.weekNum);
    setDaysOfWeek(weekDays);
    setIsSubmitting(true);
    dispatch(getTaskByTodo(todoData.todoId))
      .then(() => {
        setIsSubmitting(false);
        setOpenTaskDetailsDialog(true);
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.error("Error in fetching task details:", error);
      });
  };

  const [Snackbar, setSnackbar] = useState([]);

  const standardsHoursPerDayValidator = (params) => {
    if (params.props.value > 24) {
      params.props.error = true;
      setSnackbar({
        children: "Hours cannot be greater than 24",
        severity: "error",
      });
      return params.props;
    } else {
      params.props.error = false;
      return params.props;
    }
  };

  const hoursRender = (params) => {
    const { field, row } = params;
    if (row && field) {
      for (let i = 0; i < row.timeSheetData.length; i++) {
        if (row.timeSheetData[i].dateTimesheet === parseInt(field)) {
          return row.timeSheetData[i].hours;
        }

        if (i + 1 === row.timeSheetData.length) {
          return 0;
        }
      }
    } else {
      return 0;
    }
  };
  const hoursValueSetter = (params) => {
    const { field } = params;
    let hoursValue = {
      workingHours: 0,
      isReviewed: "",
      performanceAppraisal: 0,
    };

    let tempObj = {
      [field]: hoursValue,
    };
    return hoursValue;
  };

  const taskColumns = [
    {
      field: "id",
      headerName: "Id",
      flex: 1,
      editable: false,
    },
    {
      field: "projectName",
      headerName: "Project Title",
      editable: true,
      width: 200,
    },
    {
      field: "title",
      headerName: "Task Title",
      editable: true,
      width: 200,
    },
    {
      field: "description",
      headerName: " Task Description",
      width: 300,
      editable: true,
    },
    {
      field: "priority",
      headerName: " Priority",
      editable: true,
      width: 150,
    },
    {
      field: "taskCategory",
      headerName: " TaskCategory",
      editable: true,
      width: 150,
    },
    {
      field: "startDate",
      headerName: " StartDate",
      editable: true,
      width: 150,
      valueFormatter: (params) => {
        const dateValue = params?.value;
        if (!dateValue) return "";
        const formattedDate = moment(dateValue.toString(), "YYYYMMDD").format(
          "DD/MM/YYYY"
        );
        return formattedDate;
      },
    },
    {
      field: "endDate",
      headerName: " EndDate",
      editable: true,
      width: 150,
      valueFormatter: (params) => {
        const dateValue = params?.value;
        if (!dateValue) return "";
        const formattedDate = moment(dateValue.toString(), "YYYYMMDD").format(
          "DD/MM/YYYY"
        );
        return formattedDate;
      },
    },
    {
      field: `${datesOfWeek[1]}`,
      headerName: daysOfWeek[1].day,
      minWidth: 120,
      flex: 1,
      editable: true,
      type: "number",
      preProcessEditCellProps: standardsHoursPerDayValidator,
      valueGetter: hoursRender,
      valueSetter: hoursValueSetter,
    },
    {
      field: `${datesOfWeek[2]}`,
      headerName: daysOfWeek[2].day,
      minWidth: 120,
      flex: 1,
      editable: true,
      type: "number",
      preProcessEditCellProps: standardsHoursPerDayValidator,
      valueGetter: hoursRender,
      valueSetter: hoursValueSetter,
    },
    {
      field: `${datesOfWeek[3]}`,
      headerName: daysOfWeek[3].day,
      minWidth: 120,
      flex: 1,
      editable: true,
      type: "number",
      preProcessEditCellProps: standardsHoursPerDayValidator,
      valueGetter: hoursRender,
      valueSetter: hoursValueSetter,
    },
    {
      field: `${datesOfWeek[4]}`,
      headerName: daysOfWeek[4].day,
      minWidth: 120,
      flex: 1,
      editable: true,
      type: "number",
      preProcessEditCellProps: standardsHoursPerDayValidator,
      valueGetter: hoursRender,
      valueSetter: hoursValueSetter,
    },
    {
      field: `${datesOfWeek[5]}`,
      headerName: daysOfWeek[5].day,
      minWidth: 120,
      flex: 1,
      editable: true,
      type: "number",
      preProcessEditCellProps: standardsHoursPerDayValidator,
      valueGetter: hoursRender,
      valueSetter: hoursValueSetter,
    },
    {
      field: `${datesOfWeek[6]}`,
      headerName: daysOfWeek[6].day,
      minWidth: 120,
      flex: 1,
      editable: true,
      type: "number",
      preProcessEditCellProps: standardsHoursPerDayValidator,
      valueGetter: hoursRender,
      valueSetter: hoursValueSetter,
    },
  ];
  const columns = [
    {
      field: "actionType",
      headerName: "ActionType",
      minWidth: 150,
      maxWidth: 200,

      editable: true,
    },
    {
      field: "associateId",
      headerName: "from",
      minWidth: 150,
      maxWidth: 200,
      editable: true,
      valueGetter: (params) => {
        const associate = associateList.find(
          (associate) => associate.value === params.row?.associateId.value
        );

        return params.row?.associateId;
      },
    },

    {
      field: "date",
      headerName: "Date",
      // flex: 1,
      minWidth: 150,
      maxWidth: 200,
      editable: true,
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "dueDate",
      headerName: "DueDate",
      minWidth: 150,
      maxWidth: 200,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      // flex: 1,
      minWidth: 150,
      maxWidth: 200,
      editable: true,
    },
    {
      field: "description",
      headerName: "Description",
      width: 560,
      editable: true,
      autoHeight: true,
    },
    {
      field: "Reject",
      headerName: "Actions",
      minWidth: 300,
      renderCell: (params) => {
        const { id, actionType } = params.row;
        if (
          actionType === "Leave Request" ||
          actionType === "Extend Task" ||
          actionType === "Leave Update" ||
          actionType === "Leave Cancelation"
        ) {
          return [
            <GridActionsCellItem
              key="Approve"
              icon={
                <IconButton
                  variant="outlined"
                  size="small"
                  color="primary"
                  sx={{ textTransform: "capitalize" }}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    color="success"
                    startIcon={<Done color="success" />}
                    sx={{ textTransform: "capitalize" }}
                  >
                    Approve
                  </Button>
                </IconButton>
              }
              label="Approve"
              className="textPrimary"
              onClick={() => handleApprove(params.row)}
              color="inherit"
            />,
            <GridActionsCellItem
              key="Reject"
              icon={
                <IconButton
                  variant="outlined"
                  size="small"
                  color="primary"
                  sx={{ textTransform: "capitalize" }}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    color="error"
                    startIcon={<Cancel color="error" />}
                    sx={{ textTransform: "capitalize" }}
                  >
                    Reject
                  </Button>
                </IconButton>
              }
              label="Reject"
              className="textPrimary"
              onClick={() => handleReject(params)}
              color="inherit"
            />,
          ];
        } else {
          return (
            <GridActionsCellItem
              key="View"
              icon={
                <IconButton
                  variant="outlined"
                  size="small"
                  color="primary"
                  sx={{ textTransform: "capitalize" }}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    startIcon={<VisibilityIcon color="primary" />}
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => handleViewTaskDetails(params.row)}
                  >
                    View
                  </Button>
                </IconButton>
              }
              label="View"
              className="textPrimary"
              color="inherit"
            />
          );
        }
      },
    },
  ];
  useEffect(() => {
    dispatch(getApprovalStatus());
  }, []);
  useEffect(() => {
    dispatch(getTaskExtendStatus());
  }, []);
  useEffect(() => {
    dispatch(getDetails());
  }, []);
  useEffect(() => {
    dispatch(getAssociateList());
    // ...
  }, []);

  useEffect(() => {
    dispatch(updateTodo());
  }, []);

  useEffect(() => {
    setIsSubmitting(true);
    dispatch(getList(user.companyId))
      .then(() => {
        setIsSubmitting(false);
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.error("Error in fetching list:", error);
      });
  }, []);

  useEffect(() => {
    if (isGetAssociateListSuccess && associateResponse) {
      let users = [];
      associateResponse.forEach((user) => {
        let userD = {
          value: user.associateId,
          label: `${user.firstName} ${user.lastName}`,
        };
        users.push(userD);
      });
      setAssociateList(users);
    }
  }, [isGetAssociateListSuccess, associateResponse]);

  useEffect(() => {
    if (isGetListSuccess && getListResponse) {
      const newArray = getListResponse
        .filter((v) => v.status === null)
        .map((v) => {
          const associate = associateResponse?.find((data) => {
            if (v.associateId === data.associateId) {
              return data;
            }
          });
          return {
            ...v,
            id: v.todoId,
            todoStatus: v.status,
            associateId: associate
              ? `${associate.firstName} ${associate.lastName}`
              : "",
          };
        });
      setGetList(newArray);
    }
    dispatch(reset());
  }, [isGetListSuccess, getListResponse]);

  useEffect(() => {
    if (
      isGetTaskByTodoSuccess &&
      getTaskByTodoResponse &&
      Array.isArray(getTaskByTodoResponse.result)
    ) {
      const newArray2 = getTaskByTodoResponse.result
        .filter((v) => v.approvalStatus === null)
        .map((v) => {
          return {
            ...v,
            id: v.taskId,
            todoStatus: v.approvalStatus,
          };
        });

      setSelectedTaskDetails(newArray2);
    }
  }, [isGetTaskByTodoSuccess, getTaskByTodoResponse]);

  const handleApprove = (data) => {
    setIsSubmitting(true);

    const { id, actionType } = data;
    if (actionType === "Submit For Review") {
      dispatch(
        updateTodo({
          todoId: id,
          status: "Approve",
          actionType: "Submit For Review",
          companyId: user.companyId,
          comment: commentText,
        })
      ).then(() => {
        setIsSubmitting(false);

        setGetList((prevList) => prevList.filter((item) => item.todoId !== id));
      });
    } else {
      dispatch(
        getApprovalStatus({
          todoId: id,
          todoStatus: "Approve",
          companyId: user.companyId,
          comment: commentText,
        })
      ).then(() => {
        setIsSubmitting(false);

        setGetList((prevList) => prevList.filter((item) => item.todoId !== id));
      });
    }
  };

  const handleReject = (data) => {
    setIsSubmitting(true);

    const { id, actionType } = data;
    if (actionType === "Submit For Review") {
      dispatch(
        updateTodo({
          todoId: id,
          status: "Reject",
          actionType: "Submit For Review",
          companyId: user.companyId,
          comment: commentText,
        })
      )
        .then(() => {
          setIsSubmitting(false);
          setGetList((prevList) =>
            prevList.filter((item) => item.todoId !== id)
          );
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.error("Error in handleReject:", error);
        });
    } else {
      dispatch(
        getApprovalStatus({
          todoId: id,
          todoStatus: "Reject",
          companyId: user.companyId,
          comment: commentText,
        })
      )
        .then(() => {
          setIsSubmitting(false);
          setGetList((prevList) =>
            prevList.filter((item) => item.todoId !== id)
          );
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.error("Error in handleReject:", error);
        });
    }
    setOpenCommentPopup(false);
  };
  const handleCancelComment = () => {
    setOpenCommentPopup(false);
    setCommentText("");
  };
  const handleCancelApproveComment = () => {
    setApproveCommentPopup(false);
    setCommentText("");
  };
  useEffect(() => {
    if (isUpdateTodoSuccess) {
      if (updateResponse.result) {
        dispatch(
          setMessage({
            message: "Notification sent successFully",
            status: "Success",
          })
        );
      }
      dispatch(reset());
      setIsSubmitting(false);
    } else if (isUpdateTodoFailure && updateResponse) {
      dispatch(
        setMessage({
          message: "Notification failed",
          status: "error",
        })
      );
      dispatch(reset());
      setIsSubmitting(false);
    }
  }, [isUpdateTodoSuccess, updateResponse, isUpdateTodoFailure]);
  return (
    <>
      <Page>
        <DataTable
          columns={columns}
          rows={GetList}
          pageSize={13}
          title="Todo"
          checkboxSelection={false}
          isApproveEnabled={true}
          isRejectEnabled={true}
          columnVisibilityModel={columnVisibilityModel}
        ></DataTable>
      </Page>
      <Dialog
        open={openTaskDetailsDialog}
        fullWidth
        maxWidth="lg"
        style={{
          color: "red",
        }}
      >
        <DialogTitle
          sx={{
            borderBottom: "1px solid black",
          }}
        >
          Task Details
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseTaskDetails}
            aria-label="close"
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="taskdetailstabel">
          <DataTable
            columns={taskColumns}
            rows={selectedTaskDetails}
            pageSize={6}
            checkboxSelection={false}
            isApproveEnabled={false}
            isRejectEnabled={false}
            columnVisibilityModel={columnVisibilityModel}
          />
        </DialogContent>
        <DialogActions
          sx={{
            borderTop: "1px solid black",
          }}
        >
          <Button
            variant="outlined"
            size="small"
            color="primary"
            sx={{ textTransform: "capitalize" }}
            onClick={() => {
              setApproveCommentPopup(true);
              handleCloseTaskDetails();
            }}
          >
            Approve
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="error"
            sx={{ textTransform: "capitalize" }}
            onClick={() => {
              setOpenCommentPopup(true);
              handleCloseTaskDetails();
            }}
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openCommentPopup} maxWidth="sm" fullWidth>
        <DialogTitle>Enter Comment</DialogTitle>
        <DialogContent>
          <textarea
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            placeholder="Enter your comment..."
            style={{ width: "100%", minHeight: "100px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCancelComment}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleReject(selectedTodo);
              setOpenCommentPopup(false);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openApproveCommentPopup} maxWidth="sm" fullWidth>
        <DialogTitle>Enter Comment</DialogTitle>
        <DialogContent>
          <textarea
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            placeholder="Enter your comment..."
            style={{ width: "100%", minHeight: "100px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCancelApproveComment}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleApprove(selectedTodo);
              setApproveCommentPopup(false);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {isSubmitting && (
        <Loader
          open={isSubmitting}
          handleClose={() => setIsSubmitting(false)}
        />
      )}
    </>
  );
}
